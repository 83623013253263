<template>
  <div>
    <v-card v-if='this.undermaintenance == true' elevation="2" class="mx-auto my-1 px-1" max-width="500">        
                <v-img
                    contain
                    max-height="350"
                    max-width="100%"
                    src="/images/under-maintenance.jpg"
                >                    
                </v-img>
    </v-card>

    <v-card  v-if='this.undermaintenance == false' elevation="5" class="pt-4 pb-0 px-2 mx-3">
      <v-card-text>
        <v-row>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu21" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker1"
                  slot="activator"
                  label="Tanggal Awal"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker1"
                @input="menu21 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu22" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker2"
                  slot="activator"
                  label="Tanggal Akhir"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker2"
                @input="menu22 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="2" md="2">
            <!-- <v-select
              v-model="admindropdown"
              :items="itemsAdmin"
              item-text="nama"
              item-value="kodeAdminTrx"
              label="Pilih Admin"
              outlined
              clearable
              :disabled="validateddisable == true"
              :rules="[(v) => !!v || 'Field is required']"
            ></v-select> -->
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block color="primary" elevation="1" @click="searchPerfroma">
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block dark class="mb-2" @click="download()" color="#b3b928">
              <v-icon left> mdi-download-circle-outline </v-icon> Download
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table v-if='this.undermaintenance == false' 
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 mt-4 fontall"
    >
      <template v-slot:item.GetTotalInvoice="{ item }">
        {{ item.GetTotalInvoice }} ({{ item.GetTotalInvoiceUnPaid }} /
        {{ item.GetTotalInvoicePaid }} )
      </template>
      <template v-slot:item.GetTotalUang="{ item }">
        {{ getRupiah(item.GetTotalUang) }}
      </template>
      <template v-slot:item.totaluangsales="{ item }">
        {{ getRupiah(item.totaluangsales) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- <v-btn dark class="mb-2" @click="showAddModalSO()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn> -->
        </v-toolbar>

        <v-dialog v-model="dialogDetailDownload" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >What you want to download?</v-card-title
            >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn block outlined color="blue" @click="downloadinvoice">
                    <v-icon left> mdi-information-outline </v-icon>
                    Sales Invoice</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn block outlined color="green" @click="downloadSO">
                    <v-icon left> mdi-information-outline </v-icon>
                    Sales Order</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDetail" max-width="700px">
          <v-card>
            <v-card-title class="headline"
              >Show which details you want?</v-card-title
            >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn block outlined color="blue" @click="showDetailInvoice">
                    <v-icon left> mdi-information-outline </v-icon>
                    Detail Invoice</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn
                    block
                    outlined
                    color="green"
                    @click="showDetailCustomer"
                  >
                    <v-icon left> mdi-information-outline </v-icon>
                    Detail Customer</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn
                    block
                    outlined
                    color="orange"
                    @click="showDetailProduct"
                  >
                    <v-icon left> mdi-information-outline </v-icon>
                    Detail Product</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDetailInvoice" max-width="850px" class="pa-1">
          <v-card>
            <v-card-title class="mb-0 headermodalstyle">
              <span>Detail Stock Opname Invoice</span>
            </v-card-title>

            <v-card-text class="fontall pa-1">
              <v-container
                v-if="load == true"
                fluid
                fill-height
                style="background-color: rgba(255, 255, 255, 0.5)"
              >
                <v-layout justify-center align-center>
                  <v-progress-circular
                    :size="80"
                    :width="10"
                    indeterminate
                    color="primary"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>

              <v-container v-if="load == false" class="pa-1">
                <v-row class="mt-0">
                  <v-col
                    class="px-1 py-0 mb-2"
                    cols="12"
                    sm="12"
                    md="12"
                    style="text-align: center"
                  >
                    <h2>
                      Penjualan Salesman {{ defaultItem.salesman }}
                      <br />tanggal {{ gettanggal(defaultItem.from_date) }} -
                      {{ gettanggal(defaultItem.to_date) }}
                    </h2>
                  </v-col>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-3 mb-2"
                  ></v-divider>
                </v-row>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" style="font-size: 13px">
                          No Order
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Customer
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Barang
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Total Beli
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="val in list_detail_invoice"
                        :key="val.order_no"
                      >
                        <td class="text-left" style="font-size: 13px">
                          {{ val.order_no }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.nama_customer }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.jumlah_barang }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ getRupiah(val.Total_Rupiah3) }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.status_so }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- <v-data-table
                  :headers="headersDetailInvoice"
                  :items="list_detail_invoice"
                  :search="search"
                  class="rounded elevation-1 mx-1 pa-1 my-2"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.Total_Rupiah3="{ item }">
                    {{ getRupiah(item.Total_Rupiah3) }}
                  </template>
                </v-data-table> -->
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDetailProduct" max-width="700px" class="pa-1">
          <v-card>
            <v-card-title class="mb-0 headermodalstyle">
              <span>Detail Stock Opname Product</span>
            </v-card-title>

            <v-card-text class="fontall pa-1">
              <v-container
                v-if="load == true"
                fluid
                fill-height
                style="background-color: rgba(255, 255, 255, 0.5)"
              >
                <v-layout justify-center align-center>
                  <v-progress-circular
                    :size="80"
                    :width="10"
                    indeterminate
                    color="primary"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>

              <v-container v-if="load == false" class="pa-1">
                <v-row class="mt-0">
                  <v-col
                    class="px-1 py-0 mb-2"
                    cols="12"
                    sm="12"
                    md="12"
                    style="text-align: center"
                  >
                    <h2>
                      Penjualan Salesman {{ defaultItem.salesman }}
                      <br />tanggal {{ gettanggal(defaultItem.from_date) }} -
                      {{ gettanggal(defaultItem.to_date) }}
                    </h2>
                  </v-col>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-3 mb-2"
                  ></v-divider>
                </v-row>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" style="font-size: 13px">
                          Code Product
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Product
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Total Terjual
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="val in list_detail_product"
                        :key="val.code_product"
                      >
                        <td class="text-left" style="font-size: 13px">
                          {{ val.code_product }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.nama_product }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.total_barang }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- <v-data-table
                  :headers="headersDetailProduct"
                  :items="list_detail_product"
                  :search="search"
                  class="rounded elevation-1 mx-1 pa-1 my-2"
                  hide-default-footer
                  disable-pagination
                >
                </v-data-table> -->
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDetailCustomer" max-width="800px" class="pa-1">
          <v-card>
            <v-card-title class="mb-0 headermodalstyle">
              <span>Detail Stock Opname Product</span>
            </v-card-title>

            <v-card-text class="fontall pa-1">
              <v-container
                v-if="load == true"
                fluid
                fill-height
                style="background-color: rgba(255, 255, 255, 0.5)"
              >
                <v-layout justify-center align-center>
                  <v-progress-circular
                    :size="80"
                    :width="10"
                    indeterminate
                    color="primary"
                  >
                  </v-progress-circular>
                </v-layout>
              </v-container>

              <v-container v-if="load == false" class="pa-1">
                <v-row class="mt-0">
                  <v-col
                    class="px-1 py-0 mb-2"
                    cols="12"
                    sm="12"
                    md="12"
                    style="text-align: center"
                  >
                    <h2>
                      Penjualan Salesman {{ defaultItem.salesman }}
                      <br />tanggal {{ gettanggal(defaultItem.from_date) }} -
                      {{ gettanggal(defaultItem.to_date) }}
                    </h2>
                  </v-col>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-3 mb-2"
                  ></v-divider>
                </v-row>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" style="font-size: 13px">
                          Code Cust
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Nama
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Barang
                        </th>
                        <th class="text-center" style="font-size: 13px">
                          Total Beli
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="val in list_detail_customer"
                        :key="val.code_customer"
                      >
                        <td class="text-left" style="font-size: 13px">
                          {{ val.code_customer }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.nama_customer }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ val.total_barang }}
                        </td>
                        <td class="text-center" style="font-size: 13px">
                          {{ getRupiah(val.jumlah_uang) }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- <v-data-table
                  :headers="headersDetailCustomer"
                  :items="list_detail_customer"
                  :search="search"
                  class="rounded elevation-1 mx-1 pa-1 my-2"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:item.jumlah_uang="{ item }">
                    {{ getRupiah(item.jumlah_uang) }}
                  </template>
                </v-data-table> -->
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "LaporanPenjualan",
  data: () => ({

    undermaintenance : true,

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showProduct: false,
    dialogDetailInvoice: false,
    dialogDetailProduct: false,
    dialogDetailCustomer: false,
    dialogDetailDownload: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "Nama Sales", value: "salesman", width: "25%" },
      { text: "Total Barang", value: "GetTotalBarang" },
      { text: "Total Order", value: "GetSO" },
      { text: "Total Invoice (Unpaid/Paid)", value: "GetTotalInvoice" },
      { text: "Total Uang Inv", value: "totaluangsales" },
      { text: "Uang Terbayar", value: "GetTotalUang" },
      //   { text: "Stock Keluar", value: "stock_keluar" },
      //   { text: "Ready Stock", value: "stock_ready" },
      { text: "Actions", value: "actions", sortable: false, width: "13%" },
    ],
    headersDetailInvoice: [
      { text: "No Order", value: "order_no" },
      { text: "Customer", value: "nama_customer" },
      { text: "Barang", value: "jumlah_barang" },
      { text: "Total Beli", value: "Total_Rupiah3" },
      { text: "Status", value: "status_so" },
    ],
    headersDetailCustomer: [
      { text: "Code Cust", value: "code_customer" },
      { text: "Nama", value: "nama_customer" },
      { text: "Barang", value: "total_barang" },
      { text: "Total Beli", value: "jumlah_uang" },
    ],
    headersDetailProduct: [
      { text: "Code Product", value: "code_product" },
      { text: "Product", value: "nama_product" },
      { text: "Total Terjual", value: "total_barang" },
    ],

    dataobject: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_salesman: "",
      salesman: "",
      from_date: "",
      to_date: "",
      GetTotalBarang: "",
      GetTotalInvoice: "",
      GetTotalInvoicePaid: "",
      GetTotalInvoiceUnPaid: "",
    },
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    list_detail_invoice: "",
    list_detail_product: "",
    list_detail_customer: "",

    datepicker1: new Date().toISOString().substr(0, 10),
    menu21: "",
    datepicker2: new Date().toISOString().substr(0, 10),
    menu22: "",

    kodeAdmin: "",

    admin_role: "",
    admin_role_code: "",
    status_admin: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      if (this.admin_role_code == "4" || this.admin_role_code == "99") {
        this.status_admin == "manager";
      } else {
        this.status_admin == "staff";
      }
    }
    this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInv?from_date=" +
            this.datepicker1 +
            "&to_date=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetailInvoice() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailInvoice?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_invoice = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailCustomer() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailCustomer?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_customer = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailProduct() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailProduct?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_product = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },

    searchPerfroma() {
      console.log(this.datepicker1);
      console.log(this.datepicker2);
      this.initialize();
    },
    detailItem(item) {
      console.log(item);
      this.defaultItem = Object.assign({}, item);
      if (item.GetTotalInvoice != 0) {
        this.dialogDetail = true;
        // this.getDetail();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Tidak Ada Penjualan";
      }
    },
    showDetailInvoice() {
      this.dialogDetail = false;
      this.dialogDetailInvoice = true;
      this.getDetailInvoice();
    },
    showDetailCustomer() {
      this.dialogDetail = false;
      this.dialogDetailCustomer = true;
      this.getDetailCustomer();
    },
    showDetailProduct() {
      this.dialogDetail = false;
      this.dialogDetailProduct = true;
      this.getDetailProduct();
    },

    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },
    download() {
      this.dialogDetailDownload = true;
    },
    downloadinvoice() {
      this.dialogDetailDownload = false;
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPenjualanSalesInv?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },
    downloadSO() {
      this.dialogDetailDownload = false;
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPenjualanSalesOrd?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



