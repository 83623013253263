<template>
  <v-app id="Trees">
    
    <!-- <div v-if="isLoading == true">
      <v-progress-circular
        :size="70"
        :width="7"
        color="red"
        indeterminate
      ></v-progress-circular>
    </div> -->

    <v-navigation-drawer
      :width="200"
      v-if="isLogin  && isLoading== false"
      v-model="drawer"
      app
    >
      <v-layout column align-center>
        <!-- setting margin profil kiri -->
        <v-flex class="pa-6 pb-4 text-center">
          <v-avatar size="80">
            <img v-bind:src='images_show' />
          </v-avatar>
          <v-list-item-title class="fontall" style="color: #c53535">
            {{ nameadmin }}
          </v-list-item-title>
          <v-list-item-subtitle class="fontall" style="color: #787a91">{{
            statusadmin
          }}</v-list-item-subtitle>
        </v-flex>
      </v-layout>

      <v-divider style="background-color: white !important"></v-divider>

      <v-list dense color="transparent" nav class="px-1 py-2">
        <v-list-item color="#c53535" :to="DashboardLink" link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-title class="ml-3">Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
          color="#c53535"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                class="itemparent ml-2"
                color="#c53535"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.to"
            link
          >
            <v-list-item-content>
              <v-list-item-title
                class="itemchild"
                style="padding-left: 37px !important"
                v-text="child.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="mx-2 mt-1" style="border-radius: 5px" v-if="isLogin  && isLoading== false" app>
      <v-app-bar-nav-icon
        style="color: black"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title
        style="color: black; font-weight: bold"
        class="pl-1 fontall"
      >
        <a
          class="d-none d-sm-block"
          href="/"
          style="text-decoration: none; color: black"
        >
          AQWAM
        </a>
        <a
          class="d-sm-none"
          href="/"
          style="text-decoration: none; color: black"
        >
          AQWAM
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y open-on-hover min-width="230">
        <template v-slot:activator="{ on }">
          <v-btn class="px-1 mr-1" text color="black" v-on="on">
            <!-- <div style="text-align: right" class="mr-2">
              <p style="font-weight: bold" class="font-weight-bolder mb-0">
                {{ nameadmin }}
              </p>
              <span style="font-size: 9px">{{ statusadmin }}</span>
            </div> -->
            <v-badge
              bottom
              color="success"
              overlap
              offset-x="12"
              offset-y="12"
              class="ms-4"
              dot
            >
              <v-avatar size="35">
                <img v-bind:src='images_show' />
              </v-avatar>
            </v-badge>
          </v-btn>
          <v-btn icon>
            <v-icon>mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <v-list class="fontall">
          <div class="pb-3 pt-2">
            <v-badge
              bottom
              color="success"
              overlap
              offset-x="12"
              offset-y="12"
              class="ms-4"
              dot
            >
              <v-avatar size="35">
                <img v-bind:src='images_show' />
              </v-avatar>
            </v-badge>
            <div
              class="d-inline-flex flex-column justify-center ms-3"
              style="vertical-align: middle"
            >
              <span class="text--primary font-weight-semibold mb-n1">
                {{ nameadmin }}
              </span>
              <small class="text--disabled text-capitalize">{{
                statusadmin
              }}</small>
            </div>
          </div>
          <v-divider></v-divider>

          <!-- Profile -->
          <v-list-item @click="gotoProfile()" link>
            <v-list-item-icon class="me-2">
              <v-icon size="22"> mdi-account-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Email -->
          <v-list-item @click="gotoEmail()" link>
            <v-list-item-icon class="me-2">
              <v-icon size="22"> mdi-email-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Inbox</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!-- Reset&Logout -->

          <v-list-item-content>
            <v-list-item
              v-for="(item, index) in itemsprofile"
              :key="index"
              @click="selectSection(item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon size="22">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list>
      </v-menu>
      <!-- <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on }">
          <v-btn class="px-1 mr-1" text color="black" size="200" v-on="on">
            <div style="text-align: right" class="mr-2">
              <p style="font-weight: bold" class="font-weight-bolder mb-0">
                {{ nameadmin }}
              </p>
              <span style="font-size: 9px">{{ statusadmin }}</span>
            </div>
            <v-avatar size="35">
              <v-icon style="font-size: 40px !important" x-large color="black"
                >mdi-account-circle</v-icon
              >
            </v-avatar>
          </v-btn>
        </template>
        <v-list rounded style="overflow-y: hidden !important">
          <v-list-item
            v-for="(item, index) in itemsprofile"
            :key="index"
            @click="selectSection(item)"
            color="primary"
          >
            <v-list-item-title
              ><v-icon small color="black">{{ item.icon }}</v-icon>
              {{ item.title }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu> -->
    </v-app-bar>

    <v-main style="background-color: white" color="white lighten-1">
        <div style='margin-bottom: -80px' v-if="isLogin  && isLoading== false"> 
          <div v-if='this.$route.name == "Dashboard"'
          style="background-color: #b22020;height: 325px; padding-bottom:40px; margin-bottom:-250px; margin-top:-70px">
            
          </div>         
          <v-breadcrumbs
            v-else
            class="breadcrumbsmain"
            :items="itemsbr"
            divider=">"
            large
            style="background-color: #b22020;height: 210px; padding-bottom:40px; margin-top:-70px"
          >
          <template v-slot:divider>
            <v-icon size="20" style="color: white">mdi-forward</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :disabled="item.disabled">
              <span style="color: white">
                {{ item.text.toUpperCase() }}
              </span>
            </v-breadcrumbs-item>
          </template>
          </v-breadcrumbs>
        </div>
      <v-fade-transition mode="out-in">
      <router-view>
      </router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    itemsbr: [
      {
        text: "Ledger",
        disabled: true,
      },
      {
        text: "Data Akun",
        disabled: true,
      },
    ],

    drawer: null,
    nameadmin: "admin web",
    statusadmin: "admin",
    mailadmin: "email@mail.com",
    showimagenav: false,
    DashboardLink: "/Dashboard",
    itemsprofile: [
      { title: "Ganti Password", icon: "mdi-lock-reset" },
      { title: "Logout", icon: "mdi-logout" },
    ],
    User: [],
    items: [],
    elevations: [6, 12, 18],
    BaseUrlGet: "",
    authtoken: "",
    listValMenu: [],
    logoutvalue: false,

    images_show:'',

    isLoading : false,
  }),
  computed: {
    isLogin() {
    // console.log('computed');
      var itemroutecheck;
      // this.listValMenu;
      itemroutecheck = ["Home", "Dashboard", "Users", "GantiPassword"];

        const itemConditional = [ "ManajemenPiutangDetail", "Profile", "Email", "BukuBesarDetail"];

      if (this.listValMenu) {
      // setTimeout(this.setLoading(false), 5000);
        if (this.listValMenu.length > 0) {
          itemroutecheck = this.listValMenu.concat(itemConditional);
        }

        var rslt = itemroutecheck.includes(this.$route.name);
        if (rslt == true) {
          // console.log("comp=" + localStorage.getItem("token"));
          this.authtoken = localStorage.getItem("token");
          var authtokenval = this.authtoken;
          if (authtokenval != null) {
            var notspaceauthtoken = authtokenval.trim();
            // console.log(notspaceauthtoken.length);
            // console.log(this.authtoken);
            if (notspaceauthtoken.length > 0) {
              if(this.$route.name == 'GantiPassword'){
                this.itemsbr[0].text = 'Profile';
                this.itemsbr[1].text = 'Ganti Password';
              }else if(this.$route.name == 'Profile'){
                this.itemsbr[0].text = 'Profile';
                this.itemsbr[1].text = 'Detail User';
              }else if(this.$route.name == 'Email'){
                this.itemsbr[0].text = 'Profile';
                this.itemsbr[1].text = 'Inbox';
              }else{
                for (let i = 0; i < this.items.length; i++) {
                  var title = this.items[i].title;
                  var itemsdata = this.items[i].items;
                  for (var n = 0; n < itemsdata.length; n++) {  
                    if(itemsdata[n].to.substring(1) == this.$route.name ){
                      this.itemsbr[0].text = title;
                      this.itemsbr[1].text = itemsdata[n].title;
                    }            
                  }
                }
              }

              return this.$route.name != "Login";
            } else {
              console.log("auth 0");
              this.$router.push("/");
            }
          } else {
            console.log("auth null");
            this.$router.push("/");
          }
        } else {
          console.log("route false");
          this.authtoken = localStorage.getItem("token");
          var authtokenval = this.authtoken;
          if (authtokenval != null) {
            var notspaceauthtoken = authtokenval.trim();
            if (notspaceauthtoken.length > 0) {
              this.$router.push("/Dashboard");
              return this.$route.name != "Login";
            } else {
              console.log("auth 0");
              this.$router.push("/");
            }
          } else {
            console.log("auth null");
            console.log("logout system");
            if (this.logoutvalue == true) {
              this.logoutvalue = false;
              // location.reload();
            } else if (this.$route.name == "Login") {
              console.log("page " + this.$route.name);
            } else {
              this.$router.push("/");
            }
          }
        }
      } else {
      // setTimeout(this.setLoading(false), 5000);
        localStorage.removeItem("token");
        localStorage.removeItem("User");
        localStorage.removeItem("BaseUrlUpload");
        this.$router.push("/");
      }
    },
  },
  created() {
    // console.log('created');
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.items = this.User.list_menu;
      this.listValMenu = this.User.list_val_menu;
      var name = this.User.name;
      const arrayname = name.split(" ");
      var lastname = '';
      if(arrayname[1]){
        lastname = arrayname[1];
      }
      this.nameadmin = arrayname[0]+' '+lastname;
      this.mailadmin = this.User.email;
      this.statusadmin = this.User.role;
      // console.log(this.statusadmin);
      var statusadm = this.statusadmin.toLowerCase();
      if (statusadm == "kasir") {
        // console.log("true");
        this.showimagenav = true;
      }

      if(this.User.path_foto == '-'){
        this.images_show ="images/avatar.png";
      }else{
        this.getFoto(this.User.path_foto);
        // this.path_foto = this.imagesReturnBase64;
      }
    }
    // console.log(this.User.list_menu);
  },
  beforeMount(){
    // console.log('beforeMount');
    // this.isLoading = true;
    // this.setLoading(true);
  },
  mounted(){
    // console.log('test mounted');
    // this.isLoading = false;
  },
  methods: {
    async cekLogout() {
      const datapost = {};
      // console.log(this.BaseUrlGet);
      // console.log(this.authtoken);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "Logout",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.status.code);
        if (response.data.data.status.code == 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          this.logoutvalue = true;
          this.$router.push("/");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          this.logoutvalue = true;
          this.$router.push("/");
        }
      } catch (error) {
        // console.error(error.response);
        localStorage.removeItem("token");
        localStorage.removeItem("User");
        localStorage.removeItem("BaseUrlUpload");
        this.logoutvalue = true;
        this.$router.push("/");
      }
    },
    async getFoto(val){
      // var imagesvalue = 'public/uploads/1656286341_login_tokomas.PNG';
      var imagesvalue = val;
      try {
        const response = await axios.get(this.BaseUrlGet + "getimages?path_file="+imagesvalue, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        if (response.data.length != 0) {
          this.images_show = "data:image/jpg;base64," + response.data.data.result;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },

    logout() {
      // console.log("test");
      this.cekLogout();
    },

    selectSection(item) {
      switch (item.title) {
        case "Ganti Password":
          console.log("Ganti Password");
          this.$router.push("/GantiPassword");
          break;
        case "Logout":
          console.log("Logout");
          this.logout();
      }
    },

    gotoProfile(){
          this.$router.push("/Profile");
    },
    gotoEmail(){
          this.$router.push("/Email");
    },

    setLoading(val){
      console.log(val);
      this.isLoading = val;
    }
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-list-item__icon {
  margin-right: 5px !important;
}

.v-icon.v-icon {
  font-size: 20px !important;
}

.v-app-bar {
  height: 54px !important;
}

.v-toolbar__content {
  height: 54px !important;
}
</style>


