<template>
  <div class="px-3">
    <v-row>
      <v-col  cols="12" sm="12" md="12">
        <v-card>
          <div class="pa-2">
            <h4>Buku Besar Akun Neraca</h4>
            <v-divider style="width:60%"></v-divider>
          </div>          
          <div v-if="loadtable2 == true" style="text-align:center" class="my-5">
            <v-progress-circular
              :size="50"
              :width="5"
              color="red"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width='20%' class="text-left">
                    Kode Akun
                  </th>
                  <th width='50%' class="text-left">
                    Nama Akun
                  </th>
                  <th width='30%' class="text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dataobject2"
                  :key="item.no_akun"
                >
                  <td><a @click="detailPilih(item.no_akun)">{{ item.no_akun }}</a></td>
                  <td>{{ item.nama_akun }}</td>
                  <td class="text-right">{{ getRupiahNominal(item.saldo_akun) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "BukuBesar",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    textshowoptional: "Show",
    iconshowoptional: "mdi-arrow-right-drop-circle-outline",
    valid: true,
    loadtable1: false,
    loadtable2: false,
    optionalForm: false,
    dialog: false,
    dialogReset: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    dataobject1: [],
    dataobject2: [],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    admin_id: "",
  }),

  beforeMount() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    // this.getBukuBesarLabaRugi();
    this.getBukuBesarNeraca();
  },

  methods: {
    // async getBukuBesarLabaRugi() {
    //   this.dataobject = [];
    //   this.loadtable1 = true;
    //   try {
    //     const response = await axios.get(this.BaseUrlGet + "GetLaporanBukuBesar?type=laba_rugi", {
    //       headers: {
    //         Authorization: `Bearer ` + this.authtoken,
    //       },
    //     });
    //     console.log(response.data.data.result.data);
    //     if (response.data.length != 0) {
    //       this.loadtable1 = false;
    //       this.dataobject1 = response.data.data.result.data;
    //     } else {
    //       console.log("Kosong");
    //       this.loadtable1 = false;
    //       this.dataobject = [];
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     if (error.response.status == 401) {
    //       this.loadtable1 = false;
    //       localStorage.removeItem("token");
    //       this.$router.push("/");
    //     } else {
    //       this.loadtable1 = false;
    //       this.dataobject = [];
    //     }
    //   }
    // },
    async getBukuBesarNeraca() {
      this.dataobject = [];
      this.loadtable2 = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetLaporanBukuBesar?type=neraca", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable2 = false;
          this.dataobject2 = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable2 = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable2 = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable2 = false;
          this.dataobject = [];
        }
      }
    },

    detailPilih(value) {
      localStorage.setItem("no_akun", value);
      this.$router.push("/BukuBesarDetail");
    },

    close() {
      this.dialog = false;
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },

    
    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahNominal(val) {
      return HelperGlobalService.getRupiahNominal(val);
    },
  },
};
</script>



