<template>
  <div>

    <v-card elevation="5" class="pt-4 pb-0 px-2 mx-3">
      <v-card-text>
        <v-row>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu21" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker1"
                  slot="activator"
                  label="Tanggal Awal"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker1"
                @input="menu21 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu22" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker2"
                  slot="activator"
                  label="Tanggal Akhir"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker2"
                @input="menu22 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="2" md="2">
            <!-- <v-select
              v-model="admindropdown"
              :items="itemsAdmin"
              item-text="nama"
              item-value="kodeAdminTrx"
              label="Pilih Admin"
              outlined
              clearable
              :disabled="validateddisable == true"
              :rules="[(v) => !!v || 'Field is required']"
            ></v-select> -->
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block color="primary" elevation="1" @click="searchPerfroma">
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block dark class="mb-2" @click="download()" color="#b3b928">
              <v-icon left> mdi-download-circle-outline </v-icon> Download
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-card elevation="5" class="pt-1 pb-0 px-2 mx-3 mt-5">
      <v-card-text>
        <v-row>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="12"
            md="12"
            style="text-align: center"
          >
            <h2 style="font-styler: bold" class="mt-3 mb-2">
              Jurnal - Semua Transaksi 
            </h2>
            <h4>
                {{ gettanggal(from_date) }} -
              {{ gettanggal(to_date) }}
            </h4>
          </v-col>
        </v-row>
      </v-card-text>
      <hr>
      <div v-if="showLoading == true" style="text-align: center;margin-top: 30px;">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-simple-table v-if="showLoading == false">
            <template v-slot:default>
            <thead>
                <tr>
                <th class="text-left" width="10%">
                    Type
                </th>
                <th class="text-left"  width="15%">
                    REF/DATE
                </th>
                <th class="text-left">
                    Description
                </th>
                <th class="text-left" width="18%">
                    Debit
                </th>
                <th class="text-left" width="18%">
                    Credit
                </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in dataobject"
                :key="item.code_jurnal"
                >
                <td>{{ item.type }}</td>
                <td>{{ item.ref_date }}</td>
                <td>{{ item.keterangan }}</td>
                <td>{{ getRupiah(item.debit) }}</td>
                <td>{{ getRupiah(item.kredit) }}</td>
                </tr>
            <tr></tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "JurnalTransaksi",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showProduct: false,
    dialogDetailInvoice: false,
    dialogDetailProduct: false,
    dialogDetailCustomer: false,
    dialogDetailDownload: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "Nama Sales", value: "salesman", width: "25%" },
      { text: "Total Barang", value: "GetTotalBarang" },
      { text: "Total Order", value: "GetSO" },
      { text: "Total Invoice (Unpaid/Paid)", value: "GetTotalInvoice" },
      { text: "Total Uang Inv", value: "totaluangsales" },
      { text: "Uang Terbayar", value: "GetTotalUang" },
      //   { text: "Stock Keluar", value: "stock_keluar" },
      //   { text: "Ready Stock", value: "stock_ready" },
      { text: "Actions", value: "actions", sortable: false, width: "13%" },
    ],

    dataobject: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_salesman: "",
      salesman: "",
      from_date: "",
      to_date: "",
      GetTotalBarang: "",
      GetTotalInvoice: "",
      GetTotalInvoicePaid: "",
      GetTotalInvoiceUnPaid: "",
    },
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    list_detail_invoice: "",
    list_detail_product: "",
    list_detail_customer: "",

    datepicker1: new Date().toISOString().substr(0, 10),
    menu21: "",
    datepicker2: new Date().toISOString().substr(0, 10),
    menu22: "",

    kodeAdmin: "",

    admin_role: "",
    admin_role_code: "",
    status_admin: "",

    showLoading: false,

    
    from_date: new Date().toISOString().substr(0, 10),
    to_date: new Date().toISOString().substr(0, 10),
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      if (this.admin_role_code == "4" || this.admin_role_code == "99") {
        this.status_admin == "manager";
      } else {
        this.status_admin == "staff";
      }
    }
    this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      this.showLoading = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanJurnalSemuaTransaksi?from_date=" +
            this.datepicker1 +
            "&to_date=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
          this.from_date = this.datepicker1;
          this.to_date = this.datepicker2;
          this.showLoading = false;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
          this.showLoading = false;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
          this.showLoading = false;
        } else {
          this.loadtable = false;
          this.dataobject = [];
          this.showLoading = false;
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetailInvoice() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailInvoice?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_invoice = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailCustomer() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailCustomer?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_customer = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailProduct() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailProduct?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_product = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },

    searchPerfroma() {
      console.log(this.datepicker1);
      console.log(this.datepicker2);
      this.initialize();
    },
    detailItem(item) {
      console.log(item);
      this.defaultItem = Object.assign({}, item);
      if (item.GetTotalInvoice != 0) {
        this.dialogDetail = true;
        // this.getDetail();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Tidak Ada Penjualan";
      }
    },
    showDetailInvoice() {
      this.dialogDetail = false;
      this.dialogDetailInvoice = true;
      this.getDetailInvoice();
    },
    showDetailCustomer() {
      this.dialogDetail = false;
      this.dialogDetailCustomer = true;
      this.getDetailCustomer();
    },
    showDetailProduct() {
      this.dialogDetail = false;
      this.dialogDetailProduct = true;
      this.getDetailProduct();
    },

    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },
    download() {
      this.dialogDetailDownload = true;
    },
    downloadinvoice() {
      this.dialogDetailDownload = false;
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPenjualanSalesInv?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },
    downloadSO() {
      this.dialogDetailDownload = false;
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPenjualanSalesOrd?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



