<template>
  <div>
    
    <v-card v-if='this.undermaintenance == true' elevation="2" class="mx-auto my-1 px-1" max-width="500">        
                <v-img
                    contain
                    max-height="350"
                    max-width="100%"
                    src="/images/under-maintenance.jpg"
                >                    
                </v-img>
    </v-card>

    <v-card  v-if='this.undermaintenance == false' elevation="5" class="pt-4 pb-0 px-2 mx-3">
      <v-card-text>
        <v-row>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu21" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker1"
                  slot="activator"
                  label="Tanggal Awal"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker1"
                @input="menu21 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
            <v-menu v-model="menu22" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datepicker2"
                  slot="activator"
                  label="Tanggal Akhir"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :rules="[(v) => !!v || 'Field is required']"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datepicker2"
                @input="menu22 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="2" md="2">
            <!-- <v-select
              v-model="admindropdown"
              :items="itemsAdmin"
              item-text="nama"
              item-value="kodeAdminTrx"
              label="Pilih Admin"
              outlined
              clearable
              :disabled="validateddisable == true"
              :rules="[(v) => !!v || 'Field is required']"
            ></v-select> -->
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block color="primary" elevation="1" @click="searchPerfroma">
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block dark class="mb-2" @click="download()" color="#b3b928">
              <v-icon left> mdi-download-circle-outline </v-icon> Download
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card  v-if='this.undermaintenance == false' elevation="5" class="pt-1 pb-0 px-2 mx-3 mt-5">
      <v-card-text>
        <v-row>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="12"
            md="12"
            style="text-align: center"
          >
            <h3 style="font-styler: bold">
              Total Pengeluaran {{ gettanggal(from_date) }} sampai
              {{ gettanggal(to_date) }} =
              {{ getRupiah(totaluang) }}
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-data-table v-if='this.undermaintenance == false' 
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 mt-4 fontall"
    >
      <template v-slot:item.created_at="{ item }">
        {{ gettanggal(item.created_at) }}
      </template>

      <template v-slot:item.total_akhir="{ item }">
        {{ getRupiah(item.total_akhir) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- <v-btn dark class="mb-2" @click="showAddModalSO()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn> -->
        </v-toolbar>

        <v-dialog v-model="dialogDetail" max-width="700px">
          <v-card>
            <v-card-title class="headline"
              >Show which details you want?</v-card-title
            >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn block outlined color="blue" @click="showDetailInvoice">
                    <v-icon left> mdi-information-outline </v-icon>
                    Detail Invoice</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn
                    block
                    outlined
                    color="green"
                    @click="showDetailCustomer"
                  >
                    <v-icon left> mdi-information-outline </v-icon>
                    Detail Customer</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                  class="px-3"
                  style="text-align: center"
                >
                  <v-btn
                    block
                    outlined
                    color="orange"
                    @click="showDetailProduct"
                  >
                    <v-icon left> mdi-information-outline </v-icon>
                    Detail Product</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>

            <v-card-actions> </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "LaporanPengeluaran",
  data: () => ({

    undermaintenance : true,

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showProduct: false,
    dialogDetailInvoice: false,
    dialogDetailProduct: false,
    dialogDetailCustomer: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "Keterangan", value: "keterangan" },
      { text: "Total Uang", value: "total_akhir" },
      { text: "Tanggal", value: "created_at" },
      //   { text: "Stock Keluar", value: "stock_keluar" },
      //   { text: "Ready Stock", value: "stock_ready" },
      //   { text: "Actions", value: "actions", sortable: false, width: "13%" },
    ],
    headersDetailInvoice: [
      { text: "No Order", value: "order_no" },
      { text: "Customer", value: "nama_customer" },
      { text: "Barang", value: "jumlah_barang" },
      { text: "Total Beli", value: "Total_Rupiah3" },
      { text: "Status", value: "status_so" },
    ],
    headersDetailCustomer: [
      { text: "Code Cust", value: "code_customer" },
      { text: "Nama", value: "nama_customer" },
      { text: "Barang", value: "total_barang" },
      { text: "Total Beli", value: "jumlah_uang" },
    ],
    headersDetailProduct: [
      { text: "Code Product", value: "code_product" },
      { text: "Product", value: "nama_product" },
      { text: "Total Terjual", value: "total_barang" },
    ],

    dataobject: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_salesman: "",
      salesman: "",
      from_date: "",
      to_date: "",
      GetTotalBarang: "",
      GetTotalInvoice: "",
      GetTotalInvoicePaid: "",
      GetTotalInvoiceUnPaid: "",
    },
    from_date: new Date().toISOString().substr(0, 10),
    to_date: new Date().toISOString().substr(0, 10),
    totaluang: 0,
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    list_detail_invoice: "",
    list_detail_product: "",
    list_detail_customer: "",

    datepicker1: new Date().toISOString().substr(0, 10),
    menu21: "",
    datepicker2: new Date().toISOString().substr(0, 10),
    menu22: "",

    kodeAdmin: "",

    admin_role: "",
    admin_role_code: "",
    status_admin: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      if (this.admin_role_code == "4" || this.admin_role_code == "99") {
        this.status_admin == "manager";
      } else {
        this.status_admin == "staff";
      }
    }
    this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPengeluaran?from_date=" +
            this.datepicker1 +
            "&to_date=" +
            this.datepicker2,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.Getpengeluaran;
          this.totaluang = response.data.data.result.Getpengeluarantotal;
          this.from_date = response.data.data.result.from_date;
          this.to_date = response.data.data.result.to_date;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
          this.from_date = response.data.data.result.from_date;
          this.to_date = response.data.data.result.to_date;
          this.totaluang = 0;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
        this.from_date = response.data.data.result.from_date;
        this.to_date = response.data.data.result.to_date;
        this.totaluang = 0;
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetailInvoice() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailInvoice?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_invoice = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailCustomer() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailCustomer?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_customer = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailProduct() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPenjualanSalesInvDetailProduct?code_salesman=" +
            this.defaultItem.code_salesman +
            "&from_date=" +
            this.defaultItem.from_date +
            "&to_date=" +
            this.defaultItem.to_date,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.list_detail_product = response.data.data.result;
          //   this.defaultItem = response.data.data.result.data;
          //   this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },

    searchPerfroma() {
      console.log(this.datepicker1);
      console.log(this.datepicker2);
      this.initialize();
    },
    detailItem(item) {
      console.log(item);
      this.defaultItem = Object.assign({}, item);
      if (item.GetTotalInvoice != 0) {
        this.dialogDetail = true;
        // this.getDetail();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Tidak Ada Penjualan";
      }
    },
    showDetailInvoice() {
      this.dialogDetail = false;
      this.dialogDetailInvoice = true;
      this.getDetailInvoice();
    },
    showDetailCustomer() {
      this.dialogDetail = false;
      this.dialogDetailCustomer = true;
      this.getDetailCustomer();
    },
    showDetailProduct() {
      this.dialogDetail = false;
      this.dialogDetailProduct = true;
      this.getDetailProduct();
    },

    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },

    download() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPengeluaran?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



