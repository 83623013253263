import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import GantiPassword from '../views/GantiPassword.vue'
import Profile from '../views/Profile.vue'
import Email from '../views/Email.vue'

import Product from '../views/MainData/Product.vue'
import ProductCategory from '../views/MainData/ProductCategory.vue'
import Customer from '../views/MainData/Customer.vue'
import Supplier from '../views/MainData/Supplier.vue'
import Admin from '../views/MainData/Admin.vue'
import AdminRole from '../views/MainData/AdminRole.vue'
import AdminLogin from '../views/MainData/AdminLogin.vue'

import SalesOrder from '../views/Transaksi/SalesOrder.vue'
import SalesInvoice from '../views/Transaksi/SalesInvoice.vue'
import PurchaseOrder from '../views/Transaksi/PurchaseOrder.vue'

import ReturnCustomer from '../views/Return/ReturnCustomer.vue'
import ReturnSupplier from '../views/Return/ReturnSupplier.vue'

import PendingTask from '../views/Task/PendingTask.vue'

import ManajemenPiutang from '../views/Utilities/ManajemenPiutang.vue'
import ManajemenPiutangDetail from '../views/Utilities/ManajemenPiutangDetail.vue'
import ManajemenHutang from '../views/Utilities/ManajemenHutang.vue'

import History from '../views/History/History.vue'

import LaporanPenjualan from '../views/Report/LaporanPenjualan.vue'
import LaporanPendapatan from '../views/Report/LaporanPendapatan.vue'
import LaporanPengeluaran from '../views/Report/LaporanPengeluaran.vue'
import LaporanHutang from '../views/Report/LaporanHutang.vue'
import LaporanPiutang from '../views/Report/LaporanPiutang.vue'
import LaporanStock from '../views/Report/LaporanStock.vue'

import StockInOut from '../views/Inventory/StockInOut.vue'
import StockOpname from '../views/Inventory/StockOpname.vue'
import InventoryAdjusment from '../views/Inventory/InventoryAdjusment.vue'


import Akun from '../views/BukuBesar/Akun.vue'
import BukuBesar from '../views/BukuBesar/BukuBesar.vue'
import BukuBesarDetail from '../views/BukuBesar/BukuBesarDetail.vue'
import Jurnal from '../views/BukuBesar/Jurnal.vue'

import KasKeluar from '../views/KasBank/KasKeluar.vue'
import KasMasuk from '../views/KasBank/KasMasuk.vue'


import PiutangKaryawan from '../views/Utilities/PiutangKaryawan.vue'
import PiutangLain from '../views/Utilities/PiutangLain.vue'


import ManajemenGudang from '../views/Utilities/ManajemenGudang.vue'
import LaporanPindahGudang from '../views/Utilities/LaporanPindahGudang.vue'
import PenjualanPindahGudang from '../views/Utilities/PenjualanPindahGudang.vue'
import ReturnPindahGudang from '../views/Utilities/ReturnPindahGudang.vue'

import JurnalTrx from '../views/Report/JurnalTrx.vue'
import LabaRugi from '../views/Report/LabaRugi.vue'
import Neraca from '../views/Report/Neraca.vue'



// import FieldFacilitator from '../views/Employee/FF.vue'
import MainPage from '../views/MainPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  // {
  //   path: '/Uploads',
  //   name: 'Login',
  //   component: Login
  // },
  {
    path: '/GantiPassword',
    name: 'GantiPassword',
    component: GantiPassword
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/Email',
    name: 'Email',
    component: Email
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/MainPage',
    name: 'MainPage',
    component: MainPage
  },
  
  {
    path: '/Customer',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/Supplier',
    name: 'Supplier',
    component: Supplier
  },
  {
    path: '/Product',
    name: 'Product',
    component: Product
  },
  {
    path: '/ProductCategory',
    name: 'ProductCategory',
    component: ProductCategory
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/AdminRole',
    name: 'AdminRole',
    component: AdminRole
  }, 
  {
    path: '/AdminLogin',
    name: 'AdminLogin',
    component: AdminLogin
  }, 
  
  {
    path: '/SalesOrder',
    name: 'SalesOrder',
    component: SalesOrder
  },
  {
    path: '/SalesInvoice',
    name: 'SalesInvoice',
    component: SalesInvoice
  },
  {
    path: '/PurchaseOrder',
    name: 'PurchaseOrder',
    component: PurchaseOrder
  },

  {
    path: '/ReturnCustomer',
    name: 'ReturnCustomer',
    component: ReturnCustomer
  },
  {
    path: '/ReturnSupplier',
    name: 'ReturnSupplier',
    component: ReturnSupplier
  },
 
  {
    path: '/PendingTask',
    name: 'PendingTask',
    component: PendingTask
  },
  
  {
    path: '/StockInOut',
    name: 'StockInOut',
    component: StockInOut
  },
  {
    path: '/StockOpname',
    name: 'StockOpname',
    component: StockOpname
  },
  {
    path: '/InventoryAdjusment',
    name: 'InventoryAdjusment',
    component: InventoryAdjusment
  },
  
  
  {
    path: '/History',
    name: 'History',
    component: History
  }, 

  {
    path: '/ManajemenPiutang',
    name: 'ManajemenPiutang',
    component: ManajemenPiutang
  },
  {
    path: '/ManajemenPiutangDetail',
    name: 'ManajemenPiutangDetail',
    component: ManajemenPiutangDetail
  }, 
  {
    path: '/ManajemenHutang',
    name: 'ManajemenHutang',
    component: ManajemenHutang
  }, 
  
  {
    path: '/LaporanPenjualan',
    name: 'LaporanPenjualan',
    component: LaporanPenjualan
  }, 
  {
    path: '/LaporanPendapatan',
    name: 'LaporanPendapatan',
    component: LaporanPendapatan
  }, 
  {
    path: '/LaporanPengeluaran',
    name: 'LaporanPengeluaran',
    component: LaporanPengeluaran
  }, 
  {
    path: '/LaporanHutang',
    name: 'LaporanHutang',
    component: LaporanHutang
  }, 
  {
    path: '/LaporanPiutang',
    name: 'LaporanPiutang',
    component: LaporanPiutang
  }, 
  {
    path: '/LaporanStock',
    name: 'LaporanStock',
    component: LaporanStock
  }, 
  
  
  {
    path: '/Akun',
    name: 'Akun',
    component: Akun
  },
  {
    path: '/BukuBesar',
    name: 'BukuBesar',
    component: BukuBesar
  },
  {
    path: '/BukuBesarDetail',
    name: 'BukuBesarDetail',
    component: BukuBesarDetail
  },
  {
    path: '/Jurnal',
    name: 'Jurnal',
    component: Jurnal
  },

  {
    path: '/KasKeluar',
    name: 'KasKeluar',
    component: KasKeluar
  }, 
  {
    path: '/KasMasuk',
    name: 'KasMasuk',
    component: KasMasuk
  }, 
  
  {
    path: '/PiutangKaryawan',
    name: 'PiutangKaryawan',
    component: PiutangKaryawan
  }, 
  {
    path: '/PiutangLain',
    name: 'PiutangLain',
    component: PiutangLain
  }, 

  
  {
    path: '/ManajemenGudang',
    name: 'ManajemenGudang',
    component: ManajemenGudang
  }, 
  {
    path: '/LaporanPindahGudang',
    name: 'LaporanPindahGudang',
    component: LaporanPindahGudang
  }, 
  {
    path: '/PenjualanPindahGudang',
    name: 'PenjualanPindahGudang',
    component: PenjualanPindahGudang
  }, 
  {
    path: '/ReturnPindahGudang',
    name: 'ReturnPindahGudang',
    component: ReturnPindahGudang
  }, 

  {
    path: '/JurnalTrx',
    name: 'JurnalTrx',
    component: JurnalTrx
  }, 
  {
    path: '/LabaRugi',
    name: 'LabaRugi',
    component: LabaRugi
  }, 
  {
    path: '/Neraca',
    name: 'Neraca',
    component: Neraca
  }, 
  
]

const router = new VueRouter({
  routes
})

export default router
