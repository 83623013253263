<template>
  <div>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="2" class="mx-auto my-1 px-1" max-width="500">        
                <v-img
                    contain
                    max-height="350"
                    max-width="100%"
                    src="/images/under-maintenance.jpg"
                >                    
                </v-img>
      </v-card>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile",
  data: () => ({
    showPassword: false,
    authtoken: "",
    BaseUrlGet: "",
    dataobjectprov: [],
    defaultItem: { id: "", province_code: "", name: "" },
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
    valid: true,

    User: [],

    namauser: "",
    username: "",
    email: "",
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.User = JSON.parse(localStorage.getItem("User"));
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    if (this.User) {
      this.email = this.User.email;
      this.namauser = this.User.name;
    }
  },

  methods: {
    async submitgantipassword() {
      const datapost = {
        name: this.namauser,
        email: this.email,
        password: this.oldpassword,
        new_password: this.newpassword,
        confirm_password: this.confirmpassword,
      };

      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditProfile",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.status.code);
        this.snackbar = true;
        if (response.data.data.status.code == 200) {
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Ganti Password";
          this.cekLogout();
        } else {
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ganti password";
        }
      } catch (error) {
        console.error(error.response);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = error.response.data.data.status.description;
      }
    },
    async cekLogout() {
      const datapost = {};
      // console.log(this.BaseUrlGet);
      // console.log(this.authtoken);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "Logout",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.status.code);
        if (response.data.data.status.code == 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          this.$router.push("/");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          this.$router.push("/");
        }
      } catch (error) {
        // console.error(error.response);
        localStorage.removeItem("token");
        localStorage.removeItem("User");
        localStorage.removeItem("BaseUrlUpload");
        this.$router.push("/");
      }
    },
    async gantipassword() {
      await this.$refs.form.validate();
      console.log(this.$refs.form.validate());
      if (this.$refs.form.validate() == true) {
        this.submitgantipassword();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Data tidak boleh kosong";
      }
      // console.log(this.email);
      // console.log(this.password);
      // await this.$refs.form.reset();
    },
  },
};
</script>