<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.created_at="{ item }">
        {{ gettanggal(item.created_at) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModalSO()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-btn dark class="mb-2 ml-2" @click="synchronize()" color="primary">
            <v-icon small>mdi-sync</v-icon> Synchronize
          </v-btn>
          <v-dialog v-model="dialogAdd" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle" style="margin-bottom: 2px">
                <span>Add Stock In/Out</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container class="mb-0">
                  <v-row class="mb-0">
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.tipe_asal"
                        :items="itemsTipe"
                        item-value="value"
                        item-text="text"
                        label="Pilih Tipe"
                        outlined
                        v-on:change="changeTipe"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="defaultItem.order"
                        :items="dataasal"
                        item-value="code_asal"
                        item-text="TextOrder"
                        label="Pilih No Order"
                        outlined
                        v-on:change="changeCode"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-combobox>
                      <h4
                        v-if="showButtonAdd == false"
                        class="ml-2"
                        style="color: red"
                      >
                        Note:
                        <br />- No Order yang sudah
                        <span style="text-decoration: underline"
                          >"pernah dibuat stock in out tidak akan muncul
                          lagi"</span
                        >
                        kecuali di hapus. <br />- jika masih terdapat no order
                        berarti no order tersebut harus di stock in out untuk
                        mendapatkan stock yang akurat
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-if="showButtonAdd == true">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody class="ml-5">
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Code
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.order.TextOrder }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Tipe
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.tipe_asal }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Tipe Stock
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            Stock {{ defaultItem.tipe_in_out }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-data-table
                    :headers="headersProduct"
                    :items="defaultItem.list_order"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.harga="{ item }">
                      {{ getRupiah(item.harga) }}
                    </template>
                    <template v-slot:item.jumlah="{ item }">
                      {{ item.jumlah }} {{ item.satuan }}
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
              <v-card-actions v-if="showButtonAdd == true">
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="savestockinout"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-text>
                <h4 class="ml-2" style="color: red">
                  Note: Jika dihapus akan berpengaruh ke stock
                </h4>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDetail" max-width="700px" class="pa-1">
            <v-card>
              <v-card-title class="mb-0 headermodalstyle">
                <span>Detail Stock In Out</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container
                  v-if="load == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>

                <v-container v-if="load == false" class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody class="ml-5">
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Code
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.code_stock }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                            rowspan="2"
                          >
                            Tipe
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.tipe_asal }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.code_asal }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Tipe Stock
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            Stock {{ defaultItem.tipe_in_out }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Keterangan
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.keterangan }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            style="width: 250px !important; font-size: 13px"
                          >
                            Product
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Jumlah
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="val in defaultItem.list_order"
                          :key="val.code_customer"
                        >
                          <td
                            class="text-left"
                            style="
                              padding-left: 35px !important;
                              font-size: 13px;
                            "
                          >
                            {{ val.nama_product }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.jumlah }} {{ val.satuan }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <!-- <v-data-table
                    :headers="headersProductDetail"
                    :items="defaultItem.list_order"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.jumlah="{ item }">
                      {{ item.jumlah }} {{ item.satuan }}
                    </template>
                  </v-data-table> -->
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          
          <v-dialog v-model="dialogSingkron" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline">
                <div style="text-align: left;width: 100%;" > Pilih singkronisasi</div>
              </v-card-title>
              <hr class="ml-2 mr-2">
              <v-card-text style="text-align: center;" class="mt-2">
                <v-btn color="#0685ea" elevation="1" class="mr-3" outlined @click="singkronSO">
                  Sales Order
                </v-btn>
                <v-btn color="#3e0ec1" elevation="1" outlined @click="singkronPurchase">
                  Purchase
                </v-btn>
                <!-- <v-btn color="#6e0bb6" elevation="1" outlined @click="closeSingkron">
                  Product Stock
                </v-btn> -->
              </v-card-text>
              <hr class="ml-2 mr-2" v-if="showHasil == true" >
              <v-card-text>
                <div v-if="showLoading == true && showHasil == true" style="text-align: center;margin-top: 10px;" ><h2>Proses singkronisasi, harap ditunggu...</h2></div> 
                <div v-if="showLoading == false && showHasil == true" style="text-align: center;margin-top: 10px;" ><h2>Proses singkronisasi selesai...</h2></div>                
                <div v-if="showLoading == true && showHasil == true" style="text-align: center;margin-top: 30px;">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                </div>
                </v-card-text>
              <hr class="ml-2 mr-2" v-if="showHasil == true" >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="closeSingkron"
                   v-if="showLoading == false && showHasil == true" 
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Selesai</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "StockInOut",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showButtonAdd: false,

    dialogSingkron: false,
    showHasil: false,
    showLoading: false,

    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Code Stock",
        value: "code_stock",
      },
      { text: "Tipe", value: "tipe_asal", width: "20%" },
      { text: "Total", value: "jumlah_barang" },
      { text: "keterangan", value: "keterangan" },
      { text: "Tanggal", value: "created_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersOrderCust: [
      {
        text: "No Inv",
        value: "id",
      },
      { text: "Barang", value: "total_barang" },
      { text: "Total Belanja", value: "total_harga" },
      { text: "Tanggal", value: "created_at" },
      { text: "Update", value: "updated_at" },
    ],
    headerListProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Harga", value: "harga" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Jumlah", value: "jumlah" },
    ],
    headersProductDetail: [
      { text: "Product", value: "nama_product" },
      { text: "Jumlah", value: "jumlah" },
    ],
    dataobject: [],
    dataadmin: [],
    datacustomer: [],
    dataasal: [],
    listordercust: [],
    itemsKabRO: [],
    itemsProduct: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_stock: "",
      order: "",
      code_asal: "",
      tipe_asal: "",
      tipe_in_out: "",
      keterangan: "",
      list_order: [],
    },

    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    itemsTipe: [
      { text: "Order", value: "order" },
      { text: "Purchase", value: "purchase" },
      { text: "Return_Customer", value: "return_customer" },
      { text: "Return_Supplier", value: "return_supplier" },
      { text: "Pindah_Gudang", value: "pindah_gudang" },
    ],

    e1: 1,
    stepperdone: false,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    kodeAdmin: "",

    status_show_bayar: false,
    bayar_temp: 0,

    dateTempo: false,
    codeProductTemp: "",
    NamaProductTemp: "",
    deskripsiProductTemp: "",
    hargaProductTemp: 0,
    jumlahProductTemp: 0,
    admin_role: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
    }
    this.initialize();
    this.GetAdmin();
    // this.getKabKotaROAll();
    this.GetProduct();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetStockInOut", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetAdmin() {
      this.admin_role = 1;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAdminbyRole?role_code=" + this.admin_role,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataadmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataadmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataadmin = [];
        }
      }
    },
    async GetCustomer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomer?salesman_id=" +
            this.defaultItem.code_salesman,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.datacustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.datacustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.datacustomer = [];
        }
      }
    },
    async GetSalesOrder() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetSOInventory?status_stock=0",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataasal = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataasal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataasal = [];
        }
      }
    },
    async GetPurchaseOrder() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPurchaseInventory?status_stock=0",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataasal = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataasal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataasal = [];
        }
      }
    },
    async GetReturnCustomer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetRerturnCustomerInventory?status_stock=0",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataasal = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataasal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataasal = [];
        }
      }
    },
    async GetReturnSupplier() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetRerturnSupplierInventory?status_stock=0",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataasal = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataasal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataasal = [];
        }
      }
    },
    async GetLaporanPindahGudang() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetLaporanPindahGudangInventory?status_stock=0",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataasal = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataasal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataasal = [];
        }
      }
    },
    async getDetailSO(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetSalesInvDetail?order_no=" + val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem.list_order = response.data.data.result.list_order;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailPurchase(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPurchaseDetail?purchase_no=" + val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem.list_order = response.data.data.result.list_order;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailReturnCust(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetRerturnCustomerDetail?return_customer_no=" +
            val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem.list_order = response.data.data.result.list_order;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailReturnSupplier(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetRerturnSupplierDetail?return_supplier_no=" +
            val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem.list_order = response.data.data.result.list_order;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailPindahGudang(val){
      
      this.defaultItem.list_order = [];
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanPindahGudangDetail/" +val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem.list_order = response.data.data.result.GetPindahGudangDetail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetail(val) {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetStockInOutDetail?code_stock=" +
            this.defaultItem.code_stock,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result.data;
          this.defaultItem.list_order = response.data.data.result.detail;

          //   console.log(this.defaultItem);

          console.log(val);
          if (val == "edit") {
            this.GetCustomer();
            console.log(val);
          }
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        code_stock: this.defaultItem.code_stock,
        code_admin: this.kodeAdmin,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteStockInOut",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
        if (
          error.response.status == 400 &&
          error.response.data.data.status.description == "error_msg"
        ) {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = error.response.data.data.result;
        }
      }
    },
    async addDataStockInOut(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddStockInOut",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogAdd = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data, Data SO sudah terbuat Invoice";
        }
      }
    },
    async singkronSO(){
      this.showHasil=true;
      this.showLoading=true;


      const datapost = {
        admin_id: 'nama',
      };
      try {
        const response = await axios.post(
          this.BaseUrlGet + "SynchronizeStockInOutSO",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response);
        if (response.status == 200) {
          this.showHasil=true;
          this.showLoading=false;

          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses sincronize data";
          // this.initialize();
        } else {
          //   this.dialog = false;
          this.showHasil=true;
          this.showLoading=false;

          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal";
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal";
        }
        
          this.showHasil=true;
          this.showLoading=false;
      }

    },
    async singkronPurchase(){
      this.showHasil=true;
      this.showLoading=true;
    },

    synchronize(){
      this.dialogSingkron = true;
    },  
    closeSingkron(){
      this.dialogSingkron = true;
    },

    showAddModalSO() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.e1 = 1;

      this.defaultItem.order = "";
      this.defaultItem.tipe_asal = "";
      this.defaultItem.tipe_in_out = "";
      this.dataasal = [];

      this.showButtonAdd = false;

      this.dialogAdd = true;
    },

    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.getDetail("edit");
      this.value = "edit";
      this.e1 = 1;
      // this.dialog = true;
      this.dialogEditShow = true;
    },
    editinvoice() {
      // console.log(item);
      // this.formTitle = "Edit Item";
      // this.defaultItem = Object.assign({}, item);
      // this.getDetail("edit");
      // this.value = "edit";
      this.dialogEditShow = false;
      this.e1 = 1;
      this.dialog = true;
    },
    editstatus() {
      this.dialogEditShow = false;
      this.dialogEditStatus = true;
    },
    saveeditstatus() {
      this.updateStatusInvoice();
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.getDetail("detail");
      this.dialogDetail = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
      this.dialogAdd = false;
      this.dialogEditShow = false;
      this.dialogEditStatus = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      // order_no: "",
      // console.log(this.defaultItem.code_customer);
      // console.log(this.defaultItem.code_salesman);
      // console.log(this.defaultItem.alamat);
      // console.log(this.defaultItem.jumlah_discount);
      // console.log(this.defaultItem.term_of_payment);
      // console.log(this.defaultItem.list_order);
      // console.log(this.defaultItem.kabROCombobox);

      if (
        this.defaultItem.code_customer.length != 0 &&
        this.defaultItem.code_salesman.length != 0 &&
        this.defaultItem.alamat.length != 0 &&
        // this.defaultItem.jumlah_discount.length != 0 &&
        // this.defaultItem.term_of_payment.length != 0 &&
        this.defaultItem.list_order.length != 0 &&
        this.defaultItem.kabROCombobox.id_alamat.length != 0
      ) {
        if (this.defaultItem.order_no) {
          console.log(this.defaultItem);
          this.updateData();
        } else {
          console.log("add");
          console.log(this.defaultItem);
          this.addData();
        }
        // this.close();

        // console.log(this.defaultItem.kabROCombobox.text);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },
    savestockinout() {
      const datapost = {
        code_asal: this.defaultItem.order.code_asal,
        tipe_asal: this.defaultItem.tipe_asal,
        tipe_in_out: this.defaultItem.tipe_in_out,
        keterangan: this.defaultItem.keterangan,
        code_admin: this.kodeAdmin,
      };
      console.log(datapost);
      var check = HelperGlobalService.checkMandatory(datapost, "object");
      if (check == true) {
        this.addDataStockInOut(datapost);
      } else {
        console.log(check);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    changeTOP(val) {
      console.log(val);
      if (val == "Tempo") {
        this.dateTempo = true;
      } else {
        this.dateTempo = false;
        this.defaultItem.lama_tempo = 0;
      }
      // this.discSelectedCustomer = val.diskon;
    },

    changeSalesman(val) {
      this.GetCustomer();
    },

    changeTipe(val) {
      this.showButtonAdd = false;
      this.defaultItem.order = [];
      //   this.defaultItem.list_order = [];
      this.defaultItem.tipe_asal = val;
      this.defaultItem.tipe_in_out = "";
      if (val == "order") {
        this.GetSalesOrder();
        this.defaultItem.tipe_in_out = "Out";
      } else if (val == "purchase") {
        this.GetPurchaseOrder();
        this.defaultItem.tipe_in_out = "In";
      } else if (val == "return_customer") {
        this.GetReturnCustomer();
        this.defaultItem.tipe_in_out = "In";
      } else if (val == "return_supplier") {
        this.GetReturnSupplier();
        this.defaultItem.tipe_in_out = "Out";
      }else if (val == "pindah_gudang") {
        this.GetLaporanPindahGudang();
        this.defaultItem.tipe_in_out = "Out";
      } else {
      }
    },
    changeCode(val) {
      console.log(val);
      console.log(this.defaultItem.tipe_asal);
      this.defaultItem.list_order = [];
      if (this.defaultItem.tipe_asal == "order") {
        this.getDetailSO(val.code_asal);
        this.defaultItem.keterangan = "Pengurangan stock dari SO";
      } else if (this.defaultItem.tipe_asal == "purchase") {
        this.getDetailPurchase(val.code_asal);
        this.defaultItem.keterangan = "Penambahan stock dari purchase";
      } else if (this.defaultItem.tipe_asal == "return_customer") {
        this.getDetailReturnCust(val.code_asal);
        this.defaultItem.keterangan =
          "Penambahan stock dari return dari customer";
      } else if (this.defaultItem.tipe_asal == "return_supplier") {
        this.getDetailReturnSupplier(val.code_asal);
        this.defaultItem.keterangan =
          "Pengurangan stock dari return ke supplier";
      }else if (this.defaultItem.tipe_asal == "pindah_gudang") {
        this.getDetailPindahGudang(val.code_asal);
        this.defaultItem.keterangan =
          "Pengurangan stock dari gudang";
      } else {
      }
      this.showButtonAdd = true;
    },

    changeorderstatus(val) {
      console.log(val);
      if (val == "partial") {
        this.status_show_bayar = true;
      } else {
        this.status_show_bayar = false;
      }
    },

    changeProductSelected(val) {
      console.log(val);
      this.codeProductTemp = val.code_product;
      this.NamaProductTemp = val.nama_product;
      this.hargaProductTemp = val.harga_jual;
      //   this.jumlahProductTemp = val.total;
      this.dialogAddProduct = true;
    },

    closeProduct() {
      this.dialogAddProduct = false;
    },
    addProduct() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahProductTemp > 0) {
        var listmenu = this.defaultItem.list_order;
        console.log(listmenu);
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].code_product == this.codeProductTemp) {
            result = true;
            break;
          }
        }

        if (result == false) {
          var productset = {
            code_product: "",
            diskon: "",
            harga: "",
            jumlah: "",
            nama_product: "",
            deskripsi: "",
          };

          productset.code_product = this.codeProductTemp;
          productset.harga = this.hargaProductTemp;
          productset.jumlah = this.jumlahProductTemp;
          productset.nama_product = this.NamaProductTemp;
          if (this.deskripsiProductTemp) {
            productset.deskripsi = this.deskripsiProductTemp;
          } else {
            productset.deskripsi = "-";
          }

          this.defaultItem.list_order.push(productset);

          this.dialogAddProduct = false;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Product Sudah Ada..!!!";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitemproduct(item) {
      this.editedIndex = await this.defaultItem.list_order.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.list_order.splice(this.editedIndex, 1);
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
    },

    printImage() {
      var no_ord = this.defaultItem.order_no;
      var nama_cust = this.defaultItem.nama_customer;
      const arrayname = nama_cust.split(" ");
      var nama_cust_split = arrayname[0];
      // console.log(no_ord);
      // console.log(nama_cust_split);
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = nama_cust_split + "_" + no_ord + ".png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    gettermofpayment(top, lama) {
      if (top) {
        if (top.toLowerCase() == "tunai") return "Tunai";
        else return top + " | " + lama + " Hari";
      }
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },

    getColorStatusKonfirmasiAdmin(status) {
      if (status == "unpaid") return "red";
      else if (status == "partial") return "orange";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "unpaid") return "mdi-alert-octagon";
      //   else if (status == "7") return "mdi-package-variant";
      //   else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-check-underline-circle";
    },
    getColorStatusApproval(status) {
      if (status == "waiting_for_approval") return "orange";
      else if (status == "rejected") return "red";
      else return "green";
    },
    getIconStatusApproval(status) {
      if (status == "waiting_for_approval") return "mdi-calendar-clock-outline";
      else if (status == "rejected") return "mdi-close-circle-multiple-outline";
      else return "mdi-check-underline-circle";
    },

    showdiskon(val1, val2, val3) {
      var rslt = "-";
      if (Number(val1) != 0) {
        rslt = val1 + "% ";
      }
      if (Number(val2) != 0) {
        if (rslt == "-") {
          rslt = val2 + "% ";
        } else {
          rslt = rslt + "+" + val2 + "% ";
        }
      }
      if (Number(val3) != 0) {
        if (rslt == "-") {
          rslt = val3 + "% ";
        } else {
          rslt = rslt + "+" + val3 + "% ";
        }
      }
      return rslt;
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



