<template>
  <div>
    <v-card class="mx-3">
      <v-container class="pt-5 px-5">
        <v-row>
          <v-btn dark class="mb-2" @click="backtable()" color="red">
            <v-icon left> mdi-arrow-left-circle </v-icon> Back
          </v-btn>
          <v-spacer class="d-none d-sm-block"></v-spacer>
          <h4 class="d-none d-sm-block">
            Detail Piutang {{ defaultItem.nama_customer }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="download()" color="#b3b928">
            <v-icon left> mdi-download-circle-outline </v-icon> Download
          </v-btn>
        </v-row>
        <v-row class="d-sm-none">
          <v-spacer></v-spacer>
          <h4>Detail Piutang {{ defaultItem.nama_customer }}</h4>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" style="font-size: 14px">Total Piutang</th>
              <th class="text-center" style="font-size: 14px">
                Total Pembayaran
              </th>
              <th class="text-center" style="font-size: 14px">Sisa Piutang</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center" style="font-size: 14px">
                {{ getRupiah(totalpiutangall) }}
              </td>
              <td class="text-center" style="font-size: 14px">
                {{ getRupiah(totalpembayaran) }}
              </td>
              <td class="text-center" style="font-size: 14px">
                {{ getRupiah(sisapiutang) }}
              </td>
            </tr>
          </tbody>
        </template>
      <hr class="mb-2">
      </v-simple-table>
    </v-card>
    <v-card class="mx-3">
      <v-row>
        <v-col class="pb-1 pl-2 pr-0" cols="12" sm="7" md="7">
          <h4 class="ml-4">Detail Piutang</h4>
          <hr class="ml-4 mb-2" style="width:60%">
          <v-row>
            <v-col cols="12" sm="5" md="5"><h5 class="ml-7">Piutang Awal  : {{getRupiah(totalpiutangawal)}}</h5></v-col>
            <v-col cols="12" sm="7" md="7"> <h5 class="ml-1">Piutang Order : {{getRupiah(totalpiutangorder)}}</h5></v-col>
          </v-row>
          <h4 class="ml-4 mt-3">List Piutang Order</h4>
          <hr class="ml-4 mb-2" style="width:60%">
          <v-data-table
            :headers="headersPiutang"
            :items="dataobjectPiutang"
            :search="search"
            :loading="loadtable"
            loading-text="Loading... Please wait"
            class="rounded elevation-6 mx-3 pa-1 fontall"
          >
            <template v-slot:item.total_piutang="{ item }">
              {{ getRupiah(item.total_piutang) }}
            </template>

            <template v-slot:item.created_at="{ item }">
              {{ getTanggal(item.created_at) }}
            </template>    
            <template v-slot:item.actions="{ item }">
                <v-icon
                  class="mr-1"
                  @click="updatestatusinvoice(item.code_order)"
                  color="warning"
                >
                  mdi-pencil-circle-outline
                </v-icon>
            </template>
          </v-data-table>
        </v-col>
        <v-col class="pb-1 pl-0 pr-2" cols="12" sm="5" md="5">
          <h4 class="ml-4">Detail Pembayaran</h4>
          <hr class="ml-4 mb-2" style="width:60%">
          <v-row>
            <v-col cols="12" sm="12" md="12"><h5 class="ml-7">Total Pembayaran  : {{getRupiah(totalpembayaran)}}</h5></v-col>
          </v-row>
          <h5 class="ml-4 mt-3">List Pembayaran</h5>
          <hr class="ml-4 mb-2" style="width:60%">
          <v-data-table
            :headers="headersPembayaran"
            :items="dataobjectPembayaran"
            :search="search"
            :loading="loadtable"
            :items-per-page="5"
            loading-text="Loading... Please wait"
            class="rounded elevation-6 mx-3 pa-1 fontall"
          >
            <template v-slot:item.nominal="{ item }">
              {{ getRupiah(item.nominal) }}
            </template>

            <template v-slot:item.tanggal="{ item }">
              {{ getTanggal(item.tanggal) }}
            </template>    
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Modal Detail -->
      <v-dialog v-model="dialogDetailInvoice" max-width="700px">
        <v-card>
          <v-card-title class="headermodalstyle mb-0">
            <span>Detail Pembayaran</span>
          </v-card-title>

          <v-card-text class="fontall pa-1">
            <v-container
              v-if="loaddetail == true"
              fluid
              fill-height
              style="background-color: rgba(255, 255, 255, 0.5)"
            >
              <v-layout justify-center align-center>
                <v-progress-circular
                  :size="80"
                  :width="10"
                  indeterminate
                  color="primary"
                >
                </v-progress-circular>
              </v-layout>
            </v-container>

            <v-container v-if="loaddetail == false">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        No Invoice
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ detail.code_order }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Total Piutang
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.total_piutang) }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Total Bayar
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.total_payment) }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Sisa Piutang
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.sisapiutang) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-2 mb-1">
                <v-divider
                  class="mx-2 mt-4"
                  style="border-color: black"
                ></v-divider>
                <v-divider
                  class="mx-2 mt-4"
                  style="border-color: black"
                ></v-divider>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center d-none d-sm-block"
                        style="width: 300px; font-size: 13px"
                      >
                        Keterangan
                      </th>
                      <th class="text-center" style="font-size: 13px">
                        Pembayaran
                      </th>
                      <th class="text-center" style="font-size: 13px">
                        Tanggal
                      </th>
                      <!-- <th
                        class="text-center"
                        style="width: 80px; font-size: 13px"
                      >
                        Action
                      </th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="product in detail.detail" :key="product.id">
                      <td
                        class="text-center d-none d-sm-block"
                        style="font-size: 13px"
                      >
                        {{ product.keterangan }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ getRupiah(product.total_akhir) }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ getTanggal(product.created_at) }}
                      </td>
                      <!-- <td class="text-center" style="font-size: 13px">
                        <v-icon
                          class="mr-1"
                          @click="
                            editpembayaraninv(
                              product.id,
                              product.created_at,
                              product.total_akhir
                            )
                          "
                          color="orange"
                        >
                          mdi-pencil-circle-outline
                        </v-icon>
                        <v-icon
                          class="mr-1"
                          @click="hapuspembayaranInv(product.id)"
                          color="red"
                        >
                          mdi-delete-circle-outline
                        </v-icon>
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPembayaran" max-width="400px">
        <v-card class="fontall">
          <v-card-title class="mb-0 headermodalstyle"
            >Update Pembayaran Invoice?</v-card-title
          >
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-menu v-model="menu2" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datepicker"
                        slot="activator"
                        label="Tanggal Pembayaran"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datepicker"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="bayar_temp"
                    label="Isikan Nominal Bayar"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="saveepembayaran"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >Save</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card class="fontall">
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <!-- <v-card-text>
                <h4 class="ml-2" style="color: red">
                  Note: Invoice sudah di rekam ke piutang, jika di hapus maka
                  <span style="text-decoration: underline"
                    >"Total Piutang Customer akan berkurang"</span
                  >
                </h4>
              </v-card-text> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="deletepembayaran"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogupdatestatus" max-width="500px">
        <v-card class="fontall">
          <v-card-title class="headline">Update status Invoice?</v-card-title>
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-select
                    v-model="status_so_temp"
                    :items="itemsStatusSO"
                    item-value="value"
                    item-text="text"
                    label="Pilih Status"
                    outlined
                    :rules="[(v) => !!v || 'Field is required']"
                  ></v-select>
                </v-col>
                <!-- <v-col
                      v-if="status_show_bayar == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="bayar_temp"
                        label="Isikan Nominal Bayar"
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-col> -->
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <h4 class="ml-2" style="color: red">
                    Note: Hanya merubah status Invoice
                    <span style="text-decoration: underline"
                      >"Tidak akan merubah piutang dan pemasukan"</span
                    >
                  </h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="updatepaidItemConfirm"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >Save</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    
      <v-dialog v-model="dialogMaintenance" max-width="600px">
        <v-card class="fontall">
          <v-card-title class="mb-0 headermodalstyle"
            >Opps Sorry!!!</v-card-title
          >
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row class="mt-4 mb-5">
                <v-col cols="12">
                  <div style="text-align: center;font-size: larger;font-weight: 900;">Sistem Sedang Maintenance, Maaf atas ketidaknyamanannya</div>  
                </v-col>              
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "ManajemenPiutang",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    valid: true,
    loadtable: false,
    loaddetail: false,
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogDetailInvoice: false,
    detailshow: false,
    dialogPembayaran: false,
    dialogupdatestatus: false,
    dialogBayarHutang: false,
    dialogAddProduct: false,
    dialogPilihDetail: false,
    detailpagebayar: false,
    dialogMaintenance:false,
    search: "",
    authtoken: "",
    BaseUrl: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrlDBImage: "",
    
    headersAwal: [
      { text: "Code Order", value: "code_order" },
      { text: "Total Piutang", value: "total_piutang" },
      { text: "Total Pembayaran", value: "total_payment" },
      { text: "Sisa Piutang", value: "sisapiutang" },
      { text: "Tanggal", value: "created_at" },
    ],
    headersPiutang: [
      { text: "Code Order", value: "code_order" },
      { text: "Status", value: "payment_status" },
      { text: "Total Piutang", value: "total_piutang" },
      { text: "Tanggal", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersPembayaran: [
      { text: "Keterangan", value: "keterangan"  },
      { text: "Jumlah Bayar", value: "nominal", width: "30%" },
      { text: "Tanggal", value: "tanggal", width: "20%" },
      // { text: "Admin", value: "nama_admin", width: "18%" },
    ],
    dataobjectAwal: [],
    dataobjectPembayaran: [],
    dataobjectPiutang: [],
    itemsCategory: [],
    itemsStatusSO: [
      { text: "Unpaid", value: "unpaid" },
      { text: "Partial", value: "partial" },
      { text: "Paid", value: "paid" },
    ],

    defaultItem: {
      id: "",
      code_customer: "",
      nama_customer: "",
      total_piutang: "",
      total_payment: "",
      created_at: "",
      detail: "",
      code_salesman:"",
    },

    detail: {
      code_order: "",
      total_piutang: "",
      total_payment: "",
      sisapiutang: "",
      detail: [],
    },


    targetItem: {
      customer:[],
      code_customer: "",
      akun_debit: "",
      akun_kredit: "",
      code_admin: "",
      list_bayar: "",
      code_salesman:[],
    },
    
    itempembayaraninv:[],
    itemsInvoiceCust:[],
    itemscustomer:[],
    itemsAkun: [],
    itemlistbayar: [],
    dialogBayar: false,
    dialogTambahBayar: false,
    dialogDeleteBayar: false,
    editedIndexBayar: -1,
    editedItemBayar: {
      invoice:[],
      code_order: "",
      total: "",
      sisa: "-",
      nominal: "-",
    },
    defaultItemBayar: {
      invoice:[],
      code_order: "",
      total: "",
      sisa: "-",
      nominal: "-",
    },

    code_order_temp: "",
    status_so_temp: "",
    datepicker: new Date().toISOString().substr(0, 10),
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: "",
    bayar_temp: 0,
    id_temp: 0,
    code_inv_temp: 0,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    checkboxBayarSemua:'',

    dataadmin:[],
    admin_id: "",

    kodeCustomer:"",

    totalpiutangawal:0,
    totalpiutangorder:0,
    totalpiutangall:0,
    totalpembayaran:0,
    sisapiutang:0,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.kodeCustomer = localStorage.getItem("kodeCustomer");
    this.getDetailAwal();
    this.getDetailPiutang();
    this.getDetailPembayaran();
    this.GetCustomer();
    this.getAkun();
    setTimeout(this.gettotall(), 2000)
  },

  methods: {
    async gettotall(){
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetTotalPiutangCustomerV2?code_customer=" +
            this.kodeCustomer+
            "&type=awal",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.sisapiutang = response.data.data.result.sisa_piutang_all;
          this.totalpiutangall = response.data.data.result.total_piutang_all;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobjectAwal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobjectAwal = [];
        }
      }
    },
    async getDetailAwal() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPiutangCustomerV2?code_customer=" +
            this.kodeCustomer+
            "&type=awal",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobjectAwal = response.data.data.result.data;
          this.totalpiutangawal = response.data.data.result.total;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobjectAwal = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobjectAwal = [];
        }
      }
    },
    async getDetailPiutang() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPiutangCustomerV2?code_customer=" +
            this.kodeCustomer+
            "&type=order",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobjectPiutang = response.data.data.result.data;
          this.totalpiutangorder = response.data.data.result.total;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobjectPiutang = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobjectPiutang = [];
        }
      }
    },
    async getDetailPembayaran() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPembayaranPiutangV2?code_customer=" +
            this.kodeCustomer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobjectPembayaran = response.data.data.result.data;
          this.totalpembayaran = response.data.data.result.total;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobjectPembayaran = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobjectPembayaran = [];
        }
      }
    },
    async getAkun() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAkun = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAkun = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAkun = [];
        }
      }
    },
    
    async GetAdmin() {
      this.admin_role = 1;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAllAdminTemp",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataadmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataadmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataadmin = [];
        }
      }
    },
    async GetCustomer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomer",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemscustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.datacustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.datacustomer = [];
        }
      }
    },
    
    async getInvCust(code_customer) {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanPiutangDetail?code_customer=" +
            code_customer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          // this.defaultItem = response.data.data.result;
          this.itemsInvoiceCust = response.data.data.result.detail;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },

    


    async getDetail() {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanPiutangDetail?code_customer=" +
            this.defaultItem.code_customer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async getDetailInvoice(val) {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetPiutangInvDetail?code_order=" + val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.detail.code_order =
            response.data.data.result.GetLaporanPiutang.code_order;
          this.detail.total_piutang =
            response.data.data.result.GetLaporanPiutang.total_piutang;
          this.detail.total_payment =
            response.data.data.result.GetLaporanPiutang.total_payment;
          this.detail.sisapiutang =
            response.data.data.result.GetLaporanPiutang.sisapiutang;
          this.detail.detail = response.data.data.result.detail;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async AddPiutangInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPiutangInvDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetailInvoice = false;
          this.dialogPembayaran = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async EditPiutangInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditPiutangInvDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetailInvoice = false;
          this.dialogPembayaran = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async DeletePiutangInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeletePiutangInvDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetailInvoice = false;
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async UpdateStatusInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditOnlyStatusInvoice",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogupdatestatus = false;
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    
    async AddPembayaranPiutang(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPembayaranPiutang",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogBayar = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
          // this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    
    async getDetailPembayaranHutang() {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPembayaranPiutang?code_customer=" +
            this.defaultItem.code_customer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.itempembayaraninv = response.data.data.result.GetLaporanPembayaranpiutang;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },

    gantiRupiah(){
      const textId = document.getElementById("nominalrupiah");
      console.log(this.editedItemBayar.nominal);
      if(this.editedItemBayar.nominal.length > 3){
        textId.value = this.formatRupiahNow(this.editedItemBayar.nominal);
      }
    },
    formatRupiahNow(angka){      
			return angka.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    detailPilih(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogPilihDetail = true;
    },
    detailItem() {
      this.dialogPilihDetail = false;
      // this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      // console.log(this.defaultItem.path_image);
      this.getDetail();
      //   this.dialogDetail = true;
      this.detailshow = true;
      this.detailpagebayar = false;
      
    },    
    detailBayar() {
      this.dialogPilihDetail = false;
      // this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      // console.log(this.defaultItem.path_image);
      this.getDetailPembayaranHutang();
      //   this.dialogDetail = true;
      this.detailshow = true;
      this.detailpagebayar = true;
    },
    backtable() {
      // localStorage.setItem("kodeCustomer", item.code_customer);
      this.$router.push("/ManajemenPiutang");
      // this.detailshow = false;
      // this.detailpagebayar = false;
    },
    detailInv(val) {
      this.dialogDetailInvoice = true;
      this.getDetailInvoice(val);
      this.code_order_temp = val;
    },
    download() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(str.substring(0, str.length - 4) + "ExportLaporanPiutang");
    },
    updatestatusinvoice(code_inv) {
      this.status_so_temp = "";
      this.code_order_temp = code_inv;
      this.dialogupdatestatus = true;
    },
    addpembayaraninv() {
      this.id_temp = "";
      this.bayar_temp = 0;
      this.datepicker = new Date().toISOString().substr(0, 10);
      this.dialogPembayaran = true;
    },
    editpembayaraninv(id, tanggal, bayar) {
      this.id_temp = id;
      this.bayar_temp = bayar;
      this.datepicker = tanggal;
      this.dialogPembayaran = true;
    },
    hapuspembayaranInv(id) {
      this.id_temp = id;
      this.dialogDelete = true;
    },

    close() {
      this.dialogupdatestatus = false;
      this.dialogPembayaran = false;
      this.dialogDelete = false;
    },
    saveepembayaran() {
      const datapost = {
        code_order: this.code_order_temp,
        id_pemasukan: "default",
        nominal: JSON.stringify(this.bayar_temp),
        date: this.datepicker,
        code_admin: this.admin_id,
      };
      //   console.log(datapost);
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);
        datapost.nominal = parseInt(this.bayar_temp);
        if (this.id_temp) {
          datapost.id_pemasukan = this.id_temp;
          console.log("update");
          this.EditPiutangInvDetail(datapost);
        } else {
          datapost.id_pemasukan = "";
          console.log("add");
          this.AddPiutangInvDetail(datapost);
          // this.addData(datapost);
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },
    deletepembayaran() {
      console.log(this.id_temp);
      const datapost = {
        id_pemasukan: this.id_temp,
        code_order: this.code_order_temp,
        code_admin: this.admin_id,
      };
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);
        this.DeletePiutangInvDetail(datapost);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },
    updatepaidItemConfirm() {
      const datapost = {
        status_so: this.status_so_temp,
        order_no: this.code_order_temp,
        code_admin: this.admin_id,
      };
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);
        this.UpdateStatusInvDetail(datapost);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    
    changecheckboxBayarSemua(val){
      console.log(val);
      if(val == true){
        this.editedItemBayar.nominal = this.editedItemBayar.sisa
      }else{
        this.editedItemBayar.nominal = 0
      }
    },

    
    saveBayarHutang(){
      const datapost = {
        code_customer: this.targetItem.code_customer,
        akun_debit: this.targetItem.akun_debit,
        akun_kredit: this.targetItem.akun_kredit,
        list_bayar: this.itemlistbayar,
        date: this.date,
        code_admin: this.admin_id,
      };
        console.log(datapost);
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);      
        datapost.list_bayar = JSON.stringify(this.itemlistbayar);
        if (this.id_bayar_hutang_temp) {
          datapost.id_pengeluaran = this.id_temp;
          console.log("update");
          this.EditPiutangInvDetail(datapost);
          // this.updateData(datapost);
        } else {
          datapost.id_pengeluaran = "";
          console.log("add");
          this.AddPembayaranPiutang(datapost);
          // this.addData(datapost);
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    
    changeInvCustBayar(val){
      console.log(val);
      this.editedItemBayar.code_order = val.code_order;
      this.editedItemBayar.total = val.total_piutang;
      this.editedItemBayar.sisa = val.sisapiutang;
    },
    changeCustBayar(val){
      console.log(val)
      this.getInvCust(val.code_customer);
      this.targetItem.code_customer=val.code_customer;
    },
    changeSales(){
      this.itemscustomer = [];
      this.targetItem.code_customer = [];
      this.targetItem.code_customer.code = '';
      this.GetCustomer();
    },
    bayarHutang(){
      this.dialogBayar = true;
      this.targetItem.akun_debit = '1-126';
      this.targetItem.akun_kredit = '1-108';
      this.id_bayar_hutang_temp = '';
    },
    closeBayarHutang(){
      this.dialogBayar = false;
    },
    closeDialogBayar(){
      this.dialogBayarHutang= false;
    },

    
    detailPagePembayaran(val){
      this.dialogMaintenance =true;
    },
    editPagePembayaran(item){
      this.dialogMaintenance =true;
    },
    deletePagePembayaran(item){
      this.dialogMaintenance =true;
    },

    deleteItemBayar(item) {
      this.editedIndexBayar = this.itemlistbayar.indexOf(item);
      this.editedItemBayar = Object.assign({}, item);
      this.dialogDeleteBayar = true;
    },
    deleteItemConfirmBayar() {
      this.itemlistbayar.splice(this.editedIndexBayar, 1);
      this.closeDeleteBayar();
    },
    closeBayar() {
      this.dialogTambahBayar = false;
      this.$nextTick(() => {
        this.editedItemBayar = Object.assign({}, this.defaultItemBayar);
        this.editedIndexBayar = -1;
      });
    },
    closeDeleteBayar() {
      this.dialogDeleteBayar = false;
      this.$nextTick(() => {
        this.editedItemBayar = Object.assign({}, this.defaultItemBayar);
        this.editedIndexBayar = -1;
      });
    },
    saveBayar() {
      // console.log(this.editedItemKas.no_akun);
      // console.log(this.editedItemKas.no_akun);
      if (this.editedIndexBayar > -1) {
        Object.assign(
          this.itemlistbayar[this.editedIndexBayar],
          this.editedItemBayar
        );
      } else {
        // this.editedItemKas.nama_akun = this.editedItemKas.akun.nama_akun;
        // this.editedItemKas.no_akun = this.editedItemKas.akun.no_akun;
        this.itemlistbayar.push(this.editedItemBayar);
        //   console.log(this.datepicker4);
      }
      this.closeBayar();
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },

    getTanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>



