<template>
  <div>
    <!-- <div>
      <v-card class="rounded elevation-4 mx-3 mb-2">
        <v-card-title style="background: #d5415f">
          <span class="headline">Data Kas Masuk</span>
        </v-card-title>
      </v-card>
    </div> -->
    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1"
    >
      <template v-slot:item.tanggal="{ item }">
        {{ gettanggal(item.tanggal) }}
      </template>

      <template v-slot:item.amount="{ item }">
        {{ getRupiah(item.amount) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-title style="background: #d5415f">
                <span class="headline">{{ formTitle }} Kas Keluar</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Pilih Tanggal"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="8" md="8">
                      <v-select
                        v-model="defaultItem.no_akun"
                        :items="itemsAkun"
                        item-value="no_akun"
                        item-text="nama_akun"
                        label="Pilih Akun Asal"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="defaultItem.keterangan"
                        label="Keterangan"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerslistkas"
                        :items="itemlistkas"
                        sort-by="calories"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>Kas Keluar</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogKas" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Tambah Kas
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Daftar Kas</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-combobox
                                          v-model="editedItemKas.akun"
                                          :items="itemsAkun"
                                          label="Pilih Akun"
                                          item-value="no_akun"
                                          item-text="nama_akun"
                                          clearable
                                          outlined
                                          :rules="[
                                            (v) => !!v || 'Field is required',
                                          ]"
                                        ></v-combobox>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemKas.amount"
                                          label="Jumlah"
                                          type="number"
                                          outlined
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-textarea
                                          v-model="editedItemKas.keterangan"
                                          label="Keterangan"
                                          outlined
                                        ></v-textarea>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveKas"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteKas"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >Are you sure you want to delete this
                                  item?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteKas"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirmAnak"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small @click="deleteItemKas(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "KasKeluar",
  data: () => ({

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Tanggal",
        value: "tanggal",
      },
      { text: "Kode Ref Kas", value: "code_kas" },
      { text: "Deskripsi", value: "keterangan" },
      { text: "Total", value: "amount" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      tipe: "",
      code_kas: "",
      tanggal_kas: "",
      keterangan: "",
      no_akun: "",
    },

    itemsAkun: [],

    itemlistkas: [],
    headerslistkas: [
      {
        text: "No Akun",
        align: "start",
        sortable: false,
        value: "no_akun",
      },
      { text: "Nama Akun", value: "nama_akun" },
      { text: "Jumlah", value: "amount" },
      { text: "keterangan", value: "keterangan" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dialogKas: false,
    dialogDeleteKas: false,
    editedIndexKas: -1,
    editedItemKas: {
      akun: "",
      no_akun: "",
      nama_akun: "-",
      amount: "-",
      keterangan: "-",
    },
    defaultItemKas: {
      akun: "",
      no_akun: "",
      nama_akun: "-",
      amount: "-",
      keterangan: "-",
    },

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.getAkun();
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetKas?limit=all&tipe=kas_keluar",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetKasDetail?code_kas="+this.defaultItem.code_kas,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          // this.dataobject = response.data.data.result.data;
          this.defaultItem = Object.assign({}, response.data.data.result.GetKas);
          this.date = response.data.data.result.GetKas.tanggal;
          this.itemlistkas = response.data.data.result.GetKasDetail;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getAkun() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAkun = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAkun = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAkun = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        code_kas: this.defaultItem.code_kas,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteKas",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        tipe: this.defaultItem.tipe,
        no_akun: this.defaultItem.no_akun,
        keterangan: this.defaultItem.keterangan,
        tanggal_kas: this.date,
        list_kas: JSON.stringify(this.itemlistkas),
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddKas",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          this.dialog = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async updateData() {
      const datapost = {
        code_kas: this.defaultItem.code_kas,
        tipe: this.defaultItem.tipe,
        no_akun: this.defaultItem.no_akun,
        keterangan: this.defaultItem.keterangan,
        tanggal_kas: this.date,
        list_kas: JSON.stringify(this.itemlistkas),
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "UpdateKas",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          this.dialog = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },

    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.id = "";
      this.defaultItem.code_kas = "";
      this.defaultItem.tipe = "kas_keluar";
      this.defaultItem.no_akun = "";
      this.defaultItem.keterangan = "";
      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      var val = item.tanggal;
      this.date = val.split(" ")[0];
      this.getDetail();
      this.value = "edit";
      this.dialog = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      this.defaultItem.tipe = 'kas_keluar';
      this.defaultItem.tanggal_kas = this.date;
      console.log(this.defaultItem);
      console.log(this.itemlistkas);

      if(this.defaultItem.code_kas){
        this.updateData();
      }else{
        this.addData();
      }
    },

    deleteItemKas(item) {
      this.editedIndexKas = this.itemlistkas.indexOf(item);
      this.editedItemKas = Object.assign({}, item);
      this.dialogDeleteKas = true;
    },
    deleteItemConfirmAnak() {
      this.itemlistkas.splice(this.editedIndexKas, 1);
      this.closeDeleteKas();
    },
    closeKas() {
      this.dialogKas = false;
      this.$nextTick(() => {
        this.editedItemKas = Object.assign({}, this.defaultItemKas);
        this.editedIndexKas = -1;
      });
    },
    closeDeleteKas() {
      this.dialogDeleteKas = false;
      this.$nextTick(() => {
        this.editedItemKas = Object.assign({}, this.defaultItemKas);
        this.editedIndexKas = -1;
      });
    },
    saveKas() {
      console.log(this.editedItemKas.no_akun);
      console.log(this.editedItemKas.no_akun);
      if (this.editedIndexKas > -1) {
        Object.assign(
          this.itemlistkas[this.editedIndexKas],
          this.editedItemKas
        );
      } else {
        this.editedItemKas.nama_akun = this.editedItemKas.akun.nama_akun;
        this.editedItemKas.no_akun = this.editedItemKas.akun.no_akun;
        this.itemlistkas.push(this.editedItemKas);
        //   console.log(this.datepicker4);
      }
      this.closeKas();
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      var date = val.split(" ")[0];
      var time = val.split(" ")[1];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },

    getRupiah(val) {
      var bilangan = val;

      var number_string = bilangan.toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);

      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      return "Rp. " + rupiah;
    },
  },
};
</script>