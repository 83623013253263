<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.nama_customer="{ item }">
        <tr>
          <h4>{{ item.nama_customer }}</h4>
        </tr>
        <tr>
          <h5>{{ item.alamat_ro }}</h5>
        </tr>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ gettanggal(item.created_at) }}
      </template>

      <template v-slot:item.status_so="{ item }">
        <v-chip
          outlined
          :color="getColorStatusKonfirmasiAdmin(item.status_so)"
          dark
        >
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusKonfirmasiAdmin(item.status_so)"
          >
            {{ getIconStatusAdmin(item.status_so) }}
          </v-icon>
          {{ item.status_so }}
        </v-chip>
      </template>

      <template v-slot:item.status_task="{ item }">
        <v-chip outlined :color="getColorStatusApproval(item.status_task)" dark>
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusApproval(item.status_task)"
          >
            {{ getIconStatusApproval(item.status_task) }}
          </v-icon>
          {{ item.status_task }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-dialog v-model="dialog" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle" style="margin-bottom: 2px">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header style="height: 65px">
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Data Customer
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable :complete="e1 > 2" step="2">
                      List Order
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="3">
                      Pembayaran
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-0">
                        <v-row class="mb-0">
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.code_salesman"
                              :items="dataadmin"
                              item-value="code"
                              item-text="nama"
                              label="Pilih Salesman"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              v-on:change="changeSalesman"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.code_customer"
                              :items="datacustomer"
                              item-value="code_customer"
                              item-text="nama_customer"
                              label="Pilih Customer"
                              outlined
                              v-on:change="changeCustomer"
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.kabROCombobox"
                              :items="itemsKabRO"
                              item-value="id_alamat"
                              item-text="text"
                              label="Pilih Kec/Kab"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-textarea
                              v-model="defaultItem.alamat"
                              label="Alamat"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 2"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="2">
                      <v-container class="mb-0">
                        <v-row class="mb-0">
                          <h4>Pilih Product</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.itemsProductSelected"
                              :items="itemsProduct"
                              item-value="code_product"
                              item-text="nama_product"
                              label="Pilih Product"
                              v-on:change="changeProductSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Product</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row class="mb-2">
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListProduct"
                              :items="defaultItem.list_order"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitemproduct(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 3"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="3">
                      <v-container class="mb-0">
                        <v-row class="mb-1">
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.term_of_payment"
                              :items="itemsTOP"
                              item-value="value"
                              item-text="text"
                              label="Term Of Payment"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              v-on:change="changeTOP"
                            ></v-select>
                          </v-col>
                          <v-col
                            v-if="dateTempo == true"
                            class="pb-0"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-select
                              v-model="defaultItem.lama_tempo"
                              :items="itemsLamaTempo"
                              item-value="value"
                              item-text="text"
                              label="Lama Tempo"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="defaultItem.jumlah_discount"
                              label="Diskon Sales"
                              outlined
                              type="number"
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="defaultItem.diskon_management"
                              label="Diskon Management"
                              outlined
                              type="number"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="dateTempo == false"
                            class="pb-0"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-text-field
                              v-model="defaultItem.diskon_cash"
                              label="Diskon Tunai"
                              outlined
                              type="number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="save('create')"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Modal Add Product -->
          <v-dialog v-model="dialogAddProduct" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Product</h5>
                        <h3 class="ml-2">
                          {{ NamaProductTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Harga</h5>
                        <h3 class="ml-2">
                          {{ getRupiah(hargaProductTemp) }}
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="jumlahProductTemp"
                        label="Jumlah"
                        outlined
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="deskripsiProductTemp"
                        label="Keterangan Product"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeProduct">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="addProduct"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="save('delete')"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDetail" max-width="800px" class="pa-1">
            <v-card>
              <v-card-title class="mb-0 headermodalstyle">
                <span>Detail Sales Order</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container
                  v-if="loaddetail == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>
                <v-container v-if="loaddetail == false" class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            No Order
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.order_no }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Nama Salesman
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_sales }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Nama Customer
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_customer }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            rowspan="2"
                            class="text-left"
                            style="font-size: 14px"
                          >
                            Alamat
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.alamat_ro }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.alamat }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Status
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            <v-chip
                              outlined
                              :color="
                                getColorStatusApproval(defaultItem.status_task)
                              "
                              dark
                            >
                              <v-icon
                                medium
                                class="mr-1"
                                :color="
                                  getColorStatusApproval(
                                    defaultItem.status_task
                                  )
                                "
                              >
                                {{
                                  getIconStatusApproval(defaultItem.status_task)
                                }}
                              </v-icon>
                              {{ defaultItem.status_task }}
                            </v-chip>
                            /
                            <v-chip
                              outlined
                              :color="
                                getColorStatusKonfirmasiAdmin(
                                  defaultItem.status_so
                                )
                              "
                              dark
                            >
                              <v-icon
                                medium
                                class="mr-1"
                                :color="
                                  getColorStatusKonfirmasiAdmin(
                                    defaultItem.status_so
                                  )
                                "
                              >
                                {{ getIconStatusAdmin(defaultItem.status_so) }}
                              </v-icon>
                              {{ defaultItem.status_so }}
                            </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Term Of Payment
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.term_of_payment }} |
                            {{ defaultItem.lama_tempo }} Hari
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            style="width: 250px !important; font-size: 13px"
                          >
                            Nama Product
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Harga
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Jumlah
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Keterangan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="val in defaultItem.list_order"
                          :key="val.code_product"
                        >
                          <td
                            class="text-left"
                            style="
                              padding-left: 15px !important;
                              font-size: 13px;
                            "
                          >
                            {{ val.nama_product }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getRupiah(val.harga) }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.jumlah }} {{ val.satuan }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.deskripsi }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!-- <v-data-table
                    :headers="headersProduct"
                    :items="defaultItem.list_order"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.harga="{ item }">
                      {{ getRupiah(item.harga) }}
                    </template>
                    <template v-slot:item.jumlah="{ item }">
                      {{ item.jumlah }} {{ item.satuan }}
                    </template>
                  </v-data-table> -->

                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            SubTotal
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.jumlah_uang) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Disc
                            {{
                              showdiskon(
                                defaultItem.jumlah_discount,
                                defaultItem.diskon_management,
                                defaultItem.diskon_cash
                              )
                            }}
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.disc_rupiah) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.Total_Rupiah) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                </v-container>
              </v-card-text>

              <v-card-actions v-if="showButtonPrint == true">
                <v-spacer></v-spacer>
                <v-btn color="blue" elevation="1" outlined @click="print">
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Surat Jalan
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Preview -->
          <v-dialog v-model="dialogPreview" max-width="900px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Preview Invoie</span>
              </v-card-title>
              <div class="text-center fontprint" style="">
                <h1 id="cap1" class="h1 d-none">INVOICE DISTARA</h1>
                <div
                  id="cap2"
                  class="container mb-5 d-none"
                  style="width: 100%"
                >
                  <input
                    type="button"
                    class="btn btn-success float-right mx-2"
                    onclick="printDiv('capture')"
                    value="Cetak Invoice PDF"
                  />
                  <button
                    id="download"
                    class="btn btn-success float-right mx-2"
                  >
                    Cetak Invoice PNG
                  </button>
                  <br style="clear: both" />
                  <br style="clear: both" />
                  <br style="clear: both" />
                </div>
                <div
                  class="container-fluid pa-2 mt-5 mr-2"
                  style="overflow: auto"
                >
                  <div style="width: 100%" id="canvas" />
                </div>
              </div>
              <div style="overflow: auto" class="mr-2">
                <div
                  id="capture"
                  class="pa-2 ma-2"
                  style="transform: scale(0.85)"
                >
                  <div class="printtable mr-2" style="padding: 1px !important">
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 1px 0px 0px 0px !important;
                        padding: 0px 1px 0px 2px !important;
                      "
                    >
                      <tr>
                        <td
                          class="font-weight-bold text-center pt-1 pl-1 pr-4"
                          style="font-size: 25px"
                          colspan="7"
                        >
                          SURAT JALAN
                        </td>
                      </tr>
                    </table>
                    <table
                      style="width: 100% !important; margin-top: -45px"
                      class="fontprint mb-1"
                    >
                      <tr
                        class="fontprint"
                        style="padding-left: 30px; text-align: left"
                      >
                        <td style="padding-left: 10px; text-align: left">
                          <img
                            src="/images/logo_login_distara.jpeg"
                            class="mr-auto"
                            style="margin-left: 10px !important; width: 250px"
                          />
                        </td>
                        <td style="width: 100px">
                          <p
                            class="margininvoicetop1"
                            style="
                              margin-bottom: 6px;
                              font-size: 13px;
                              padding-left: 25px;
                            "
                          >
                            <b>Tanggal </b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="
                              margin-bottom: 6px;
                              font-size: 13px;
                              padding-left: 25px;
                            "
                          >
                            <b>No Order </b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="
                              margin-bottom: 6px;
                              font-size: 13px;
                              padding-left: 25px;
                            "
                          >
                            <b>Salesman </b>
                          </p>
                        </td>
                        <td style="width: 210px">
                          <p
                            class="margininvoicetop1"
                            style="margin-bottom: 6px; font-size: 13px"
                          >
                            <b>{{ gettanggal(defaultItem.created_at) }} </b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="margin-bottom: 6px; font-size: 13px"
                          >
                            <b>{{ defaultItem.order_no }}</b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="margin-bottom: 6px; font-size: 13px"
                          >
                            <b>{{ defaultItem.nama_sales }} </b>
                          </p>
                        </td>
                      </tr>
                    </table>
                    <table
                      style="width: 100% !important; margin-top: -25px"
                      class="fontprint mb-1"
                    >
                      <tr class="fontprint">
                        <td>
                          <!-- nama pembeli -->
                          <p
                            style="
                              margin: 20px 10px 1px 10px !important ;
                              font-size: 14px;
                            "
                          >
                            <b>Kepada {{ defaultItem.nama_customer }}</b>
                          </p>
                          <p
                            style="
                              margin: 1px 0px 1px 10px !important;
                              font-size: 14px;
                            "
                          >
                            <b>{{ defaultItem.alamat }}</b
                            >, <b>{{ defaultItem.alamat_ro }}</b>
                          </p>
                          <!-- Terimakasih -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            Terimakasih sudah berbelanja, Berikut rincian dari
                            orderan anda :
                          </p>
                        </td>
                        <!-- keterangan bayar -->
                        <td
                          colspan="2"
                          style="text-align: left; font-size: 14px"
                        >
                          <!-- <h3 style="color: red">BELUM DIBAYAR</h3> -->
                        </td>
                      </tr>
                    </table>
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 5px 10px 0px 10px !important;
                        padding: 0px 1px 0px 2px !important;
                        border: 1px solid black;
                        width: 97%;
                      "
                    >
                      <tr style="border: 2px solid black">
                        <th
                          class="font-weight-bold"
                          colspan="3"
                          style="color: black"
                        >
                          Nama Produk
                        </th>

                        <th
                          class="font-weight-bold text-center"
                          style="color: black"
                        >
                          Jumlah
                        </th>
                        <th
                          class="font-weight-bold text-center"
                          colspan="3"
                          style="color: black"
                        >
                          Keterangan
                        </th>
                      </tr>
                      <!-- <tr>
                        <td>Fikih Empat Mazhab Jilid II</td>
                        <td class="text-center">1</td>
                        <td class="text-center">1kg</td>
                        <td class="text-right">Rp 179.000</td>
                        <td class="text-center">0%</td>
                        <td class="text-right">Rp 179.000</td>
                      </tr> -->
                      <tr
                        style="border: 2px solid black"
                        v-for="vallist in defaultItem.list_order"
                        :key="vallist.code_product"
                      >
                        <td colspan="3">
                          <h4>
                            {{ vallist.nama_product }}
                          </h4>
                        </td>

                        <td class="text-center">
                          {{ vallist.jumlah }} {{ vallist.satuan }}
                        </td>
                        <td class="text-right" colspan="3">
                          {{ vallist.deskripsi }}
                        </td>
                      </tr>
                      <!-- <tr class="border-top">
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="font-weight-bold text-weight-bold">
                          SubTotal
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.jumlah_uang) }}
                        </td>
                      </tr>
                      <tr style="border-top: 1px solid black">
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="font-weight-bold text-weight-bold">
                          Diskon {{ defaultItem.jumlah_discount }} %
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.disc_rupiah) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold">TOTAL</td>
                        <td class="font-weight-bold text-right">
                          {{ getRupiah(defaultItem.Total_Rupiah) }}
                        </td>
                      </tr> -->
                    </table>
                    <!-- <hr class="border-top m-2" /> -->
                    <table class="table table-sm-responsive table-borderless">
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1 text-center"
                          style="width: 350px"
                        >
                          <p class="font-weight-bold margininvoicebot1"></p>
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Mengetahui
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Toko/Customer
                          </p>
                        </td>
                        <!-- </tr>
          <tr> -->
                        <td
                          class="font-weight-bold text-center"
                          style="width: 120px"
                        >
                          <p class="font-weight-bold margininvoicebot1"></p>
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Dikirim
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Supir
                          </p>
                        </td>
                        <td class="font-weight-bold">
                          <!-- <p class="font-weight-bold margininvoicebot1">
                            SubTotal
                          </p>
                          <p class="font-weight-bold margininvoicebot1">
                            Diskon {{ defaultItem.jumlah_discount }} %
                          </p>
                          <p class="font-weight-bold margininvoicebot1">
                            TOTAL
                          </p> -->
                        </td>
                        <td
                          class="font-weight-bold text-center"
                          style="width: 120px"
                        >
                          <p class="font-weight-bold margininvoicebot1"></p>
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Dibuat Oleh
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Adm Gudang
                          </p>
                        </td>
                        <td class="font-weight-bold text-right">
                          <!-- <p
                            class="font-weight-bold margininvoicebot1"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.jumlah_uang) }}
                          </p>
                          <p
                            class="font-weight-bold margininvoicebot1"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.disc_rupiah) }}
                          </p>
                          <p
                            class="font-weight-bold margininvoicebot1"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.Total_Rupiah) }}
                          </p> -->
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <!-- </v-container> -->
              <v-card-actions>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEditStatus" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Update Order Status is Delivered?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="updateStatusEditConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline </v-icon
        ><v-icon class="mr-1" @click="editstatusItem(item)" color="green">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "SalesOrder",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    loaddetail: false,
    valid: true,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogEditStatus: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Order No",
        value: "order_no",
        width: "15%",
      },
      { text: "Customer", value: "nama_customer" },
      // { text: "Tujuan", value: "alamat_ro" },
      { text: "Status", value: "status_so" },
      { text: "Approval", value: "status_task" },
      { text: "Tanggal", value: "created_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersOrderCust: [
      {
        text: "No Inv",
        value: "id",
      },
      { text: "Barang", value: "total_barang" },
      { text: "Total Belanja", value: "total_harga" },
      { text: "Tanggal", value: "created_at" },
      { text: "Update", value: "updated_at" },
    ],
    headerListProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Harga", value: "harga" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Harga", value: "harga" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Keterangan", value: "deskripsi" },
    ],
    dataobject: [],
    dataadmin: [],
    datacustomer: [],
    listordercust: [],
    itemsKabRO: [],
    itemsProduct: [],

    defaultItem: {
      id: "",
      order_no: "",
      alamat_ro: "",
      status_task: "",
      status_so: "",
      jumlah_barang: "",
      jumlah_uang: "",
      nama_sales: "",
      code_customer: "",
      nama_customer: "",
      code_salesman: "",
      alamat: "",
      code_alamat_ro: "",
      alamat_cust_ro: "",
      diskon_management: "",
      diskon_cash: "",
      disc_percent_show: "",
      jumlah_discount: "",
      term_of_payment: "",
      lama_tempo: "",
      due_date: "",
      created_at: "",
      itemsProductSelected: "",
      list_order: [],
      kabROCombobox: "",
    },

    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    itemsPermintaan: [
      { text: "-", value: "-" },
      { text: "Nanolite", value: "Nanolite" },
    ],
    itemsPembayaran: [
      { text: "-", value: "-" },
      { text: "Tunai", value: "Tunai" },
      { text: "Transfer", value: "Transfer" },
    ],
    itemsKepemilikan: [
      { text: "-", value: "-" },
      { text: "Milik Sendiri", value: "Milik_sendiri" },
      { text: "Induk", value: "Induk" },
      { text: "Sewa", value: "Sewa" },
      { text: "Cabang", value: "Cabang" },
      { text: "Proyek", value: "Proyek" },
    ],
    itemsTOP: [
      { text: "Tunai", value: "Tunai" },
      { text: "Tempo", value: "Tempo" },
    ],
    itemsLamaTempo: [
      { text: "15 Hari", value: "15" },
      { text: "30 Hari", value: "30" },
      { text: "45 Hari", value: "45" },
      { text: "60 Hari", value: "60" },
    ],

    e1: 1,
    stepperdone: false,

    showButtonPrint: false,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    kodeAdmin: "",

    dateTempo: false,
    codeProductTemp: "",
    NamaProductTemp: "",
    deskripsiProductTemp: "",
    hargaProductTemp: 0,
    jumlahProductTemp: 0,
    admin_role: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
    }
    this.initialize();
    this.GetAdmin();
    this.GetCustomer();
    this.getKabKotaROAll();
    this.GetProduct();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetSO", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetAdmin() {
      this.admin_role = 1;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAdminbyRole?role_code=" + this.admin_role,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataadmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataadmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataadmin = [];
        }
      }
    },
    async GetCustomer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomer?salesman_id=" +
            this.defaultItem.code_salesman,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.datacustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.datacustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.datacustomer = [];
        }
      }
    },
    async GetCustomerDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomerDetail?code_customer=" +
            this.defaultItem.code_customer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.customer_detail);
        if (response.data.length != 0) {
          this.defaultItem.kabROCombobox = {
            id_alamat:
              response.data.data.result.customer_detail.code_alamat_cust_ro,
            text: response.data.data.result.customer_detail.alamat_cust_ro,
          };
          this.defaultItem.alamat =
            response.data.data.result.customer_detail.alamat_customer;
          // this.datacustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          // this.datacustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.datacustomer = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetail(val) {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetSODetail?order_no=" + this.defaultItem.order_no,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.defaultItem.kabROCombobox = {
            id_alamat: response.data.data.result.code_alamat_ro,
            text: response.data.data.result.alamat_ro,
          };
          //   console.log(this.defaultItem);

          console.log(val);
          if (val == "edit") {
            this.GetCustomer();
            console.log(val);

            if (response.data.data.result.term_of_payment == "Tempo") {
              this.dateTempo = true;
            } else {
              this.dateTempo = false;
            }
          }
          if (val == "detail") {
            // this.GetCustomer();
            console.log(val);
            if (
              response.data.data.result.status_task == "waiting_for_approval"
            ) {
              this.showButtonPrint = false;
            } else if (response.data.data.result.status_task == "Approved") {
              this.showButtonPrint = true;
            } else {
              this.showButtonPrint = false;
            }
          }
          this.loaddetail = false;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getKabKotaROAll() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetKotaKabRO", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsKabRO = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsKabRO = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsKabRO = [];
        }
      }
    },
    async verifDelete(datapost) {
      console.log("deleteapi");
      // console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteSO",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData(datapost) {
      console.log("addapi");
      // console.log(datapost);
      try {
        const response = await axios.post(this.BaseUrlGet + "AddSO", datapost, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData(datapost) {
      console.log("updateapi");
      // console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditSO",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateDataStatus() {
      const datapost = {
        order_no: this.defaultItem.order_no,
        code_admin_update: this.kodeAdmin,
        status_so: this.defaultItem.status_so,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditSOStatus",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogEditStatus = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.e1 = 1;
      // this.defaultItem = [];

      this.defaultItem.order_no = "";
      this.defaultItem.code_customer = "";
      this.defaultItem.code_salesman = "";
      this.defaultItem.alamat = "";
      this.defaultItem.jumlah_discount = 0;
      this.defaultItem.diskon_management = 0;
      this.defaultItem.diskon_cash = 0;
      this.defaultItem.term_of_payment = "";
      this.defaultItem.list_order = [];
      this.defaultItem.kabROCombobox = "";
      this.defaultItem.code_alamat_ro = "";
      this.defaultItem.alamat_cust_ro = "";
      // if (this.$refs.form) {
      //   this.$refs.form.reset();
      // }

      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.getDetail("edit");
      this.value = "edit";
      this.e1 = 1;
      if (item.status_so == "delivered") {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.timeoutsnackbar = 3000;
        this.textsnackbar = "Tidak bisa update SO. Status sudah terkirim..";
      } else {
        this.dialog = true;
      }
    },
    editstatusItem(item) {
      console.log(item);
      this.defaultItem = Object.assign({}, item);
      this.getDetail("edit");
      this.value = "edit";
      if (item.status_task == "Approved") {
        this.dialogEditStatus = true;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.timeoutsnackbar = 3000;
        this.textsnackbar =
          "Tidak bisa update status, order masih menunggu persetujuan..!!";
      }
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.getDetail("detail");
      this.dialogDetail = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogEditStatus = false;
    },
    save(val) {
      if (val == "delete") {
        console.log("delete");
        const datapost = {
          order_no: this.defaultItem.order_no,
          code_admin_update: this.kodeAdmin,
        };
        if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
          this.verifDelete(datapost);
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Delete, Error data yang dipilih";
        }
      } else {
        const datapost = {
          order_no: "default",
          code_customer: this.defaultItem.code_customer,
          code_salesman: this.defaultItem.code_salesman,
          alamat: this.defaultItem.alamat,
          code_alamat_ro: this.defaultItem.kabROCombobox.id_alamat,
          diskon_management: JSON.stringify(this.defaultItem.diskon_management),
          diskon_cash: JSON.stringify(this.defaultItem.diskon_cash),
          jumlah_discount: JSON.stringify(this.defaultItem.jumlah_discount),
          term_of_payment: this.defaultItem.term_of_payment,
          lama_tempo: "default",
          list_order: this.defaultItem.list_order,
          code_admin_create: this.kodeAdmin,
        };

        // console.log(datapost);
        if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
          datapost.diskon_management = this.defaultItem.diskon_management;
          datapost.diskon_cash = this.defaultItem.diskon_cash;
          datapost.jumlah_discount = this.defaultItem.jumlah_discount;
          datapost.lama_tempo = this.defaultItem.lama_tempo;
          datapost.list_order = JSON.stringify(this.defaultItem.list_order);
          // console.log(datapost);

          if (this.defaultItem.order_no) {
            datapost.order_no = this.defaultItem.order_no;
            this.updateData(datapost);
          } else {
            datapost.order_no = "";
            this.addData(datapost);
          }
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar =
            "Gagal Menambah, Data tidak boleh ada yang kosong";
        }
      }
    },
    updateStatusEditConfirm() {
      this.defaultItem.status_so = "delivered";
      this.updateDataStatus();
    },

    changeTOP(val) {
      console.log(val);
      if (val == "Tempo") {
        this.dateTempo = true;
      } else {
        this.dateTempo = false;
        this.defaultItem.lama_tempo = 0;
      }
      // this.discSelectedCustomer = val.diskon;
    },

    changeSalesman(val) {
      console.log(this.defaultItem.code_salesman);
      this.GetCustomer();
    },

    changeCustomer(val) {
      console.log(val);
      this.defaultItem.code_customer = val;
      this.GetCustomerDetail();
    },

    changeProductSelected(val) {
      console.log(val);
      this.codeProductTemp = val.code_product;
      this.NamaProductTemp = val.nama_product;
      this.hargaProductTemp = val.harga_jual;
      //   this.jumlahProductTemp = val.total;
      this.dialogAddProduct = true;
    },

    closeProduct() {
      this.dialogAddProduct = false;
    },
    addProduct() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahProductTemp > 0) {
        var listmenu = this.defaultItem.list_order;
        console.log(listmenu);
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].code_product == this.codeProductTemp) {
            result = true;
            console.log(result);
            break;
          }
        }

        console.log(result);
        if (result == false) {
          var productset = {
            code_product: "",
            diskon: "",
            harga: "",
            jumlah: "",
            nama_product: "",
            deskripsi: "",
          };

          productset.code_product = this.codeProductTemp;
          productset.harga = this.hargaProductTemp;
          productset.jumlah = this.jumlahProductTemp;
          productset.nama_product = this.NamaProductTemp;
          if (this.deskripsiProductTemp) {
            productset.deskripsi = this.deskripsiProductTemp;
          } else {
            productset.deskripsi = "-";
          }

          this.defaultItem.list_order.push(productset);

          console.log(productset);
          console.log(this.defaultItem.list_order);

          this.dialogAddProduct = false;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Product Sudah Ada..!!!";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitemproduct(item) {
      this.editedIndex = await this.defaultItem.list_order.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.list_order.splice(this.editedIndex, 1);
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
    },

    printImage() {
      var no_ord = this.defaultItem.order_no;
      var nama_cust = this.defaultItem.nama_customer;
      const arrayname = nama_cust.split(" ");
      var nama_cust_split = arrayname[0];
      // console.log(no_ord);
      // console.log(nama_cust_split);
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = nama_cust_split + "_" + no_ord + ".png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },

    getColorStatusKonfirmasiAdmin(status) {
      if (status == "new_order") return "orange";
      else if (status == "processing") return "purple";
      else if (status == "delivering") return "blue";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "new_order") return "mdi-text-box-check-outline";
      else if (status == "processing") return "mdi-package-variant";
      else if (status == "delivering") return "mdi-truck-delivery-outline";
      //   else if (status == "7") return "mdi-package-variant";
      //   else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-check-underline-circle";
    },
    getColorStatusApproval(status) {
      if (status == "waiting_for_approval") return "orange";
      else if (status == "Rejected") return "red";
      else return "green";
    },
    getIconStatusApproval(status) {
      if (status == "waiting_for_approval") return "mdi-update";
      else if (status == "Rejected") return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    },

    showdiskon(val1, val2, val3) {
      var rslt = "-";
      if (Number(val1) != 0) {
        rslt = val1 + "% ";
      }
      if (Number(val2) != 0) {
        if (rslt == "-") {
          rslt = val2 + "% ";
        } else {
          rslt = rslt + "+" + val2 + "% ";
        }
      }
      if (Number(val3) != 0) {
        if (rslt == "-") {
          rslt = val3 + "% ";
        } else {
          rslt = rslt + "+" + val3 + "% ";
        }
      }
      return rslt;
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>

