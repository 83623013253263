<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.updated_at="{ item }">
        {{ gettanggal(item.updated_at) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- <v-btn dark class="mb-2" @click="showAddModalSO()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn> -->

          <v-dialog v-model="dialogEdit" max-width="400px">
            <v-card class="fontall">
              <v-card-title class="mb-0 headermodalstyle"
                >Update Stock Awal?</v-card-title
              >
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Product</h5>
                        <h3 class="ml-2">
                          {{ defaultItem.nama_product }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Stock Awal</h5>
                        <h3 class="ml-2">
                          {{ defaultItem.stock_awal }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Stock Ready</h5>
                        <h3 class="ml-2">
                          {{ defaultItem.stock_ready }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="px-3 pt-4">
                      <v-text-field
                        v-model="defaultItem.stock_awal"
                        label="Masukkan Stock Awal"
                        outlined
                        type="number"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Save</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "InventoryAdjusment",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogEdit: false,
    showProduct: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Code Product",
        value: "code_product",
      },
      { text: "Nama Product", value: "nama_product", width: "20%" },
      { text: "Lokasi", value: "gudang" },
      { text: "Stock Awal", value: "stock_awal" },
      { text: "Stock Masuk", value: "stock_masuk" },
      { text: "Stock Keluar", value: "stock_keluar" },
      { text: "Ready Stock", value: "stock_ready" },
      { text: "Updated", value: "updated_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "7%" },
    ],
    dataobject: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_product: "",
      nama_product: "",
      stock_awal: "",
      stock_masuk: "",
      stock_keluar: "",
      stock_ready: "",
      updated_at: "",
    },
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    kodeAdmin: "",

    admin_role: "",
    admin_role_code: "",
    status_admin: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      if (this.admin_role_code == "4" || this.admin_role_code == "99") {
        this.status_admin == "manager";
      } else {
        this.status_admin == "staff";
      }
    }
    this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetStockProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async updateStockAwal(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditStockAwalProduct",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogEdit = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogEdit = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    editItem(item) {
      this.status_show = true;
      this.status_show_process = false;
      this.status_show_done = false;
      this.defaultItem = Object.assign({}, item);
      this.valstatustemp = item.status;
      this.dialogEdit = true;
    },
    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },
    close() {
      this.dialog = false;
      this.dialogEdit = false;
    },
    save() {
      const datapost = {
        code_product: this.defaultItem.code_product,
        stock_awal: this.defaultItem.stock_awal,
        // keterangan: this.defaultItem.keterangan,
        code_admin: this.kodeAdmin,
      };
      // console.log(datapost);
      var check = HelperGlobalService.checkMandatory(datapost, "object");
      if (check == true) {
        this.updateStockAwal(datapost);
      } else {
        console.log(check);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



