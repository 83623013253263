<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.tanggal_stock_opname="{ item }">
        {{ gettanggal(item.tanggal_stock_opname) }}
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip outlined :color="getColorStatusApproval(item.status)" dark>
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusApproval(item.status)"
          >
            {{ getIconStatusApproval(item.status) }}
          </v-icon>
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            v-if="show_manager == true"
            dark
            class="mb-2"
            @click="showAddModalSO()"
            color="green"
          >
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-dialog v-model="dialogAdd" max-width="500px">
            <v-card>
              <v-card-title class="headermodalstyle" style="margin-bottom: 2px">
                <span>Add Perintah Stock Opname</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container class="mb-0">
                  <v-row class="mb-0">
                    <v-col cols="12" sm="12" md="12" class="pb-0">
                      <v-text-field
                        v-model="defaultItem.keterangan"
                        label="Keterangan Stock Opname"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-menu v-model="menu2" transition="scale-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="datepicker"
                            slot="activator"
                            label="Tanggal Penugasan"
                            outlined
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="[(v) => !!v || 'Field is required']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="datepicker"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.tipe_stock_opname"
                        :items="itemsTipe"
                        item-value="value"
                        item-text="text"
                        label="Pilih Tipe"
                        outlined
                        v-on:change="changeTipe"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-if="showProduct == true">
                  <v-row class="mb-3">
                    <h4 class="ml-4">Tambah Produk</h4>
                    <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="itemsProduct"
                        item-value="code_product"
                        item-text="nama_product"
                        label="Pilih Product"
                        outlined
                        v-on:change="selectedProduct($event)"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mb-3">
                    <h4 class="ml-4">List Produk Stock Opname</h4>
                    <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        :headers="headersProduct"
                        :items="defaultItem.list_product"
                        class="elevation-1"
                        append-icon="mdi-magnify"
                        :items-per-page="5"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            class="mr-2"
                            @click="deletelistproduct(item)"
                            color="red"
                          >
                            mdi-delete
                          </v-icon>
                          <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDetail" max-width="1000px" class="pa-1">
            <v-card>
              <v-card-title class="mb-0 headermodalstyle">
                <span>Detail Stock Opname</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container
                  v-if="load == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>

                <v-container v-if="load == false" class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody class="ml-5">
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Code
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.code_stock_opname }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Keterangan
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.keterangan }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Tanggal Penugasan
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ gettanggal(defaultItem.tanggal_stock_opname) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Tipe Stock Opname
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.tipe_stock_opname }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-data-table
                    :headers="headersProductDetail"
                    :items="defaultItem.list_product"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEditStatus" max-width="600px">
            <v-card class="fontall">
              <v-card-title class="mb-0 headermodalstyle"
                >Update Status Invoice?</v-card-title
              >
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.status"
                        :items="itemsStatusSO"
                        item-value="value"
                        item-text="text"
                        label="Pilih Status"
                        outlined
                        v-on:change="changestatus"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="status_show == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <h4 class="ml-2" style="color: red">
                        Note: Update status
                        <span style="text-decoration: underline"
                          >"Pending"</span
                        >
                        ->
                        <span style="text-decoration: underline"
                          >"Process"</span
                        >
                        ->
                        <span style="text-decoration: underline">"Done"</span>
                        ->
                        <span style="text-decoration: underline"
                          >"Approved"</span
                        >
                      </h4>
                    </v-col>
                    <v-col
                      v-if="status_show_process == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <!-- <v-select
                        v-model="cetak_status"
                        :items="itemsCetak"
                        item-value="value"
                        item-text="text"
                        label="Cetak Stock Opname"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select> -->
                    </v-col>

                    <v-col
                      v-if="status_show_done == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-data-table
                        :headers="headersProductSOsave"
                        :items="itemsProductSO"
                        :search="search"
                        class="rounded elevation-1 mx-1 pa-1 my-2"
                        hide-default-footer
                        disable-pagination
                      >
                        <template v-slot:item.jumlah_akhir="props">
                          <v-text-field
                            v-model="props.item.jumlah_akhir"
                          ></v-text-field>
                        </template>
                      </v-data-table>
                    </v-col>

                    <!-- <v-simple-table>
                    <template v-slot:default>
                      <tbody class="ml-5">
                        <tr>
                          <th
                            class="text-left"
                            style="width: 150px !important; font-size: 12px"
                          >
                            Code
                          </th>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 12px"
                          >
                            Nama
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 12px; font-weight: bold"
                          >
                            {{ defaultItem.code_stock_opname }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table> -->
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="saveeditstatus"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Save</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogSingkron" max-width="500px">
            <v-card class="fontall">
              <hr class="ml-2 mr-2 mt-5">
              <v-card-text>
                <div v-if="showLoading == true " style="text-align: center;margin-top: 10px;" ><h2>Proses singkronisasi, harap ditunggu...</h2></div> 
                <div v-if="showLoading == false " style="text-align: center;margin-top: 10px;" ><h2>Proses singkronisasi selesai...</h2></div>                
                <div v-if="showLoading == true " style="text-align: center;margin-top: 30px;">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                </div>
                 </v-card-text>
              <hr class="ml-2 mr-2" >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="closeSingkron"
                   v-if="showLoading == false" 
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Selesai</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon
          v-if="item.status == 'process'"
          class="mr-1"
          @click="syncItem(item)"
          color="green"
        >
          mdi-sync-circle
        </v-icon>
        <v-icon
          v-if="item.status == 'process' || item.status == 'approved'"
          class="mr-1"
          @click="cetakItem(item)"
          color="blue"
        >
          mdi-printer-check
        </v-icon>
        <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "StockOpname",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    dialogSingkron: false,
    showProduct: false,
    show_manager: false,
    showLoading: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Code SO",
        value: "code_stock_opname",
      },
      { text: "Keterangan", value: "keterangan", width: "20%" },
      { text: "Tipe SO", value: "tipe_stock_opname" },
      { text: "Status", value: "status" },
      { text: "Tanggal SO", value: "tanggal_stock_opname", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "17%" },
    ],
    headersOrderCust: [
      {
        text: "No Inv",
        value: "id",
      },
      { text: "Barang", value: "total_barang" },
      { text: "Total Belanja", value: "total_harga" },
      { text: "Tanggal", value: "created_at" },
      { text: "Update", value: "updated_at" },
    ],
    headerListProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Harga", value: "harga" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersProduct: [
      { text: "Code Product", value: "code_product" },
      { text: "Nama Product", value: "nama_product" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headersProductDetail: [
      { text: "Code Product", value: "code_product", width: "10%" },
      { text: "Nama Product", value: "nama_product" , width: "20%"},
      { text: "Sistem Solo", value: "stock_sistem_solo" },
      { text: "Scan Solo", value: "stock_scan_solo" },
      { text: "Selisih", value: "selisih_solo" },
      { text: "Sistem Jakarta", value: "stock_sistem_jakarta" },
      { text: "Scan Jakarta", value: "stock_scan_jakarta" },
      { text: "Selisih", value: "selisih_jakarta" },
    ],
    headersProductSOsave: [
      { text: "Code Product", value: "code_product" },
      { text: "Nama Product", value: "nama_product" },
      { text: "Jumlah", value: "jumlah_akhir" },
    ],
    dataobject: [],
    dataadmin: [],
    datacustomer: [],
    dataasal: [],
    listordercust: [],
    itemsKabRO: [],
    itemsProduct: [],
    itemsProductSO: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_stock_opname: "",
      keterangan: "",
      tipe_stock_opname: "",
      created_by: "",
      status: "",
      tanggal_stock_opname: "",
      keterangan: "",
      list_product: [],
    },
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],
    itemsStatusSO: [
      {
        text: "Process (Melakukan Stock Opname Perhitungan)",
        value: "process",
      },
      {
        text: "Done (Selesai Hitung Manual dan isikan ke sistem)",
        value: "done",
      },
      {
        text: "Approved (Stock Opname akan mengupdate stock sistem)",
        value: "approved",
      },
    ],
    itemsCetak: [
      { text: "Cetak Stock Opname", value: "cetak" },
      { text: "Tidak Cetak", value: "tidak_cetak" },
    ],

    e1: 1,
    stepperdone: false,

    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    kodeAdmin: "",

    cetak_status: "",
    valstatustemp: "",
    status_show: false,
    status_show_process: false,
    status_show_done: false,
    status_show_cetak: false,
    bayar_temp: 0,

    dateTempo: false,
    codeProductTemp: "",
    NamaProductTemp: "",
    deskripsiProductTemp: "",
    hargaProductTemp: 0,
    jumlahProductTemp: 0,
    admin_role: "",
    admin_role_code: "",
    status_admin: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      console.log(this.User);
      if (this.User.role_code == 4 || this.User.role_code == 99) {
        // console.log("manager " + this.User.role_code);
        this.status_admin = "manager";
        this.show_manager = true;
      } else {
        // console.log("staff " + this.User.role_code);
        this.status_admin = "staff";
        this.show_manager = false;
      }
    }
    this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
    this.GetProduct();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetStockOpname", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetAdmin() {
      this.admin_role = 1;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAdminbyRole?role_code=" + this.admin_role,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataadmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataadmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataadmin = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetail() {
      this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetStockOpnameDetail?code_stock_opname=" +
            this.defaultItem.code_stock_opname,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        this.load = false;
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result.data;
          this.defaultItem.list_product = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getDetailProduct() {
      //   this.load = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetStockOpnameDetail?code_stock_opname=" +
            this.defaultItem.code_stock_opname,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        // this.load = false;
        if (response.data.length != 0) {
          //   this.defaultItem = response.data.data.result.data;
          this.itemsProductSO = response.data.data.result.detail;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        code_stock_opname: this.defaultItem.code_stock_opname,
        code_admin: this.kodeAdmin,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteStockOpname",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async printItem(val) {
      const datapost = {
        code_stock_opname: val,
        code_admin: this.kodeAdmin,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "CetakStockOpnameDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addDataStockOpname(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddStockOpname",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogAdd = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data, Data SO sudah terbuat Invoice";
        }
      }
    },
    async updateStatusInvoice() {
      const datapost = {
        code_stock_opname: this.defaultItem.code_stock_opname,
        status: this.defaultItem.status,
        code_admin: this.kodeAdmin,
        list_product: JSON.stringify(this.itemsProductSO),
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditStockOpnameStatus",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogEditStatus = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogEditStatus = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async syncItem(item){
      console.log(item.code_stock_opname);
      this.dialogSingkron = true;
      this.showLoading = true;
      const datapost = {
        code_stock_opname: item.code_stock_opname,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "SyncStockOpname",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          // this.dialogSingkron = false;
          this.showLoading = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Syncronise data";
          // this.initialize();
        } else {
          //   this.dialog = false;
          // this.dialogSingkron = false;
          this.showLoading = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Syncronise data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogEditStatus = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          // this.dialogSingkron = false;
          this.showLoading = false;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Syncronise data";
        }
      }

    },
    closeSingkron(){
      this.dialogSingkron = false;
    },

    showAddModalSO() {
      this.value = "add";
      this.formTitle = "Add Item";

      this.defaultItem.id = "";
      this.defaultItem.code_stock_opname = "";
      this.defaultItem.keterangan = "";
      this.defaultItem.tipe_stock_opname = "";
      this.defaultItem.created_by = "";
      this.defaultItem.list_product = [];

      this.showProduct = false;

      this.dialogAdd = true;
    },

    editstatus() {
      this.dialogEditShow = false;
      this.dialogEditStatus = true;
    },
    saveeditstatus() {
      //   console.log(this.itemsProductSO);
      this.updateStatusInvoice();
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.getDetail("detail");
      this.dialogDetail = true;
      // if (item.status == "approved" || item.status == "done") {
      //   var headersProductNew = [
      //     { text: "Code Product", value: "code_product" },
      //     { text: "Nama Product", value: "nama_product" },
      //     { text: "SA", value: "stock_awal" },
      //     { text: "SM", value: "stock_masuk" },
      //     { text: "SK", value: "stock_keluar" },
      //     { text: "JAW", value: "jumlah_awal" },
      //     { text: "JAK", value: "jumlah_akhir" },
      //   ];
      //   this.headersProductDetail = headersProductNew;
      // } else {
      //   var headersProductNew = [
      //     { text: "Code Product", value: "code_product" },
      //     { text: "Nama Product", value: "nama_product" },
      //   ];
      //   this.headersProductDetail = headersProductNew;
      // }
    },
    editItem(item) {
      this.status_show = true;
      this.status_show_process = false;
      this.status_show_done = false;
      this.defaultItem = Object.assign({}, item);
      this.valstatustemp = item.status;
      this.getDetail("detail");
      if (item.status == "approved") {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Status Approved tidak bisa di update status";
      } else {
        this.dialogEditStatus = true;
      }

      //   console.log(this.status_admin);

      if (this.User.role_code == 4 || this.User.role_code == 99) {
        var itemsStatusNew = [
          {
            text: "Process (Melakukan Stock Opname Perhitungan)",
            value: "process",
          },
          {
            text: "Done (Selesai Hitung Manual dan isikan ke sistem)",
            value: "done",
          },
          {
            text: "Approved (Stock Opname akan mengupdate stock sistem)",
            value: "approved",
          },
        ];
        this.itemsStatusSO = itemsStatusNew;
      } else {
        var itemsStatusNew = [
          {
            text: "Process (Melakukan Stock Opname Perhitungan)",
            value: "process",
          },
          {
            text: "Done (Selesai Hitung Manual dan isikan ke sistem)",
            value: "done",
          },
        ];
        this.itemsStatusSO = itemsStatusNew;
      }
    },
    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
      this.dialogAdd = false;
      this.dialogEditShow = false;
      this.dialogEditStatus = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      const datapost = {
        keterangan: this.defaultItem.keterangan,
        tipe_stock_opname: this.defaultItem.tipe_stock_opname,
        tanggal_stock_opname: this.datepicker,
        list_product: "default",
        // keterangan: this.defaultItem.keterangan,
        code_admin: this.kodeAdmin,
      };
      // console.log(datapost);
      var check = HelperGlobalService.checkMandatory(datapost, "object");
      if (check == true) {
        if (this.defaultItem.tipe_stock_opname == "some_products") {
          datapost.list_product = this.defaultItem.list_product;
          var check = HelperGlobalService.checkMandatory(datapost, "object");
          if (check == true) {
            datapost.list_product = JSON.stringify(
              this.defaultItem.list_product
            );
            // console.log(datapost);
            this.addDataStockOpname(datapost);
          } else {
            console.log(check);
            this.snackbar = true;
            this.colorsnackbar = "red";
            this.textsnackbar =
              "Gagal Menambah, Data tidak boleh ada yang kosong";
          }
        } else {
          datapost.list_product = JSON.stringify(this.defaultItem.list_product);
          //   console.log(datapost);
          this.addDataStockOpname(datapost);
        }
      } else {
        console.log(check);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    changeTipe(val) {
      if (val == "some_products") {
        this.showProduct = true;
      } else {
        this.showProduct = false;
      }
      //   this.defaultItem.order = [];
    },
    changestatus(val) {
      console.log(this.valstatustemp);
      if (this.checkstatus(this.valstatustemp, val) == true) {
        if (val == "process") {
          this.status_show = false;
          this.status_show_process = true;
          this.status_show_done = false;
        } else if (val == "done") {
          this.status_show = false;
          this.status_show_process = false;
          this.status_show_done = true;
          this.getDetailProduct();
        } else if (val == "approved") {
          this.status_show = false;
          this.status_show_process = false;
          this.status_show_done = false;
        } else {
          this.status_show = false;
          this.status_show_process = false;
          this.status_show_done = false;
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Update status harus sesuai urutan";
        this.dialogEditStatus = false;
      }
    },
    checkstatus(val1, val2) {
      if (val1 == "pending" && val2 == "process") return true;
      else if (val1 == "process" && val2 == "done") return true;
      else if (val1 == "done" && val2 == "approved") return true;
      else false;
    },

    selectedProduct(a) {
      console.log(a);
      if (a != null) {
        var listmenu = this.defaultItem.list_product;
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].code_product == a) {
            result = true;
            break;
          }
        }
        if (result == false) {
          var itemsproductset = {
            code_product: "",
            nama_product: "",
          };
          for (let i = 0; i < this.itemsProduct.length; i++) {
            if (this.itemsProduct[i].code_product == a) {
              // console.log(this.itemsMenu[i]);
              itemsproductset.code_product = this.itemsProduct[i].code_product;
              itemsproductset.nama_product = this.itemsProduct[i].nama_product;
              // console.log(this.editedItem);
              break;
            }
          }
          this.defaultItem.list_product.push(itemsproductset);
          console.log(this.defaultItem.list_product);
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Tambah Product";
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Product Sudah Ada..!!!";
        }
        // this.menudropdown.id = '';
      }
    },
    async deletelistproduct(item) {
      console.log(item);
      this.editedIndex = await this.defaultItem.list_product.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.list_product.splice(this.editedIndex, 1);
    },

    changeProductSelected(val) {
      console.log(val);
      this.codeProductTemp = val.code_product;
      this.NamaProductTemp = val.nama_product;
      this.hargaProductTemp = val.harga_jual;
      //   this.jumlahProductTemp = val.total;
      this.dialogAddProduct = true;
    },

    closeProduct() {
      this.dialogAddProduct = false;
    },
    addProduct() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahProductTemp > 0) {
        var listmenu = this.defaultItem.list_order;
        console.log(listmenu);
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].code_product == this.codeProductTemp) {
            result = true;
            break;
          }
        }

        if (result == false) {
          var productset = {
            code_product: "",
            diskon: "",
            harga: "",
            jumlah: "",
            nama_product: "",
            deskripsi: "",
          };

          productset.code_product = this.codeProductTemp;
          productset.harga = this.hargaProductTemp;
          productset.jumlah = this.jumlahProductTemp;
          productset.nama_product = this.NamaProductTemp;
          if (this.deskripsiProductTemp) {
            productset.deskripsi = this.deskripsiProductTemp;
          } else {
            productset.deskripsi = "-";
          }

          this.defaultItem.list_order.push(productset);

          this.dialogAddProduct = false;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Product Sudah Ada..!!!";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitemproduct(item) {
      this.editedIndex = await this.defaultItem.list_order.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.list_order.splice(this.editedIndex, 1);
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
    },

    printImage() {
      var no_ord = this.defaultItem.order_no;
      var nama_cust = this.defaultItem.nama_customer;
      const arrayname = nama_cust.split(" ");
      var nama_cust_split = arrayname[0];
      // console.log(no_ord);
      // console.log(nama_cust_split);
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = nama_cust_split + "_" + no_ord + ".png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    gettermofpayment(top, lama) {
      if (top) {
        if (top.toLowerCase() == "tunai") return "Tunai";
        else return top + " | " + lama + " Hari";
      }
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },

    getColorStatusKonfirmasiAdmin(status) {
      if (status == "unpaid") return "red";
      else if (status == "partial") return "orange";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "unpaid") return "mdi-alert-octagon";
      //   else if (status == "7") return "mdi-package-variant";
      //   else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-check-underline-circle";
    },
    getColorStatusApproval(status) {
      if (status == "pending") return "red";
      else if (status == "process") return "orange";
      else if (status == "done") return "blue";
      else return "green";
    },
    getIconStatusApproval(status) {
      if (status == "pending") return "mdi-calendar-clock-outline";
      else if (status == "process") return "mdi-pencil-box-multiple-outline";
      else if (status == "done") return "mdi-calendar-check-outline";
      else return "mdi-check-underline-circle";
    },

    showdiskon(val1, val2, val3) {
      var rslt = "-";
      if (Number(val1) != 0) {
        rslt = val1 + "% ";
      }
      if (Number(val2) != 0) {
        if (rslt == "-") {
          rslt = val2 + "% ";
        } else {
          rslt = rslt + "+" + val2 + "% ";
        }
      }
      if (Number(val3) != 0) {
        if (rslt == "-") {
          rslt = val3 + "% ";
        } else {
          rslt = rslt + "+" + val3 + "% ";
        }
      }
      return rslt;
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



