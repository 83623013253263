<template>
  <div>

    <v-card elevation="5" class="pt-4 pb-0 px-2 mx-3">
      <v-card-text>
        <v-row>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
              <v-select
                v-model="bulanDropDown"
                :items="itemsBulan"
                item-text="text"
                item-value="value"
                label="Pilih Bulan"
                outlined
                clearable
                @change="changeBulan"
              ></v-select>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="3" md="3">
              <v-select
                v-model="tahunDropDown"
                :items="itemsTahun"
                item-text="text"
                item-value="value"
                label="Pilih Tahun"
                outlined
                clearable
              ></v-select>
          </v-col>
          <v-col class="px-1 py-0" cols="12" sm="2" md="2">
            <!-- <v-select
              v-model="admindropdown"
              :items="itemsAdmin"
              item-text="nama"
              item-value="kodeAdminTrx"
              label="Pilih Admin"
              outlined
              clearable
              :disabled="validateddisable == true"
              :rules="[(v) => !!v || 'Field is required']"
            ></v-select> -->
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block color="primary" elevation="1" @click="searchPerfroma">
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
          </v-col>
          <v-col class="px-1 py-0 mb-1" cols="12" sm="2" md="2">
            <v-btn block dark class="mb-2" @click="download()" color="#b3b928">
              <v-icon left> mdi-download-circle-outline </v-icon> Download
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-card elevation="5" class="pt-1 pb-0 px-2 mx-3 mt-5">
      <v-card-text>
        <v-row>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="12"
            md="12"
            style="text-align: center"
          >
            <h2 style="font-styler: bold" class="mt-3 mb-2">
              Laba Rugi 
            </h2>
            <h3>
                {{bulanDropDownText}} {{tahunDropDown}}
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
      <hr>
      
      <div v-if="showLoading == true" style="text-align: center;margin-top: 30px;">
        <v-progress-circular
          :size="70"
          :width="7"
          color="blue"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-simple-table  v-if="showLoading == false" >
            <template v-slot:default>              
            <thead>
              <tr>
                <th class="text-left" style="width: 40%;">
                  Akun
                </th>
                <th v-for="item in dataobjectbulan" :key="item" style="width: 20%;" class="text-center">
                  {{item}}
                </th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="itemval in dataobject" :key="itemval.code_jurnal">
                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis == "total_laba_rugi"' style="padding-left:0%;color: #e40000">{{ itemval.nama_akun }}</td>
                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis != "total_laba_rugi"' style="padding-left:0%;color: #0337bc">{{ itemval.nama_akun }}</td>
                  <td v-if='itemval.type == "subklasifikasi"' style="padding-left:5%;color: #0337bc">{{ itemval.nama_akun }}</td>
                  <td v-if='itemval.type == "akun"' style="padding-left:10%">{{ itemval.nama_akun }}</td>

                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis == "total_laba_rugi"' class="text-right" style="padding-left:10%;color: #e40000">
                    {{ getRupiahNominal(itemval.nominal1) }}
                  </td>
                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis == "total_laba_rugi"' class="text-right" style="padding-left:10%;color: #e40000">
                    {{ getRupiahNominal(itemval.nominal2) }}
                  </td>
                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis == "total_laba_rugi"' class="text-right" style="padding-left:10%;color: #e40000">
                    {{ getRupiahNominal(itemval.nominal3) }}
                  </td>

                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis != "total_laba_rugi"' class="text-right" style="padding-left:10%;color: #0337bc">
                    {{ getRupiahNominal(itemval.nominal1) }}
                  </td>
                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis != "total_laba_rugi"' class="text-right" style="padding-left:10%;color: #0337bc">
                    {{ getRupiahNominal(itemval.nominal2) }}
                  </td>
                  <td v-if='itemval.type == "klasifikasi" &&  itemval.jenis != "total_laba_rugi"' class="text-right" style="padding-left:10%;color: #0337bc">
                    {{ getRupiahNominal(itemval.nominal3) }}
                  </td>

                  <td v-if='itemval.type == "subklasifikasi"' class="text-right" style="padding-left:10%;color: #0337bc">
                    {{ getRupiahNominal(itemval.nominal1) }}
                  </td>
                  <td v-if='itemval.type == "subklasifikasi"' class="text-right" style="padding-left:10%;color: #0337bc">
                    {{ getRupiahNominal(itemval.nominal2) }}
                  </td>
                  <td v-if='itemval.type == "subklasifikasi"' class="text-right" style="padding-left:10%;color: #0337bc">
                    {{ getRupiahNominal(itemval.nominal3) }}
                  </td>

                  <td v-if='itemval.type == "akun"' class="text-right" style="padding-left:10%">{{ getRupiahNominal(itemval.nominal1) }}</td>
                  <td v-if='itemval.type == "akun"' class="text-right" style="padding-left:10%">{{ getRupiahNominal(itemval.nominal2) }}</td>
                  <td v-if='itemval.type == "akun"' class="text-right" style="padding-left:10%">{{ getRupiahNominal(itemval.nominal3) }}</td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "LabaRugi",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showProduct: false,
    dialogDetailInvoice: false,
    dialogDetailProduct: false,
    dialogDetailCustomer: false,
    dialogDetailDownload: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "Nama Sales", value: "salesman", width: "25%" },
      { text: "Total Barang", value: "GetTotalBarang" },
      { text: "Total Order", value: "GetSO" },
      { text: "Total Invoice (Unpaid/Paid)", value: "GetTotalInvoice" },
      { text: "Total Uang Inv", value: "totaluangsales" },
      { text: "Uang Terbayar", value: "GetTotalUang" },
      //   { text: "Stock Keluar", value: "stock_keluar" },
      //   { text: "Ready Stock", value: "stock_ready" },
      { text: "Actions", value: "actions", sortable: false, width: "13%" },
    ],

    dataobjectbulan: [],
    dataobject: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_salesman: "",
      salesman: "",
      from_date: "",
      to_date: "",
      GetTotalBarang: "",
      GetTotalInvoice: "",
      GetTotalInvoicePaid: "",
      GetTotalInvoiceUnPaid: "",
    },
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],

    bulanDropDown:0,
    bulanDropDownText:'',
    tahunDropDown:'',

    itemsBulan: [
      { text: "Januari - Maret", value: 1 },
      { text: "April - Juni", value: 2 },
      { text: "Juli - September", value: 3 },
      { text: "Oktober - Desember", value: 4 },
    ],
    itemsTahun: [
      { text: "2021", value: 2021 },
      { text: "2022", value: 2022 },
      { text: "2023", value: 2023 },
      { text: "2024", value: 2024 },
      { text: "2025", value: 2025 },
      { text: "2026", value: 2026 },
      { text: "2027", value: 2027 },
      { text: "2028", value: 2028 },
      { text: "2029", value: 2029 },
      { text: "2030", value: 2030 },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    list_detail_invoice: "",
    list_detail_product: "",
    list_detail_customer: "",

    datepicker1: new Date().toISOString().substr(0, 10),
    menu21: "",
    datepicker2: new Date().toISOString().substr(0, 10),
    menu22: "",

    kodeAdmin: "",

    admin_role: "",
    admin_role_code: "",
    status_admin: "",

    
    from_date: new Date().toISOString().substr(0, 10),
    to_date: new Date().toISOString().substr(0, 10),

    showLoading : false,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      if (this.admin_role_code == "4" || this.admin_role_code == "99") {
        this.status_admin == "manager";
      } else {
        this.status_admin == "staff";
      }
    }
    // this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      this.showLoading = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanLabaRugiSemuaTransaksi?bulan=" +
            this.bulanDropDown +
            "&tahun=" +
            this.tahunDropDown,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobjectbulan = response.data.data.result.bulan;
          this.dataobject = response.data.data.result.data;
          this.showLoading = false;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.showLoading = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          this.showLoading = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.showLoading = false;
          this.dataobject = [];
        }
      }
    },

    changeBulan(){
      // console.log(this.bulanDropDown.text);
      switch (this.bulanDropDown) {
        case 1:
          this.bulanDropDownText = "Januari - Maret";
          break;
        case 2:
          this.bulanDropDownText = "April - Juni";
          break;
        case 3:
          this.bulanDropDownText = "Juli - September";
          break;
        case 4:
          this.bulanDropDownText = "Oktober - Desember";
          break;
      }
    },

    searchPerfroma() {
      if(this.bulanDropDown > 0 && this.tahunDropDown > 0){
        this.initialize();
      }else {
        this.snackbar = true;
        this.timeoutsnackbar = 3000;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal, Bulan dan Tahun wajib dipilih";
      }
    },

    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },
    download() {
      this.dialogDetailDownload = true;
    },
    downloadinvoice() {
      this.dialogDetailDownload = false;
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPenjualanSalesInv?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },
    downloadSO() {
      this.dialogDetailDownload = false;
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "ExportPenjualanSalesOrd?from_date=" +
          this.datepicker1 +
          "&to_date=" +
          this.datepicker2
      );
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahNominal(val) {
      return HelperGlobalService.getRupiahNominal(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



