<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.jenis_reseller="{ item }">
        <v-chip :color="getColorStatus(item.jenis_reseller)" dark>
          {{ getStatus(item.jenis_reseller) }}
        </v-chip>
      </template>

      <template v-slot:item.harga_pokok="{ item }">
        {{ getRupiah(item.harga_pokok) }}
      </template>
      <template v-slot:item.harga_jual="{ item }">
        {{ getRupiah(item.harga_jual) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>          
          <v-btn dark class="mb-2 mr-2" @click="showSingkronModal()" color="blue">
            <v-icon small>mdi-sync</v-icon>Singkron Product
          </v-btn>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          
          <v-dialog v-model="dialogSingkron" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline">
                <div style="text-align: left;width: 100%;" > Pilih singkronisasi</div>
              </v-card-title>
              <hr class="ml-2 mr-2">
              <v-card-text style="text-align: center;" class="mt-2">
                <v-btn color="#0685ea" elevation="1" class="mr-3" outlined @click="singkronProduct">
                  Product
                </v-btn>
                <v-btn color="#3e0ec1" elevation="1" outlined @click="singkronProductPromo">
                  Product Promo
                </v-btn>
                <!-- <v-btn color="#6e0bb6" elevation="1" outlined @click="closeSingkron">
                  Product Stock
                </v-btn> -->
              </v-card-text>
              <hr class="ml-2 mr-2" v-if="showHasil == true" >
              <v-card-text>
                <div v-if="showLoading == true && showHasil == true" style="text-align: center;margin-top: 10px;" ><h2>Proses singkronisasi, harap ditunggu...</h2></div> 
                <div v-if="showLoading == false && showHasil == true" style="text-align: center;margin-top: 10px;" ><h2>Proses singkronisasi selesai...</h2></div>                
                <div v-if="showLoading == true && showHasil == true" style="text-align: center;margin-top: 30px;">
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="blue"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <div v-if="showLoading == false && showHasil == true" style="text-align: center;margin-top: 10px;" ><h4>{{countSingkron}} data {{namaSingkron}} tersingkronkan</h4></div>
              </v-card-text>
              <hr class="ml-2 mr-2" v-if="showHasil == true" >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="closeSingkron"
                   v-if="showLoading == false && showHasil == true" 
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Selesai</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title
                class="headermodalstyle"
                style="margin-bottom: 10px"
              >
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="defaultItem.nama_product"
                        label="Nama Product"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.code_category"
                        :items="itemsCategory"
                        item-value="id"
                        item-text="nama_category"
                        label="Pilih Kategori"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.optional_category"
                        label="Spesifikasi Khusus"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.color"
                        label="Warna"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.satuan"
                        label="Satuan"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.harga_pokok"
                        label="Harga Pokok"
                        outlined
                        type="number"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.harga_jual"
                        label="Harga Jual"
                        outlined
                        type="number"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="defaultItem.deskripsi"
                        label="Deskripsi"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="pt-1">
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDetail" max-width="800px" class="pa-1">
            <v-card>
              <v-card-title class="mb-0 headermodalstyle">
                <span>Detail Product</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Code Product
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.code_product }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Nama Product
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_product }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Category Product
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_category }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Spesifikasi Khusus
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.optional_category }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Warna
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.color }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Satuan
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.satuan }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Harga Pokok
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.harga_pokok) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Harga Jual
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.harga_jual) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Deskripsi
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.deskripsi }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OngkirManual",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      { text: "Kode Product", value: "code_product" },
      { text: "Nama Product", value: "nama_product" },
      { text: "HPP", value: "harga_pokok" },
      { text: "Harga Jual", value: "harga_jual" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    dataobject: [],
    itemsCategory: [],

    defaultItem: {
      id: "",
      code_product: "",
      nama_product: "",
      code_category: "",
      nama_category: "",
      harga_pokok: "",
      harga_jual: "",
      satuan: "",
      deskripsi: "",
      optional_category: "",
      color: "",
    },

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",
    
    dialogSingkron: false,
    showLoading: false,
    showHasil: false,
    countSingkron : 0,
    namaSingkron: '',
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
    this.getCategory();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getCategory() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetProductCategory",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsCategory = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsCategory = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsCategory = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        code_product: this.defaultItem.code_product,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "SoftDeleteProduct",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        nama_product: this.defaultItem.nama_product,
        code_category: this.defaultItem.code_category,
        harga_pokok: this.defaultItem.harga_pokok,
        harga_jual: this.defaultItem.harga_jual,
        satuan: this.defaultItem.satuan,
        deskripsi: this.defaultItem.deskripsi,
        optional_category: this.defaultItem.optional_category,
        color: this.defaultItem.color,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddProduct",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal tambah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal tambah data";
        }
      }
    },
    async editData() {
      const datapost = {
        code_product: this.defaultItem.code_product,
        nama_product: this.defaultItem.nama_product,
        // code_category: this.defaultItem.code_category,
        // harga_pokok: this.defaultItem.harga_pokok,
        // harga_jual: this.defaultItem.harga_jual,
        // satuan: this.defaultItem.satuan,
        deskripsi: this.defaultItem.deskripsi,
        // optional_category: this.defaultItem.optional_category,
        // color: this.defaultItem.color,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditProduct",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async SyncroneProduct() {
      this.showLoading = true;
      const datapost = {
        admin_id: 'nama',
      };
      try {
        const response = await axios.post(
          this.BaseUrlGet +"SynchronizeProduct",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.showLoading = false;
          this.showHasil = true;
          this.namaSingkron = 'Product';
          this.countSingkron = response.data.data.result.count;
        } else {
          this.showLoading = false;
          this.showHasil = true;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
          this.showLoading = false;
          this.showHasil = true;
        } else {
          this.showLoading = false;
          this.showHasil = true;
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async SyncroneProductPromo() {
      this.showLoading = true;
      const datapost = {
        admin_id: 'nama',
      };
      try {
        const response = await axios.post(
          this.BaseUrlGet +"SynchronizeProductPromo",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.showLoading = false;
          this.showHasil = true;
          this.namaSingkron = 'Product Promo';
          this.countSingkron = response.data.data.result.count;
        } else {
          this.showLoading = false;
          this.showHasil = true;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
          this.showLoading = false;
          this.showHasil = true;
        } else {
          this.showLoading = false;
          this.showHasil = true;
          // this.detailOrder.defaultItem = [];
        }
      }
    },

    
    showSingkronModal(){
      this.dialogSingkron = true;
      this.showHasil = false;
      // this.SyncroneCust();
    },
    singkronProduct(){
      this.SyncroneProduct();
    },
    singkronProductPromo(){
      this.SyncroneProductPromo();
    },
    closeSingkron(){
      this.dialogSingkron = false;
    },

    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDetail = true;
    },
    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.id = "";
      this.defaultItem.nama_category = "";
      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.value = "edit";
      this.dialog = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      // const datapost = {
      //   nama_product: this.defaultItem.nama_product,
      //   code_category: this.defaultItem.code_category,
      //   harga_pokok: this.defaultItem.harga_pokok,
      //   harga_jual: this.defaultItem.harga_jual,
      //   satuan: this.defaultItem.satuan,
      //   deskripsi: this.defaultItem.deskripsi,
      //   optional_category: this.defaultItem.optional_category,
      //   color: this.defaultItem.color,
      // };
      // console.log(datapost);
      if(this.defaultItem.deskripsi){
        const string = this.defaultItem.deskripsi;
        const result = string.replace(/(\r\n|\r|\n)/g, '<br>');

        console.log(result);
        this.defaultItem.deskripsi = result
      }
      console.log(this.defaultItem);

      if (
        this.defaultItem.nama_product.length != 0 &&
        this.defaultItem.code_category.length != 0 
      ) {
        if (this.defaultItem.id) {
          console.log("edit");
          this.editData();
        } else {
          console.log("add");
          // this.addData();
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal, Kolom tidak boleh ada yang kosong";
      }
    },
    reset() {
      console.log(this.jenisResellerReset);
      console.log(this.diskonReset);
      this.resetDiskon();
    },

    getStatus(status) {
      if (status == "0") return "Customer Reg";
      else return "Reseller";
    },
    getColorStatus(status) {
      if (status == "0") return "orange";
      else return "green";
    },

    getRupiah(val) {
      var bilangan = val;

      if (bilangan) {
        var number_string = bilangan.toString(),
          sisa = number_string.length % 3,
          rupiah = number_string.substr(0, sisa),
          ribuan = number_string.substr(sisa).match(/\d{3}/g);

        if (ribuan) {
          var separator = sisa ? "." : "";
          rupiah += separator + ribuan.join(".");
        }

        return "Rp. " + rupiah;
      }
    },

    getTanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
  },
};
</script>



