<template>
  <div>

    <v-card elevation="5" class="pt-1 pb-0 px-2 mx-3 mt-1 mb-5">
      <v-card-text>
        <v-row>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="6"
            md="6"
            style="text-align: center"
          >
          <div style="display: flex" class="mt-1">
            <label style="font-size: 20px;font-weight: 700; margin-right:5px; margin-top:10px">Pilih Tahun :</label>
            <v-select
              v-model="tahun"
              :items="itemsyears"
              item-text="text"
              item-value="value"
              label="Years"
              outlined
            ></v-select>
          </div>
          </v-col>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="6"
            md="6"
            style="text-align: right"
          >
          <div style="display: flex; justify-content: right" class="mt-1">
            <v-btn large color="primary" elevation="1" @click="searchPiutang">
              <v-icon left> mdi-magnify </v-icon>
              Search
            </v-btn>
            <v-btn large color="success" elevation="1" class='ml-3' @click="add">
              <v-icon left> mdi-plus </v-icon>
              Add Item
            </v-btn>
          </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    
    <v-card elevation="5" class="pt-1 pb-0 px-2 mx-3 mt-5 mb-5">
      <v-card-text>
        <v-row>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="6"
            md="6"
            style="text-align: center"
          >
            <h3 style="font-styler: bold" class="mb-2 mt-1">
              Total Piutang Lain-lain
            </h3>
            <h2 style="font-styler: bold" class="mb-1 ">
              {{getRupiah(totalPiutang)}}
            </h2>
          </v-col>
          <v-col
            class="px-1 py-0"
            cols="12"
            sm="6"
            md="6"
            style="text-align: center"
          >
            <h3 style="font-styler: bold" class="mb-2 mt-1">
              Total Pembayaran Piutang Lain-lain
            </h3>
            <h2 style="font-styler: bold" class="mb-1 ">
              {{getRupiah(totalPembayaran)}}
            </h2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-simple-table style="margin-left: 10px;margin-right: 10px;padding: 10px;">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left" style="font-weight: 700;font-size: 17px;">
              Bulan
            </th>
            <th class="text-left" style="font-weight: 700;font-size: 17px;">
              Piutang
            </th>
            <th class="text-left" style="font-weight: 700;font-size: 17px;">
              Pembayaran Piutang
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataobject" :key="item.bulan">
            <td>{{ item.bulan }}</td>
            <td>{{ getRupiah(item.GetPiutangKaryawan) }}</td>
            <td>{{ getRupiah(item.GetPembayaranPiutangKaryawan) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

          <v-dialog v-model="dialogShow" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >What you want to add?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" elevation="1" outlined @click="addPiutang">
                  <v-icon left> mdi-plus </v-icon> Add Piutang
                </v-btn>
                <v-btn color="green" elevation="1" outlined @click="addPembayaran">
                  <v-icon left> mdi-plus </v-icon> Add Pembayaran
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          
          <v-dialog v-model="dialogPiutang" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">Tambah Piutang</span>
              </v-card-title>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.akun_kredit"
                        :items="itemsAkun"
                        item-value="no_akun"
                        item-text="nama_akun"
                        label="Pilih Akun Asal"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="defaultItem.nominal_awal"
                          label="Jumlah"
                          type="number"
                          outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="4" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Pilih Tanggal"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-textarea
                        v-model="defaultItem.keterangan"
                        label="Keterangan"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close </v-icon> Cancel
                </v-btn>
                <v-btn color="green" elevation="1" outlined @click="savePiutang">
                  <v-icon left> mdi-check-all </v-icon> Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogPembayaranPiutang" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">Tambah Pembayaran Piutang</span>
              </v-card-title>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.akun_debit"
                        :items="itemsAkun"
                        item-value="no_akun"
                        item-text="nama_akun"
                        label="Pilih Akun Tujuan"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                          v-model="defaultItem.nominal_awal"
                          label="Jumlah"
                          type="number"
                          outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" sm="4" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Pilih Tanggal"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-textarea
                        v-model="defaultItem.keterangan"
                        label="Keterangan"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close </v-icon> Cancel
                </v-btn>
                <v-btn color="green" elevation="1" outlined @click="savePembayaranPiutang">
                  <v-icon left> mdi-check-all </v-icon> Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PiutangLainlain",
  data: () => ({

    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu2: false,

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Tanggal",
        value: "tanggal",
      },
      { text: "Keterangan", value: "keterangan" },
      { text: "Nominal", value: "nominal_awal" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      tipe_piutang: "piutang_lain",
      nominal_awal: "",
      keterangan: "",
      akun_kredit: "",
      akun_debit: "",
    },

    itemsAkun: [],

    itemsmonth:[
      { text: "Januari", value: 1},
      { text: "Februari", value: 2},
      { text: "Maret", value: 3},
      { text: "April", value: 4},
      { text: "Mei", value: 5},
      { text: "Juni", value: 6},
      { text: "Juli", value: 7},
      { text: "Agustus", value: 8},
      { text: "September", value: 9},
      { text: "Oktober", value: 10},
      { text: "November", value: 11},
      { text: "Desember", value: 12},
    ],
    itemsyears:[
      { text: "2020", value: 2020},
      { text: "2021", value: 2021},
      { text: "2022", value: 2022},
      { text: "2023", value: 2023},
      { text: "2024", value: 2024},
      { text: "2025", value: 2025},
      { text: "2026", value: 2026},
      { text: "2027", value: 2027},
      { text: "2028", value: 2028},
      { text: "2029", value: 2029},
      { text: "2030", value: 2030},
    ],

    dialogShow: false,
    dialogPiutang: false,
    dialogPembayaranPiutang:false,

    tahun: 2022,
    totalPiutang:0,
    totalPembayaran:0,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    admin_id:"",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.getAkun();
    // this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetLaporanPiutangKaryawanAll?tipe=piutang_lain&tahun="+this.tahun,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.totalPembayaran = response.data.data.result.totalpembayaran;
          this.totalPiutang = response.data.data.result.totalpiutang;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getDetail() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetKasDetail?code_kas="+this.defaultItem.code_kas,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          // this.dataobject = response.data.data.result.data;
          this.defaultItem = Object.assign({}, response.data.data.result.GetKas);
          this.date = response.data.data.result.GetKas.tanggal;
          this.itemlistkas = response.data.data.result.GetKasDetail;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getAkun() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAkun = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAkun = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAkun = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        code_kas: this.defaultItem.code_kas,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteKas",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addDataPiutang() {
      const datapost = {
        tipe_piutang: 'piutang_lain',
        akun_kredit: this.defaultItem.akun_kredit,
        keterangan: this.defaultItem.keterangan,
        nominal_awal: this.defaultItem.nominal_awal,
        date: this.date,
        code_admin: this.admin_id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPiutangKaryawan",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogPiutang = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          this.dialogPiutang = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addDataPembayaranPiutang() {
      const datapost = {
        tipe_piutang: 'piutang_lain',
        akun_debit: this.defaultItem.akun_debit,
        keterangan: this.defaultItem.keterangan,
        nominal_awal: this.defaultItem.nominal_awal,
        date: this.date,
        code_admin: this.admin_id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPembayaranPiutangKaryawan",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogPembayaranPiutang = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          this.dialogPembayaranPiutang = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },

    searchPiutang(){
      this.initialize();
    },
    add(){
      this.dialogShow = true;
    },
    addPiutang(){
      this.dialogPiutang =true;
      this.dialogShow = false;
    },
    addPembayaran(){
      this.dialogShow = false;
      this.dialogPembayaranPiutang = true;
    },
    savePiutang(){
      console.log(this.defaultItem);
      if(this.defaultItem.akun_kredit.length<1 || this.defaultItem.nominal_awal.length<1 || this.defaultItem.keterangan.length<1){        
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }else{
        this.addDataPiutang()
      }
    },
    savePembayaranPiutang(){
      console.log(this.defaultItem);
      if(this.defaultItem.akun_debit.length<1 || this.defaultItem.nominal_awal.length<1 || this.defaultItem.keterangan.length<1){        
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }else{
        this.addDataPembayaranPiutang()
      }
    },
    close() {
      this.dialogPiutang = false;
      this.dialogShow = false;
      this.dialogPembayaranPiutang = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeModalTambahKas(){
      this.dialogKas = false;
    },
    save() {
      this.defaultItem.tipe = 'kas_masuk';
      this.defaultItem.tanggal_kas = this.date;
      console.log(this.defaultItem);
      console.log(this.itemlistkas);

      if(this.defaultItem.code_kas){
        this.updateData();
      }else{
        this.addData();
      }
    },

    deleteItemKas(item) {
      this.editedIndexKas = this.itemlistkas.indexOf(item);
      this.editedItemKas = Object.assign({}, item);
      this.dialogDeleteKas = true;
    },
    deleteItemConfirmAnak() {
      this.itemlistkas.splice(this.editedIndexKas, 1);
      this.closeDeleteKas();
    },
    closeKas() {
      this.dialogKas = false;
      this.$nextTick(() => {
        this.editedItemKas = Object.assign({}, this.defaultItemKas);
        this.editedIndexKas = -1;
      });
    },
    closeDeleteKas() {
      this.dialogDeleteKas = false;
      this.$nextTick(() => {
        this.editedItemKas = Object.assign({}, this.defaultItemKas);
        this.editedIndexKas = -1;
      });
    },
    saveKas() {
      console.log(this.editedItemKas.no_akun);
      console.log(this.editedItemKas.no_akun);
      if (this.editedIndexKas > -1) {
        Object.assign(
          this.itemlistkas[this.editedIndexKas],
          this.editedItemKas
        );
      } else {
        this.editedItemKas.nama_akun = this.editedItemKas.akun.nama_akun;
        this.editedItemKas.no_akun = this.editedItemKas.akun.no_akun;
        this.itemlistkas.push(this.editedItemKas);
        //   console.log(this.datepicker4);
      }
      this.closeKas();
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      var date = val.split(" ")[0];
      var time = val.split(" ")[1];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },

    getRupiah(val) {
      var bilangan = val;

      var number_string = bilangan.toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);

      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      return "Rp. " + rupiah;
    },

    getRupiahTotal(val1, val2, val3) {
      var bilangan = val1 + val2 - val3;

      var number_string = bilangan.toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);

      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      return "Rp. " + rupiah;
    },
  },
};
</script>