<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      group-by="nama_klasifikasi_lengkap"
      :disable-pagination="booleanpagination"
      :hide-default-footer="booleanpagination"
      class="rounded elevation-6 mx-4 pa-1 mb-4"
    >
      <template v-slot:group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
          {{ group }}
        </td>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-btn
            dark
            class="mb-2 mr-1 ml-1 d-none d-md-block"
            @click="download()"
            color="blue"
          >
            <v-icon class="mr-1" small>mdi-download-circle</v-icon> Download
          </v-btn>
          <v-dialog v-model="dialog" max-width="700px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.no_akun_subklasifikasi"
                        :items="itemsSublasifikasi"
                        item-value="no_akun_subklasifikasi"
                        item-text="nama_subklasifikasi_lengkap"
                        label="Pilih Parent"
                        clearable
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="defaultItem.no_akun"
                        label="No Akun"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <v-text-field
                        v-model="defaultItem.nama_akun"
                        label="Nama Akun"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="defaultItem.type_akun_debit_kredit"
                        :items="itemsDK"
                        item-value="value"
                        item-text="text"
                        label="Debit atau Kredit"
                        clearable
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="defaultItem.jenis_akun_nr_lr"
                        :items="itemsNRLR"
                        item-value="value"
                        item-text="text"
                        label="Neraca atau Laba rugi"
                        clearable
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="defaultItem.kas"
                        :items="itemsKas"
                        item-value="value"
                        item-text="text"
                        label="Jenis Kas"
                        clearable
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="defaultItem.saldo_awal_debit"
                        label="Saldo Awal Debit"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        v-model="defaultItem.saldo_awal_kredit"
                        label="Saldo Awal Kredit"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Akun",
  data: () => ({
    itemsbr: [
      {
        text: "Ledger",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Data Akun",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    booleanpagination: true,
    booleanfooter: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "No Akun",
        value: "no_akun",
      },
      { text: "Nama Akun", value: "nama_akun" },
      { text: "Group", value: "nama_subklasifikasi_lengkap" },
      { text: "Debit/Kredit", value: "type_akun_debit_kredit" },
      { text: "NR/LR", value: "jenis_akun_nr_lr" },
      { text: "Kas", value: "kas" },
      { text: "Actions", value: "actions", width: "12%", sortable: false },
    ],
    dataobject: [],

    defaultItem: {
      id: "",
      no_akun_klasifikasi: "",
      no_akun: "",
      nama_akun: "",
      type_akun_debit_kredit: "",
      jenis_akun_nr_lr: "",
      kas: "",
      saldo_awal_debit: "",
      saldo_awal_kredit: "",
    },

    itemsDK: [
      { text: "Debit", value: "debit" },
      { text: "Kredit", value: "kredit" },
    ],
    itemsNRLR: [
      { text: "Neraca", value: "neraca" },
      { text: "Laba_Rugi", value: "laba_rugi" },
    ],
    itemsKas: [
      { text: "Kas", value: "kas" },
      { text: "-", value: "-" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    itemsSublasifikasi: [],
  }),

  beforeMount() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.getSubklasifikasi();
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },

    async getSubklasifikasi() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAkunSubklasifikasi?limit=all",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsSublasifikasi = response.data.data.result.data;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          //   this.alerttoken = true;
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },

    async verifDelete() {
      const datapost = {
        id: this.defaultItem.id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "SoftDeleteAkun",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          //   this.dialogDelete = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        no_akun_subklasifikasi: this.defaultItem.no_akun_subklasifikasi,
        no_akun: this.defaultItem.no_akun,
        nama_akun: this.defaultItem.nama_akun,
        type_akun_debit_kredit: this.defaultItem.type_akun_debit_kredit,
        jenis_akun_nr_lr: this.defaultItem.jenis_akun_nr_lr,
        saldo_awal_debit: this.defaultItem.saldo_awal_debit,
        saldo_awal_kredit: this.defaultItem.saldo_awal_kredit,
        kas: this.defaultItem.kas,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddAkun",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          this.dialog = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          //   this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async updateData() {
      const datapost = {
        id: this.defaultItem.id,
        no_akun_subklasifikasi: this.defaultItem.no_akun_subklasifikasi,
        no_akun: this.defaultItem.no_akun,
        nama_akun: this.defaultItem.nama_akun,
        type_akun_debit_kredit: this.defaultItem.type_akun_debit_kredit,
        jenis_akun_nr_lr: this.defaultItem.jenis_akun_nr_lr,
        saldo_awal_debit: this.defaultItem.saldo_awal_debit,
        saldo_awal_kredit: this.defaultItem.saldo_awal_kredit,
        kas: this.defaultItem.kas,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "UpdateAkun",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          this.dialog = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          //   this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },

    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.id = "";
      this.defaultItem.no_akun_subklasifikasi = "";
      this.defaultItem.no_akun = "";
      this.defaultItem.nama_akun = "";
      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      console.log(this.defaultItem);
      this.value = "edit";
      this.dialog = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      if (
        this.defaultItem.no_akun.length != 0 &&
        this.defaultItem.nama_akun.length != 0
      ) {
        if (this.defaultItem.id) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
        this.close();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },

    download() {
      console.log(this.gudang);
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) + "ExportExcelAkunAkuntansi"
      );
    },
  },
};
</script>