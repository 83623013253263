<template>
  <div>
    
    <v-card v-if='this.undermaintenance == true' elevation="2" class="mx-auto my-1 px-1" max-width="500">        
                <v-img
                    contain
                    max-height="350"
                    max-width="100%"
                    src="/images/under-maintenance.jpg"
                >                    
                </v-img>
    </v-card>

    <v-data-table v-if='this.undermaintenance == false' 
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.updated_at="{ item }">
        {{ gettanggal(item.updated_at) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="download()" color="#b3b928">
            <v-icon left> mdi-download-circle-outline </v-icon> Download
          </v-btn>

          <v-dialog v-model="dialogEditStatus" max-width="600px">
            <v-card class="fontall">
              <v-card-title class="mb-0 headermodalstyle"
                >Update Status Invoice?</v-card-title
              >
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.status"
                        :items="itemsStatusSO"
                        item-value="value"
                        item-text="text"
                        label="Pilih Status"
                        outlined
                        v-on:change="changestatus"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="saveeditstatus"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Save</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "LaporanStock",
  data: () => ({

    undermaintenance : true,
    
    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    load: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAdd: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showProduct: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Code Product",
        value: "code_product",
      },
      { text: "Nama Product", value: "nama_product", width: "20%" },
      { text: "Stock Awal", value: "stock_awal" },
      { text: "Stock Masuk", value: "stock_masuk" },
      { text: "Stock Keluar", value: "stock_keluar" },
      { text: "Ready Stock", value: "stock_ready" },
      { text: "Updated", value: "updated_at", width: "13%" },
    ],
    dataobject: [],
    ordercombo_so: "",

    defaultItem: {
      id: "",
      code_stock_opname: "",
      keterangan: "",
      tipe_stock_opname: "",
      created_by: "",
      status: "",
      tanggal_stock_opname: "",
      keterangan: "",
      list_product: [],
    },
    itemsTipe: [
      { text: "All Product", value: "all_product" },
      { text: "Some Product", value: "some_products" },
    ],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    kodeAdmin: "",

    admin_role: "",
    admin_role_code: "",
    status_admin: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_role = this.User.role;
      this.admin_role_code = this.User.role_code;
      if (this.admin_role_code == "4" || this.admin_role_code == "99") {
        this.status_admin == "manager";
      } else {
        this.status_admin == "staff";
      }
    }
    this.initialize();
    // this.GetAdmin();
    // // this.getKabKotaROAll();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetStockProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },

    cetakItem(item) {
      this.downloadStockOpname(item.code_stock_opname);
    },
    downloadStockOpname(val) {
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) +
          "CetakStockOpnameDetail?code_stock_opname=" +
          val
      );

      // this.valueMUExcel = "";
      // this.valueTAExcel = "";
      // this.valueVillageExcel = "";
      // this.typegetdataExcel = "";
      // this.valueFFcodeExcel = "";
    },

    download() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(str.substring(0, str.length - 4) + "ExportStockProduct");
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



