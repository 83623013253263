<template>
  <div>
    <v-card class="mx-3" v-if="detailshow == true && detailpagebayar== false">
      <v-container class="pt-5 px-3">
        <v-row>
          <v-btn dark class="mb-2" @click="backtable()" color="red">
            <v-icon left> mdi-arrow-left-circle </v-icon> Back
          </v-btn>
          <v-spacer class="d-none d-sm-block"></v-spacer>
          <h4 class="d-none d-sm-block">
            Detail Hutang {{ defaultItem.nama_supplier }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="download()" color="#b3b928">
            <v-icon left> mdi-download-circle-outline </v-icon> Download
          </v-btn>
        </v-row>
        <v-row class="d-sm-none">
          <v-spacer></v-spacer>
          <h4>Detail Hutang {{ defaultItem.nama_supplier }}</h4>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" style="font-size: 14px">Total Hutang</th>
              <th class="text-center" style="font-size: 14px">
                Total Pembayaran
              </th>
              <th class="text-center" style="font-size: 14px">Sisa Hutang</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-center" style="font-size: 14px">
                {{ getRupiah(defaultItem.total_hutang) }}
              </td>
              <td class="text-center" style="font-size: 14px">
                {{ getRupiah(defaultItem.total_payment) }}
              </td>
              <td class="text-center" style="font-size: 14px">
                {{ getRupiah(defaultItem.sisahutang) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider
        style="border-color: black !important"
        class="mx-1 mb-2"
      ></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" style="font-size: 13px">Purchase No</th>
              <th class="text-center" style="font-size: 13px">Status</th>
              <th class="text-center" style="font-size: 13px">Total Piutang</th>
              <th class="text-center" style="font-size: 13px">
                Total Pembayaran
              </th>
              <th class="text-center" style="font-size: 13px">Sisa Piutang</th>
              <th class="text-center" style="font-size: 13px">Tanggal</th>
              <th class="text-center" style="font-size: 13px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in defaultItem.detail" :key="product.code_order">
              <td
                class="text-left"
                style="width: 250px !important; font-size: 13px"
              >
                {{ product.code_order }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ product.payment_status }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getRupiah(product.total_hutang) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getRupiah(product.total_payment) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getRupiah(product.sisahutang) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getTanggal(product.created_at) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                <v-icon
                  class="mr-1"
                  @click="detailInv(product.code_order)"
                  color="primary"
                >
                  mdi-information-outline
                </v-icon>
                <v-icon
                  class="mr-1"
                  @click="updatestatusinvoice(product.code_order)"
                  color="warning"
                >
                  mdi-pencil-circle-outline
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider
        style="border-color: black !important"
        class="mx-1 mb-2"
      ></v-divider>
      <!-- Modal Detail -->
      <v-dialog v-model="dialogDetailInvoice" max-width="700px">
        <v-card>
          <v-card-title class="headermodalstyle mb-0">
            <span>Detail Pembayaran</span>
          </v-card-title>

          <v-card-text class="fontall pa-1">
            <v-container
              v-if="loaddetail == true"
              fluid
              fill-height
              style="background-color: rgba(255, 255, 255, 0.5)"
            >
              <v-layout justify-center align-center>
                <v-progress-circular
                  :size="80"
                  :width="10"
                  indeterminate
                  color="primary"
                >
                </v-progress-circular>
              </v-layout>
            </v-container>

            <v-container v-if="loaddetail == false">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        No Invoice
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ detail.code_order }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Total Hutang
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.total_hutang) }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Total Bayar
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.total_payment) }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Sisa Hutang
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.sisahutang) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-2 mb-1">
                <v-divider
                  class="mx-2 mt-4"
                  style="border-color: black"
                ></v-divider>
                <!-- <v-btn
                  dark
                  class="mb-2"
                  @click="addpembayaraninv()"
                  color="green"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon> Tambah
                  Pembayaran
                </v-btn> -->
                <v-divider
                  class="mx-2 mt-4"
                  style="border-color: black"
                ></v-divider>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center d-none d-sm-block"
                        style="width: 300px; font-size: 13px"
                      >
                        Keterangan
                      </th>
                      <th class="text-center" style="font-size: 13px">
                        Pembayaran
                      </th>
                      <th class="text-center" style="font-size: 13px">
                        Tanggal
                      </th>
                    </tr>
                  </thead>
                  <tbody>                    
                    <tr v-for="product in detail.detail" :key="product.id">
                      <td
                        class="text-center d-none d-sm-block"
                        style="font-size: 13px"
                      >
                       {{ product.keterangan }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ getRupiah(product.nominal) }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ getTanggal(product.tanggal) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPembayaran" max-width="400px">
        <v-card class="fontall">
          <v-card-title class="mb-0 headermodalstyle"
            >Update Pembayaran Invoice?</v-card-title
          >
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-menu v-model="menu2" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datepicker"
                        slot="activator"
                        label="Tanggal Pembayaran"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datepicker"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="bayar_temp"
                    label="Isikan Nominal Bayar"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="saveepembayaran"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >Save</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card class="fontall">
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <!-- <v-card-text>
                <h4 class="ml-2" style="color: red">
                  Note: Invoice sudah di rekam ke piutang, jika di hapus maka
                  <span style="text-decoration: underline"
                    >"Total Piutang Customer akan berkurang"</span
                  >
                </h4>
              </v-card-text> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="deletepembayaran"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogupdatestatus" max-width="500px">
        <v-card class="fontall">
          <v-card-title class="headline">Update status Purchase?</v-card-title>
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-select
                    v-model="status_so_temp"
                    :items="itemsStatusSO"
                    item-value="value"
                    item-text="text"
                    label="Pilih Status"
                    outlined
                    :rules="[(v) => !!v || 'Field is required']"
                  ></v-select>
                </v-col>
                <!-- <v-col
                      v-if="status_show_bayar == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="bayar_temp"
                        label="Isikan Nominal Bayar"
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-col> -->
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <h4 class="ml-2" style="color: red">
                    Note: Hanya merubah status Invoice
                    <span style="text-decoration: underline"
                      >"Tidak akan merubah piutang dan pemasukan"</span
                    >
                  </h4>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="updatepaidItemConfirm"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-card class="mx-3" v-if="detailshow == true && detailpagebayar== true">
      <v-container class="pt-5 px-3">
        <v-row>
          <v-btn dark class="mb-2" @click="backtable()" color="red">
            <v-icon left> mdi-arrow-left-circle </v-icon> Back
          </v-btn>
          <v-spacer class="d-none d-sm-block"></v-spacer>
          <h4 class="d-none d-sm-block">
            Detail Pembayaran {{ defaultItem.nama_supplier }}
          </h4>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="download()" color="#b3b928">
            <v-icon left> mdi-download-circle-outline </v-icon> Download
          </v-btn>
        </v-row>
        <v-row class="d-sm-none">
          <v-spacer></v-spacer>
          <h4>Detail Pembayaran {{ defaultItem.nama_supplier }}</h4>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
      <v-divider
        style="border-color: black !important"
        class="mx-1 mb-2"
      ></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" style="font-size: 13px">Code</th>
              <th class="text-center" style="font-size: 13px">Nominal</th>
              <th class="text-center" style="font-size: 13px">Tanggal</th>
              <th class="text-center" style="font-size: 13px">Created At</th>
              <th class="text-center" style="font-size: 13px">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in itempembayaraninv" :key="product.code_pembayaran">
              <td
                class="text-left"
                style="width: 250px !important; font-size: 13px"
              >
                {{ product.code_pembayaran }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getRupiah(product.nominal) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getTanggal(product.tanggal) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                {{ getTanggal(product.created_at) }}
              </td>
              <td class="text-center" style="font-size: 13px">
                <v-icon
                  class="mr-1"
                  @click="detailPagePembayaran(product.code_order)"
                  color="primary"
                >
                  mdi-information-outline
                </v-icon>                
                <v-icon class="mr-1" @click="editPagePembayaran(product)" color="warning">
                  mdi-pencil-circle-outline
                </v-icon>
                <v-icon @click="deletePagePembayaran(product)" color="red">
                  mdi-delete-circle
                </v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider
        style="border-color: black !important"
        class="mx-1 mb-2"
      ></v-divider>
      <!-- Modal Detail -->
      <v-dialog v-model="dialogDetailInvoice" max-width="700px">
        <v-card>
          <v-card-title class="headermodalstyle mb-0">
            <span>Detail Pembayaran</span>
          </v-card-title>

          <v-card-text class="fontall pa-1">
            <v-container
              v-if="loaddetail == true"
              fluid
              fill-height
              style="background-color: rgba(255, 255, 255, 0.5)"
            >
              <v-layout justify-center align-center>
                <v-progress-circular
                  :size="80"
                  :width="10"
                  indeterminate
                  color="primary"
                >
                </v-progress-circular>
              </v-layout>
            </v-container>

            <v-container v-if="loaddetail == false">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        No Invoice
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ detail.code_order }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Total Hutang
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.total_hutang) }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Total Bayar
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.total_payment) }}
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="text-left"
                        style="width: 250px !important; font-size: 14px"
                      >
                        Sisa Hutang
                      </th>
                      <td class="text-left" style="font-size: 14px">
                        {{ getRupiah(detail.sisahutang) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-row class="mt-2 mb-1">
                <v-divider
                  class="mx-2 mt-4"
                  style="border-color: black"
                ></v-divider>
                <!-- <v-btn
                  dark
                  class="mb-2"
                  @click="addpembayaraninv()"
                  color="green"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon> Tambah
                  Pembayaran
                </v-btn> -->
                <v-divider
                  class="mx-2 mt-4"
                  style="border-color: black"
                ></v-divider>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-center d-none d-sm-block"
                        style="width: 300px; font-size: 13px"
                      >
                        Keterangan
                      </th>
                      <th class="text-center" style="font-size: 13px">
                        Pembayaran
                      </th>
                      <th class="text-center" style="font-size: 13px">
                        Tanggal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="product in detail.detail" :key="product.id">
                      <td
                        class="text-center d-none d-sm-block"
                        style="font-size: 13px"
                      >
                       Kode Pembayaran : {{ product.code_pembayaran }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ getRupiah(product.nominal) }}
                      </td>
                      <td class="text-center" style="font-size: 13px">
                        {{ getTanggal(product.tanggal) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-container>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogPembayaran" max-width="400px">
        <v-card class="fontall">
          <v-card-title class="mb-0 headermodalstyle"
            >Update Pembayaran Invoice?</v-card-title
          >
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-menu v-model="menu2" transition="scale-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datepicker"
                        slot="activator"
                        label="Tanggal Pembayaran"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="datepicker"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="pb-0" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="bayar_temp"
                    label="Isikan Nominal Bayar"
                    outlined
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="saveepembayaran"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >Save</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card class="fontall">
          <v-card-title class="headline"
            >Are you sure you want to delete this item?</v-card-title
          >
          <!-- <v-card-text>
                <h4 class="ml-2" style="color: red">
                  Note: Invoice sudah di rekam ke piutang, jika di hapus maka
                  <span style="text-decoration: underline"
                    >"Total Piutang Customer akan berkurang"</span
                  >
                </h4>
              </v-card-text> -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" elevation="1" outlined @click="close">
              <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
            >
            <v-btn
              color="success"
              elevation="1"
              outlined
              @click="deletepembayaran"
            >
              <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="dialogupdatestatus" max-width="500px">
          <v-card class="fontall">
            <v-card-title class="headline">Update status Purchase?</v-card-title>
            <v-card-text class="pa-0 fontall">
              <v-container>
                <v-row>
                  <v-col class="pb-0" cols="12" sm="12" md="12">
                    <v-select
                      v-model="status_so_temp"
                      :items="itemsStatusSO"
                      item-value="value"
                      item-text="text"
                      label="Pilih Status"
                      outlined
                      :rules="[(v) => !!v || 'Field is required']"
                    ></v-select>
                  </v-col>
                  <!-- <v-col
                        v-if="status_show_bayar == true"
                        class="pb-0"
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="bayar_temp"
                          label="Isikan Nominal Bayar"
                          outlined
                          type="number"
                        ></v-text-field>
                      </v-col> -->
                  <v-col class="pb-0" cols="12" sm="12" md="12">
                    <h4 class="ml-2" style="color: red">
                      Note: Hanya merubah status Invoice
                      <span style="text-decoration: underline"
                        >"Tidak akan merubah piutang dan pemasukan"</span
                      >
                    </h4>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" elevation="1" outlined @click="close">
                <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
              >
              <v-btn
                color="success"
                elevation="1"
                outlined
                @click="updatepaidItemConfirm"
              >
                <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-card>

      <v-dialog v-model="dialogMaintenance" max-width="600px">
        <v-card class="fontall">
          <v-card-title class="mb-0 headermodalstyle"
            >Opps Sorry!!!</v-card-title
          >
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row class="mt-4 mb-5">
                <v-col cols="12">
                  <div style="text-align: center;font-size: larger;font-weight: 900;">Sistem Sedang Maintenance, Maaf atas ketidaknyamanannya</div>  
                </v-col>              
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
      v-if="detailshow == false && detailpagebayar== false"
    >
      <template v-slot:item.total_hutang="{ item }">
        {{ getRupiah(item.total_hutang) }}
      </template>

      <template v-slot:item.total_payment="{ item }">
        {{ getRupiah(item.total_payment) }}
      </template>

      <template v-slot:item.sisahutang="{ item }">
        {{ getRupiah(item.sisahutang) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ getTanggal(item.created_at) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="bayarHutang()" color="#282fb9">
            <v-icon left> mdi-credit-card-outline </v-icon> Bayar Hutang
          </v-btn>

           <!-- Modal Add/ Edit -->
          <v-dialog v-model="dialogBayarHutang" max-width="700px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Main Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable step="2">
                      List Invoice
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Supllier</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.supplier"
                              :items="itemssupplier"
                              item-value="code_supplier"
                              item-text="nama_supplier"
                              label="Pilih Supllier"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Pilih Tanggal Pembelian</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="pb-0" cols="12" sm="6" md="6">
                            <v-menu
                              v-model="menu2"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="datepicker"
                                  slot="activator"
                                  label="Tanggal"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Field is required']"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="datepicker"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Pilih Akun Bayar</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>                          
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.akun_debit"
                              :items="itemsAkun"
                              label="Pilih Akun Bayar"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="closeDialogBayar"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 2"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="2">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Barang</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              :items="itemsInvoice"
                              item-value="code_product"
                              item-text="nama_product"
                              label="Pilih Barang"
                              v-on:change="changeBarangSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Barang Pembelian</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <!-- <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListProduct"
                              :items="defaultItem.list_order"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitemproduct(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                              </template>
                            </v-data-table>
                          </v-col> -->
                        </v-row>
                      </v-container>
                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="closeDialogBayar"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="savepembelian('create')"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogBayar" max-width="900px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Pilih Tanggal Bayar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="date"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="targetItem.akun_debit"
                        :items="itemsAkun"
                        item-value="no_akun"
                        item-text="nama_akun"
                        label="Pilih Akun Hutang"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        v-model="targetItem.akun_kredit"
                        :items="itemsAkun"
                        item-value="no_akun"
                        item-text="nama_akun"
                        label="Pilih Akun Sumber"
                        clearable
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="targetItem.supplier"
                        :items="itemssupplier"
                        item-value="code_supplier"
                        item-text="nama_supplier"
                        label="Pilih Supllier"
                        outlined
                        v-on:change="changeSupplierBayar"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headerslistbayar"
                        :items="itemlistbayar"
                        sort-by="calories"
                        class="elevation-1"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-toolbar-title>Daftar Pembayaran</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialogTambahBayar" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  Tambah Pembayaran
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Daftar Invoice</span>
                                </v-card-title>
                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-combobox
                                          v-model="editedItemBayar.invoice"
                                          :items="itemsInvoiceSuplier"
                                          label="Pilih Invoice"
                                          item-value="code_order"
                                          item-text="code_order"
                                          clearable
                                          outlined
                                          v-on:change="changeInvSupplierBayar"
                                          :rules="[
                                            (v) => !!v || 'Field is required',
                                          ]"
                                        ></v-combobox>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemBayar.total"
                                          label="Total Hutang"
                                          type="number"
                                          outlined
                                          disabled
                                          prefix="Rp."
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-text-field
                                          v-model="editedItemBayar.sisa"
                                          label="Sisa Hutang"
                                          type="number"
                                          outlined
                                          disabled
                                          prefix="Rp."
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="6" md="6">
                                        <v-checkbox
                                          v-model="checkboxBayarSemua"
                                          label="Bayar Semua"
                                          v-on:change="changecheckboxBayarSemua"
                                        ></v-checkbox>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-text-field
                                          v-model="editedItemBayar.nominal"
                                          label="Jumlah Bayar"
                                          type="number"
                                          outlined
                                          prefix="Rp."
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeBayar"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="saveBayar"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog
                              v-model="dialogDeleteBayar"
                              max-width="500px"
                            >
                              <v-card>
                                <v-card-title class="text-h5"
                                  >Are you sure you want to delete this
                                  item?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDeleteBayar"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirmBayar"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon small @click="deleteItemBayar(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeBayarHutang">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="saveBayarHutang"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          
      <v-dialog v-model="dialogPilihDetail" max-width="600px">
        <v-card class="fontall">
          <v-card-title class="mb-0 headermodalstyle"
            >Pilih Detail?</v-card-title
          >
          <v-card-text class="pa-0 fontall">
            <v-container>
              <v-row>
                <v-col class="pb-1 mb-5 mt-3" cols="12" sm="12" md="12" style="text-align: center;">
                  <v-btn color="teal" dark @click="detailItem" class="mr-2">
                    Detail Order dan Hutang
                  </v-btn>
                  <v-btn color="primary" @click="detailBayar">
                    Detail Pembayaran
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailPilih(item)" color="primary">
          mdi-information-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "ManajemenHutang",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    valid: true,
    loadtable: false,
    loaddetail: false,
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogDetailInvoice: false,
    detailshow: false,
    dialogPembayaran: false,
    dialogupdatestatus: false,
    dialogBayarHutang: false,
    dialogAddProduct: false,
    dialogPilihDetail: false,
    detailpagebayar: false,
    dialogMaintenance:false,
    search: "",
    authtoken: "",
    BaseUrl: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrlDBImage: "",
    headers: [
      { text: "Code Supplier", value: "code_supplier" },
      { text: "Nama Supplier", value: "nama_supplier" },
      { text: "Total Hutang", value: "total_hutang" },
      { text: "Total Pembayaran", value: "total_payment" },
      { text: "Sisa Hutang", value: "sisahutang" },
      { text: "Actions", value: "actions", sortable: false, width: "12%" },
    ],
    headerListDetail: [
      { text: "Code Order Invoice", value: "code_order" },
      { text: "Total Piutang", value: "total_piutang" },
      { text: "Total Pembayaran", value: "total_payment" },
      { text: "Sisa Piutang", value: "sisapiutang" },
      { text: "Tanggal", value: "created_at" },
    ],
    headerslistbayar: [
      {
        text: "No Order",
        align: "start",
        sortable: false,
        value: "code_order",
      },
      { text: "Total Hutang", value: "total" },
      { text: "Sisa Hutang", value: "sisa" },
      { text: "Jumlah Bayar", value: "nominal" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],
    itemsCategory: [],
    itemsStatusSO: [
      { text: "Unpaid", value: "unpaid" },
      { text: "Partial", value: "partial" },
      { text: "Paid", value: "paid" },
    ],

    itemssupplier: [],
    itemsAkun: [],
    itemsInvoice: [],
    itemsInvoiceSuplier:[],

    itempembayaraninv:[],

    defaultItem: {
      id: "",
      code_supplier: "",
      nama_supplier: "",
      total_piutang: "",
      total_payment: "",
      created_at: "",
      detail: "",
    },

    detail: {
      code_order: "",
      total_hutang: "",
      total_payment: "",
      sisahutang: "",
      detail: [],
    },

    targetItem: {
      supplier:[],
      code_supplier: "",
      akun_debit: "",
      akun_kredit: "",
      code_admin: "",
      list_bayar: "",
    },

    checkboxBayarSemua: false,
    
    itemlistbayar: [],
    dialogBayar: false,
    dialogTambahBayar: false,
    dialogDeleteBayar: false,
    editedIndexBayar: -1,
    editedItemBayar: {
      invoice:[],
      code_order: "",
      total: "",
      sisa: "-",
      nominal: "-",
    },
    defaultItemBayar: {
      invoice:[],
      code_order: "",
      total: "",
      sisa: "-",
      nominal: "-",
    },

    code_order_temp: "",
    status_so_temp: "",
    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    bayar_temp: 0,
    id_temp: 0,
    code_inv_temp: 0,

    id_bayar_hutang_temp:"",

    e1: 1,
    stepperdone: false,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    admin_id: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.initialize();
    this.getsupplier();
    this.getAkun();
  },

  // computed:{    
  //   initialTotal:{
  //     get: function() {
  //       console.log('test')
  //       return this.getRupiah(this.editedItemBayar.total)
  //     },
  //     set: function(newValue) {
  //       this.editedItemBayar.total =  this.editedItemBayar.total;
  //     }
  //   }
  // },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetLaporanHutang", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },    
    async getsupplier() {
      // this.dataobject = [];
      // this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetSupplier", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          // this.loadtable = false;
          this.itemssupplier = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.itemssupplier = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          // this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.loadtable = false;
          this.itemssupplier = [];
        }
      }
    },
    async getAkun() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAkun = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAkun = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAkun = [];
        }
      }
    },
    async getDetail() {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanHutangDetail?code_supplier=" +
            this.defaultItem.code_supplier,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.itemsInvoice = response.data.data.result.detail;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async getInvSupplier(code_supplier) {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanHutangDetail?code_supplier=" +
            code_supplier,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          // this.defaultItem = response.data.data.result;
          this.itemsInvoiceSuplier = response.data.data.result.detail;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async getDetailInvoice(val) {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetHutangInvDetail?code_order=" + val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data);
        if (response.data.length != 0) {
          this.detail.code_order =
            response.data.data.result.GetLaporanhutang.code_order;
          this.detail.total_hutang =
            response.data.data.result.GetLaporanhutang.total_hutang;
          this.detail.total_payment =
            response.data.data.result.GetLaporanhutang.total_payment;
          this.detail.sisahutang =
            response.data.data.result.GetLaporanhutang.sisahutang;
          this.detail.detail = response.data.data.result.detail;
          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async AddPiutangInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddHutangInvDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetailInvoice = false;
          this.dialogPembayaran = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async EditPiutangInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditHutangInvDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetailInvoice = false;
          this.dialogPembayaran = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async DeletePiutangInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteHutangInvDetail",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogDetailInvoice = false;
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async UpdateStatusInvDetail(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditOnlyStatusPurchase",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogupdatestatus = false;
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async AddPembayaranHutang(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddPembayaranHutang",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogBayar = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
          // this.getDetail();
          //   this.getDetailInvoice(this.code_order_temp);
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async getDetailPembayaranHutang() {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPembayaranHutang?code_supplier=" +
            this.defaultItem.code_supplier,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.itempembayaraninv = response.data.data.result.GetLaporanPembayaranhutang;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },

    detailPilih(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogPilihDetail = true;
    },
    detailItem() {
      this.dialogPilihDetail = false;
      // this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      // console.log(this.defaultItem.path_image);
      this.getDetail();
      //   this.dialogDetail = true;
      this.detailshow = true;
      this.detailpagebayar = false;
    },
    detailBayar() {
      this.dialogPilihDetail = false;
      // this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      // console.log(this.defaultItem.path_image);
      this.getDetailPembayaranHutang();
      //   this.dialogDetail = true;
      this.detailshow = true;
      this.detailpagebayar = true;
    },
    backtable() {
      this.detailshow = false;
      this.detailpagebayar = false;
      this.initialize();
    },
    detailInv(val) {
      this.dialogDetailInvoice = true;
      this.getDetailInvoice(val);
      this.code_order_temp = val;
    },
    download() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(str.substring(0, str.length - 4) + "ExportLaporanPiutang");
    },
    updatestatusinvoice(code_inv) {
      this.status_so_temp = "";
      this.code_order_temp = code_inv;
      this.dialogupdatestatus = true;
    },
    addpembayaraninv() {
      this.id_temp = "";
      this.bayar_temp = 0;
      this.datepicker = new Date().toISOString().substr(0, 10);
      this.dialogPembayaran = true;
    },
    editpembayaraninv(id, tanggal, bayar) {
      this.id_temp = id;
      this.bayar_temp = bayar;
      this.datepicker = tanggal;
      this.dialogPembayaran = true;
    },
    hapuspembayaranInv(id) {
      this.id_temp = id;
      this.dialogDelete = true;
    },
    bayarHutang(){
      this.dialogBayar = true;
      this.targetItem.akun_debit = '2-101';
      this.targetItem.akun_kredit = '1-108';
      this.id_bayar_hutang_temp = '';
    },
    closeBayarHutang(){
      this.dialogBayar = false;
    },
    changeBarangSelected(val) {
      console.log(val);
      // this.code_product_temp = val.code_product;
      // this.nama_product_temp = val.nama_product;
      // this.path_image_temp = val.path_image;
      // this.satuan_temp = val.satuan;
      this.dialogAddProduct = true;
    },
    closeDialogBayar(){
      this.dialogBayarHutang= false;
    },
    close() {
      this.dialogupdatestatus = false;
      this.dialogPembayaran = false;
      this.dialogDelete = false;
    },
    saveepembayaran() {
      const datapost = {
        code_order: this.code_order_temp,
        id_pengeluaran: "default",
        nominal: JSON.stringify(this.bayar_temp),
        date: this.datepicker,
        code_admin: this.admin_id,
      };
      //   console.log(datapost);
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);
        datapost.nominal = parseInt(this.bayar_temp);
        if (this.id_temp) {
          datapost.id_pengeluaran = this.id_temp;
          console.log("update");
          this.EditPiutangInvDetail(datapost);
          // this.updateData(datapost);
        } else {
          datapost.id_pengeluaran = "";
          console.log("add");
          this.AddPiutangInvDetail(datapost);
          // this.addData(datapost);
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },
    deletepembayaran() {
      console.log(this.id_temp);
      const datapost = {
        id_pengeluaran: this.id_temp,
        code_order: this.code_order_temp,
        code_admin: this.admin_id,
      };
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);
        this.DeletePiutangInvDetail(datapost);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },
    updatepaidItemConfirm() {
      const datapost = {
        status: this.status_so_temp,
        purchase_no: this.code_order_temp,
        code_admin: this.admin_id,
      };
      console.log(datapost);
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);
        this.UpdateStatusInvDetail(datapost);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    changeSupplierBayar(val){
      console.log(val)
      this.getInvSupplier(val.code_supplier);
      this.targetItem.code_supplier=val.code_supplier;
    },
    changeInvSupplierBayar(val){
      console.log(val);
      this.editedItemBayar.code_order = val.code_order;
      this.editedItemBayar.total = val.total_hutang;
      this.editedItemBayar.sisa = val.sisahutang;
    },
    changecheckboxBayarSemua(val){
      console.log(val);
      if(val == true){
        this.editedItemBayar.nominal = this.editedItemBayar.sisa
      }else{
        this.editedItemBayar.nominal = 0
      }
    },


    saveBayarHutang(){
      const datapost = {
        code_supplier: this.targetItem.code_supplier,
        akun_debit: this.targetItem.akun_debit,
        akun_kredit: this.targetItem.akun_kredit,
        list_bayar: this.itemlistbayar,
        date: this.date,
        code_admin: this.admin_id,
      };
      //   console.log(datapost);
      if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
        console.log(datapost);      
        datapost.list_bayar = JSON.stringify(this.itemlistbayar);
        if (this.id_bayar_hutang_temp) {
          datapost.id_pengeluaran = this.id_temp;
          console.log("update");
          this.EditPiutangInvDetail(datapost);
          // this.updateData(datapost);
        } else {
          datapost.id_pengeluaran = "";
          console.log("add");
          this.AddPembayaranHutang(datapost);
          // this.addData(datapost);
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Data tidak boleh ada yang kosong";
      }
    },

    detailPagePembayaran(val){
      this.dialogMaintenance =true;
    },
    editPagePembayaran(item){
      this.dialogMaintenance =true;
    },
    deletePagePembayaran(item){
      this.dialogMaintenance =true;
    },


    deleteItemBayar(item) {
      this.editedIndexBayar = this.itemlistbayar.indexOf(item);
      this.editedItemBayar = Object.assign({}, item);
      this.dialogDeleteBayar = true;
    },
    deleteItemConfirmBayar() {
      this.itemlistbayar.splice(this.editedIndexBayar, 1);
      this.closeDeleteBayar();
    },
    closeBayar() {
      this.dialogTambahBayar = false;
      this.$nextTick(() => {
        this.editedItemBayar = Object.assign({}, this.defaultItemBayar);
        this.editedIndexBayar = -1;
      });
    },
    closeDeleteBayar() {
      this.dialogDeleteBayar = false;
      this.$nextTick(() => {
        this.editedItemBayar = Object.assign({}, this.defaultItemBayar);
        this.editedIndexBayar = -1;
      });
    },
    saveBayar() {
      // console.log(this.editedItemKas.no_akun);
      // console.log(this.editedItemKas.no_akun);
      if (this.editedIndexBayar > -1) {
        Object.assign(
          this.itemlistbayar[this.editedIndexBayar],
          this.editedItemBayar
        );
      } else {
        // this.editedItemKas.nama_akun = this.editedItemKas.akun.nama_akun;
        // this.editedItemKas.no_akun = this.editedItemKas.akun.no_akun;
        this.itemlistbayar.push(this.editedItemBayar);
        //   console.log(this.datepicker4);
      }
      this.closeBayar();
    },


    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },

    getTanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>




