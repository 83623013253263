<template>
  <v-container class="mt-1" style="max-width:100% !important">

    <v-row style="margin-top: -15px" dense class="ml-1">
      <v-col cols="12" md="8">
        <v-row>
          <v-col class="px-1 pt-2 pb-1 mb-1" cols="12" sm="12" md="12">
            <v-card elevation="9" style="height: 175px;">
              <v-card-title>{{ greeting }}, {{ fullnameadmin }}</v-card-title>
              <v-card-subtitle> Hari ini {{daysShow}} </v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12"  md="1" class="d-none d-md-block">
                    <div>
                    </div>
                  </v-col>
                  <v-col cols="12"  md="7">
                    <div class="mt-6" style="text-align:center">
                      <h5 class="fontall" style="font-size:12px; font-weight:800">
                        "{{note}}"
                      </h5>
                    </div>
                  </v-col>
                  <v-col cols="12"  md="4" class="d-none d-md-block">
                    <v-row align="right" justify="right" style="justify-content: end" class="mb-0">
                      <v-img
                        max-width="200"
                        src="/images/dashboard_logo.jpg"
                        style="margin-top: -50px"
                      ></v-img>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="4" md="4">
          <v-card class="fontall" elevation="9" style="background-color: #ff7e57;height: 175px">          
            <v-card-text class="pa-5">
              <div class="d-flex align-start">
                  <div>
                      <h3 class="title font-weight-height" style="font-weight: 800 !important; color:white">
                        Laba
                      </h3>
                  </div>
                  <div class="flex-grow-1">
                  </div>
                  <div class="ml-auto">
                  <v-btn class="mx-2" fab color="white" >
                      <v-icon style="color:#ff7e57">
                        mdi-currency-usd
                      </v-icon>
                    </v-btn>
                  </div>
              </div>
              <div id="monthly" style="margin-top:15px">
                <h3 style="color:white">
                  Rp. 93.438.000,-
                </h3>
                <h5 style="color:white">
                  Laba Berjalan Bulan Ini
                </h5>
              </div>
            </v-card-text>
            
          </v-card>
        </v-col>
        <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="4" md="4">
          <v-card class="fontall" elevation="9" style="height: 175px">          
            <v-card-text class="pa-5">
              <div class="d-flex align-start">
                  <div>
                    <span class="text-subtitle-1 text-grey-darken-1 font-weight-medium">
                      Total Pembelian
                      </span>
                      <h2 class="title font-weight-height" style="font-weight: 800 !important; font-size:18px !important">
                        Rp. 475.000.000,-
                        </h2>
                  </div>
                  <div class="flex-grow-1">
                  </div>
              </div>
            </v-card-text>
            <div id="chart" style="max-width: 100%">
              <apexchart type="area" height="75" :options="chartOptionsApex1" :series="seriesApex1"></apexchart>
            </div>
          </v-card>
        </v-col>
        <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="4" md="4">
          <v-card class="fontall" elevation="9" style="height: 175px">          
            <v-card-text class="pa-5">
              <div class="d-flex align-start">
                  <div>
                    <span class="text-subtitle-1 text-grey-darken-1 font-weight-medium">
                      Total Penjualan
                      </span>
                      <h2 class="title font-weight-height" style="font-weight: 800 !important; font-size:18px !important">
                        Rp. 585.800.000,-
                        </h2>
                  </div>
                  <div class="flex-grow-1">
                  </div>
              </div>
            </v-card-text>
            <div id="chart" style="max-width: 100%">
              <apexchart type="area" height="75" :options="chartOptionsApex2" :series="seriesApex2"></apexchart>
            </div>
          </v-card>
        </v-col>
        <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="6" md="6">
          <v-card class="fontall pa-md-4" elevation="9" style="width:100%" >
            <div>
              <h5>Data Pembelian & Penjualan</h5>
              <hr style="width:60%">
            </div>
            <div id="chart">
              <apexchart type="bar" height="320" :options="chartOptionsApexBar1" :series="seriesApexBar1"></apexchart>
            </div>
          </v-card>
        </v-col>
        <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="6" md="6">
          <v-card class="fontall pa-md-4" elevation="9" style="width:100%" >
            <div>
              <h5>Data Kas Masuk & Kas Keluar</h5>
              <hr style="width:60%">
            </div>
            <div id="chart">
              <apexchart type="bar" height="320" :options="chartOptionsApexBar2" :series="seriesApexBar2"></apexchart>
            </div>
          </v-card>
        </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="4" class='pl-8 pt-3 pr-3' >
        <v-row>
          <v-card class="fontall pa-md-4" elevation="9" style="width:100%" height="520">
            <div>
              <h5>Data Piutang</h5>
              <hr style="width:60%">
            </div>
            <div>
              <div class="d-flex align-start mt-4">
                  <div class="ml-1">
                    <v-btn class="mx-2" small fab dark color="#6357ff" outlined>
                      <v-icon dark>
                        mdi-vector-arrange-above
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-2">
                    <h5>Piutang Agen</h5>
                    <h4 class="title font-weight-height" style="font-weight: 800 !important;font-size: 17px !important;">
                        Rp. 357.800.000,-
                    </h4>
                  </div>
              </div>
              <div class="d-flex align-start mt-4">
                  <div class="ml-1">
                    <v-btn class="mx-2" small fab dark color="#6357ff" outlined>
                      <v-icon dark>
                        mdi-vector-link
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-2">
                    <h5>Piutang Karyawan</h5>
                    <h4 class="title font-weight-height" style="font-weight: 800 !important;font-size: 17px !important;">
                        Rp.  51.550.000,-
                    </h4>
                  </div>
              </div>
              <div class="d-flex align-start mt-4">
                  <div class="ml-1">
                    <v-btn class="mx-2" small fab dark color="#6357ff" outlined>
                      <v-icon dark>
                        mdi-vector-difference
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-2">
                    <h5>Piutang Lain-lain</h5>
                    <h4 class="title font-weight-height" style="font-weight: 800 !important;font-size: 17px !important;">
                        Rp. 42.700.000,-
                    </h4>
                  </div>
              </div>
            </div>
            <div class="mt-5">
              <h5>Data Hutang</h5>
              <hr style="width:60%">
            </div>
            <div>
              <div class="d-flex align-start mt-4">
                  <div class="ml-1">
                    <v-btn class="mx-2" small fab dark color="#ff7e57" outlined>
                      <v-icon dark>
                        mdi-hexagon-slice-4
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-2">
                    <h5>Hutang Dagang</h5>
                    <h4 class="title font-weight-height" style="font-weight: 800 !important;font-size: 17px !important;">
                        Rp. 253.300.000,-
                    </h4>
                  </div>
              </div>
              <div class="d-flex align-start mt-4">
                  <div class="ml-1">
                    <v-btn class="mx-2" small fab dark color="#ff7e57" outlined>
                      <v-icon dark>
                        mdi-cube-outline
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-2">
                    <h5>Hutang Royalti</h5>
                    <h4 class="title font-weight-height" style="font-weight: 800 !important;font-size: 17px !important;">
                        Rp.  74.550.000,-
                    </h4>
                  </div>
              </div>
              <div class="d-flex align-start mt-4">
                  <div class="ml-1">
                    <v-btn class="mx-2" small fab dark color="#ff7e57" outlined>
                      <v-icon dark>
                        mdi-cube-scan
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="ml-2">
                    <h5>Pendapatan diterima dimuka</h5>
                    <h4 class="title font-weight-height" style="font-weight: 800 !important;font-size: 17px !important;">
                        Rp. 20.500.000,-
                    </h4>
                  </div>
              </div>
            </div>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <!-- <v-row dense class="mt-3">
      <v-col class="px-1 pt-2 pb-1 mb-3" cols="12" sm="4" md="4">
        <v-card class="fontall pa-md-4" elevation="5">          
          <v-data-table
            :headers="headers"
            :items="profils"
            :items-per-page="3"
            hide-items-per-page
          >
            <template v-slot:item.totaluang="{ item }">
              {{ getRupiah(item.totaluang) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col class="px-1 py-1 mt-1" cols="12" sm="4" md="4">
        <v-card class="d-none d-md-block" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="ColumnChart"
              :data="chartDataColom"
              :options="colomchartOptionsGoogle"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan3bulandetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <v-card class="d-sm-block d-md-none" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="ColumnChart"
              :data="chartDataColom"
              :options="colomchartOptionsGoogleMobile"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualan3bulandetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="px-1 py-1 mt-1" cols="12" sm="4" md="4">
        <v-card class="d-none d-md-block" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="PieChart"
              :data="chartData"
              :options="chartOptionsGoogle"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualanSalesDetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <v-card class="d-sm-block d-md-none" elevation="5">
          <v-card-text class="py-1 px-1">
            <GChart
              type="PieChart"
              :data="chartData"
              :options="chartOptionsGoogleMobile"
            />
          </v-card-text>
          <v-card-text class="py-0 px-1">
            <div class="text-h6 font-weight-light mb-2">
              <v-btn
                @click="showPenjualanSalesDetail()"
                block
                text
                small
                class="grey--text"
                >View Detail</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->

    <v-dialog v-model="dialogDetailSale" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan 3 Bulan</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpenjualan()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <v-data-table
              :headers="headersPenjualan"
              :items="itemssale"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.totaluanginv="{ item }">
                {{ getRupiah(item.totaluanginv) }}
              </template>
              <template v-slot:item.totaluang="{ item }">
                {{ getRupiah(item.totaluang) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDetailSales" max-width="800px">
      <v-card>
        <v-card-title class="mb-1 headermodalstyle">
          <span>Detail Penjualan Sales</span>
        </v-card-title>
        <div style="text-align: center">
          <v-btn
            color="black"
            elevation="1"
            outlined
            @click="downloadpenjualansales()"
          >
            <v-icon left> mdi-download-circle-outline </v-icon>
            Download
          </v-btn>
        </div>
        <v-divider class="mx-4 mt-3"></v-divider>
        <v-card-text class="fontall pa-1">
          <v-container class="pa-1">
            <div style="text-align: center">
              <h4>
                Data Penjualan {{ from_date_sales }} - {{ to_date_sales }}
              </h4>
            </div>
            <v-divider class="mx-2 mt-3"></v-divider>
            <v-data-table
              :headers="headersPenjualanSales"
              :items="itemssales"
              class="rounded elevation-1 mx-1 pa-1 my-2"
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.totaluangsales="{ item }">
                {{ getRupiah(item.totaluangsales) }}
              </template>
              <template v-slot:item.totaluang="{ item }">
                {{ getRupiah(item.totaluang) }}
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

import VueApexCharts from "vue-apexcharts";

import HelperGlobal from "../services/Helper";
const HelperGlobalService = new HelperGlobal();

const gradients = [
  ["#222"],
  ["#42b3f4"],
  ["red", "orange", "black"],
  ["purple", "violet"],
  ["#FF0", "#F0F", "#00c6ff"],
  ["#1feaea", "#ffd200", "#f72047"],
];

export default {
  name: "Dashboard",

  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    greeting: "Morning",
    date: "01 December 2021",
    day: "Sunday",

    drawer: null,
    BaseUrlGet: "",
    authtoken: "",

    dialogDetailSales: false,
    dialogDetailSale: false,

    itemssale: [],
    itemssales: [],
    from_date_sales: "",
    to_date_sales: "",

    seriesApex1: [
      {
        data: [80, 108, 90, 80, 85]
      }, 
    ],
    chartOptionsApex1: {
      colors : ['#5790ff'],
        chart: {
              height: 75,
              type: 'area',
              zoom: {
                enabled: false
              },
               toolbar: {
                show: false
              },              
              sparkline: {
                enabled: true
              }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
    },

    seriesApex2: [
      {
        data: [80, 108, 92, 109, 130]
      }, 
    ],
    chartOptionsApex2: {
      colors : ['#57ff98'],
        chart: {
              height: 75,
              type: 'area',
              zoom: {
                enabled: false
              },
               toolbar: {
                show: false
              },              
              sparkline: {
                enabled: true
              }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
    },

    seriesApexBar1: [
      {
            name: 'Pembelian',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
            name: 'Penjualan',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      },
    ],
    seriesApexBar2: [
      {
            name: 'Kas Masuk',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
      }, {
            name: 'Kas Keluar',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
      },
    ],
          chartOptionsApexBar1: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [ 'Jul', 'Aug', 'Sep', 'Oct', 'Nop', 'Dec'],
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }
          },  
          chartOptionsApexBar2: {
            colors : ['#ff7e57','#9dff5f'],
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [ 'Jul', 'Aug', 'Sep', 'Oct', 'Nop', 'Dec'],
            },
            yaxis: {
              title: {
                text: '$ (thousands)'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }
          },  

    headersPenjualan: [
      { text: "Bulan", value: "bulan" },
      { text: "SO Terbuat", value: "totalso" },
      { text: "Invoice Terbuat", value: "totalinv" },
      { text: "Barang Terjual", value: "totalbarang" },
      { text: "Total Uang Invoice", value: "totaluanginv" },
      { text: "Uang Terbayar", value: "totaluang" },
    ],
    headersPenjualanSales: [
      { text: "Sales", value: "nama" },
      { text: "SO Terbuat", value: "totalso" },
      { text: "Invoice Terbuat", value: "totalinv" },
      { text: "Barang Terjual", value: "totalbarang" },
      { text: "Total Uang Invoice", value: "totaluangsales" },
      { text: "Uang Terbayar", value: "totaluang" },
    ],

    headers: [
      {
        text: "Customer",
        align: "start",
        sortable: false,
        value: "nama_customer",
      },
      { text: "Total Beli", value: "totaluang" },
    ],
    profils: [
      {
        code_customer: "Admin1",
        nama_customer: "admin1@mail.com",
        total: "1",
        totalbarang: "1",
      },
      {
        code_customer: "Admin2",
        nama_customer: "admin2@mail.com",
        total: "1",
        totalbarang: "1",
      },
      {
        code_customer: "Cust1",
        nama_customer: "cust1@mail.com",
        total: "1",
        totalbarang: "1",
      },
    ],
    defaultItem: {
      code: "",
      nama: "",
      email: "",
      status: "",
      status_name: "",
    },

    data1: {
      Judul: "Total Uang Masuk",
      Count: "1",
      link: "/",
    },
    data2: {
      Judul: "Total Uang Keluar",
      Count: "11",
      link: "/",
    },
    data3: {
      Judul: "Total Customer",
      Count: "111",
      link: "/",
    },

    gradientDirection: "top",
    gradients,
    gradient: gradients[10],
    labelsSparkline: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "August",
      "Sept",
      "Okt",
      "Nop",
      "Dec",
    ],
    valueSparkline: [350, 200, 410, 390, 310, 460, 250, 240, 0, 0, 0, 0],
    srcimage: "https://cdn.vuetifyjs.com/images/cards/foster.jpg",
    datacollection: null,
    namaAdmin: "Nama Admin",

    chartDataColom: [
      ["Tempat", "Total"],
      ["Lokasi1", 1000],
      ["Lokasi2", 1170],
      ["Lokasi3", 760],
    ],
    chartData: [
      ["Tempat", "Total"],
      ["Lokasi1", 1000],
      ["Lokasi2", 1170],
      ["Lokasi3", 760],
      ["Lokasi4", 1030],
      ["Lokasi5", 600],
    ],
    colomchartOptionsGoogle: {
      title: "Penjualan 3 Bulan",
      // pieHole: 0.4,
      // is3D: true,
      legend: "none",
      pieSliceText: "label",
      width: $(window).width() * 0.25,
      height: $(window).height() * 0.45,
    },
    colomchartOptionsGoogleMobile: {
      title: "Penjualan 3 Bulan ",
      // pieHole: 0.4,
      // is3D: true,
      legend: "none",
      pieSliceText: "label",
    },
    chartOptionsGoogle: {
      title: "Penjualan Sales Bulan ini",
      pieHole: 0.4,
      // is3D: true,
      // legend: "none",
      pieSliceText: "label",
      legend: { position: "bottom", alignment: "start" },
      width: $(window).width() * 0.25,
      height: $(window).height() * 0.45,
    },
    chartOptionsGoogleMobile: {
      title: "Penjualan Sales Bulan ini",
      pieHole: 0.4,
      // is3D: true,
      // legend: "none",
      pieSliceText: "label",
      legend: { position: "bottom", alignment: "start" },
    },

    fullnameadmin: "",
    nameadmin: "",

    note:"Sebaik-baik pekerjaan ialah usahanya seseorang pekerja apabila ia berbuat sebaik-baiknya",
    daysShow:'',
  }),
  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      var name = this.User.name;
      this.fullnameadmin = name;
      const arrayname = name.split(" ");
      this.nameadmin = arrayname[0];
    }
    this.getDateNow();
    // this.initialize();
  },
  methods: {
    async initialize() {
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "Dashboard", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.greeting = response.data.data.result.greeting;
          this.day = response.data.data.result.day;
          this.date = response.data.data.result.dateformat;
          this.data1.Count = response.data.data.result.Getpemasukantotal;
          this.data2.Count = response.data.data.result.Getpengeluarantotal;
          this.data3.Count = response.data.data.result.Gettotalcust;
          this.chartDataColom = response.data.data.result.listarrayinvtotal;
          this.chartData = response.data.data.result.listarraysales;
          this.profils = response.data.data.result.listvalcust;
        } else {
          console.log("Kosong");
          this.loadtable = false;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },

    async getdetailsale() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DashboardDetailSale",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemssale = response.data.data.result;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    async getdetailsales() {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "DashboardDetailSales",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemssales = response.data.data.result.list;
          this.from_date_sales = response.data.data.result.from_date;
          this.to_date_sales = response.data.data.result.to_date;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },

    getDateNow(){
      const greetingdata = ["Selamat Pagi", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
      const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
      const months = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "Desember"];

      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      let month = months[today.getMonth()];
      var yyyy = today.getFullYear();
      let day = days[today.getDay()];
      var jam = today.getHours();

      if(jam < 3){
        this.greeting = 'Selamat Malam';
      }
      if(jam > 3 && jam <= 11){
        this.greeting = 'Selamat Pagi';
      }
      if(jam > 11 && jam <= 15){
        this.greeting = 'Selamat Siang';
      }
      if(jam > 15 && jam < 18){
        this.greeting = 'Selamat Sore';
      }
      if(jam > 18){
        this.greeting = 'Selamat Malam';
      }

      this.daysShow = day+', '+dd+' '+month+' '+yyyy;
    },

    showPenjualan3bulandetail() {
      this.getdetailsale();
      this.dialogDetailSale = true;
    },
    showPenjualanSalesDetail() {
      this.getdetailsales();
      this.dialogDetailSales = true;
    },

    downloadpenjualan() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) + "DashboardDownloadDetailSale"
      );
    },
    downloadpenjualansales() {
      this.dialogDetailSales = false;
      var str = this.BaseUrlGet;
      window.open(
        str.substring(0, str.length - 4) + "DashboardDownloadDetailSales"
      );
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
  },
};
</script>

