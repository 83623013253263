<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <!-- <template v-slot:item.jenis_reseller="{ item }">
        <v-chip :color="getColorStatus(item.jenis_reseller)" dark>
          {{ getStatus(item.jenis_reseller) }}
        </v-chip>
      </template> -->

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.id_admin"
                        :items="itemsAdmin"
                        item-value="id"
                        item-text="nama"
                        label="Pilih Nama Admin"
                        clearable
                        outlined
                        :disabled="disablevalue"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.role"
                        :items="itemsAdminRole"
                        item-value="value"
                        item-text="text"
                        label="Pilih Login Admin"
                        clearable
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.username"
                        label="Username"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="defaultItem.password"
                        label="Password"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Add Edit -->
          <v-dialog v-model="dialogmenu" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                Edit Menu Access
              </v-card-title>
              <!-- <v-divider></v-divider> -->
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <div>
                        <h5>Role</h5>
                        <h3 class="ml-2">{{ defaultItem.job_position }}</h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <div>
                        <h5>Nama</h5>
                        <h3 class="ml-2">{{ defaultItem.nama }}</h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="menudropdown"
                        :items="itemsMenu"
                        item-value="id"
                        item-text="name"
                        label="Pilih Tambah Menu Access"
                        outlined
                        v-on:change="selectedMenuAccess($event)"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        :headers="headersmenu"
                        :items="defaultItem.MenuTable"
                        class="elevation-1"
                        append-icon="mdi-magnify"
                        :items-per-page="5"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            class="mr-2"
                            @click="deletelistmenu(item)"
                            color="red"
                          >
                            mdi-delete
                          </v-icon>
                          <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="savemenu">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil
        </v-icon>
        <v-icon class="mr-1" @click="editMenuItem(item)" color="blue">
          mdi-microsoft-xbox-controller-menu
        </v-icon>
        <!-- <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminLogin",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogmenu: false,
    disablevalue: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Cust",
        value: "id",
      },
      { text: "Nama", value: "nama", width: "25%" },
      { text: "username", value: "username" },
      { text: "Admin Web", value: "job_position" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersmenu: [
      { text: "Menu Code", value: "MenuCode" },
      { text: "Nama Menu", value: "MenuName" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    dataobject: [],
    itemsAdmin: [],
    itemsMenu: [],
    menudropdown: "",

    editedIndex: -1,
    editedItem: {
      MenuCode: "",
      MenuName: "",
    },

    itemsAdminRole: [
      { text: "Admin_Store", value: "1" },
      { text: "Admin_Bosman", value: "2" },
      { text: "Admin_Acounting", value: "3" },
    ],

    defaultItem: {
      id_admin: "",
      role: "",
      username: "",
      password: "",
      MenuTable: "",
    },

    diskonReset: "",
    jenisResellerReset: "",
    kodeAdminTrx: "",

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdminTrx = this.User.kodeAdminTrx;
    }
    this.initialize();
    this.getAdmin();
    this.GetMenu();
  },

  methods: {
    async initialize() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllAdminLogin", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataobject = [];
        }
      }
    },
    async getAdmin() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllAdmin", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAdmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAdmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAdmin = [];
        }
      }
    },
    async GetMenu() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAllMenuAccess", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.itemsMenu = response.data.data.result;
        } else {
          console.log("Kosong");
          this.itemsMenu = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.alerttoken = true;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsMenu = [];
        }
      }
    },
    async addData() {
      const datapost = {
        id_admin: this.defaultItem.id_admin,
        role: this.defaultItem.role,
        username: this.defaultItem.username,
        password: this.defaultItem.password,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddAdminLogin",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        id: this.defaultItem.id,
        id_admin: this.defaultItem.id_admin,
        role: this.defaultItem.role,
        username: this.defaultItem.username,
        password: this.defaultItem.password,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditAdminLogin",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async UpdateMenu(datapost) {
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditAdminMenuAccess",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogmenu = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogmenu = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      this.defaultItem.id = "";
      this.defaultItem.id_admin = "";
      this.defaultItem.role = "";
      this.defaultItem.username = "";
      this.defaultItem.password = "";
      this.disablevalue = false;
      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.disablevalue = true;
      this.value = "edit";
      this.dialog = true;
    },
    editMenuItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.disablevalue = true;
      this.value = "edit";
      this.dialogmenu = true;
    },
    close() {
      this.dialog = false;
      this.dialogmenu = false;
    },

    save() {
      if (
        this.defaultItem.username.length != 0 &&
        this.defaultItem.id_admin != null &&
        this.defaultItem.role != null &&
        this.defaultItem.password.length != 0
      ) {
        if (this.defaultItem.id) {
          console.log("edit");
          this.updateData();
        } else {
          console.log("add");
          this.addData();
        }
        this.close();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },
    savemenu() {
      var listmenupost = "";
      var listmenutemp = "";
      for (let n = 0; n < this.defaultItem.MenuTable.length; n++) {
        if (n == 0) {
          listmenutemp = this.defaultItem.MenuTable[n].MenuCode;
        } else {
          listmenutemp =
            listmenutemp + "," + this.defaultItem.MenuTable[n].MenuCode;
        }
      }
      listmenupost = "[" + listmenutemp + "]";
      console.log(listmenupost);
      const datapost = {
        id_admin: this.defaultItem.id_admin,
        list_menu: listmenupost,
        MenuTable: this.defaultItem.MenuTable,
      };
      console.log(datapost);
      // if (this.statusAdd == false) {
      this.UpdateMenu(datapost);
      // } else {
      //   // this.addData();
      // }
    },

    selectedMenuAccess(a) {
      if (a != null) {
        var listmenu = this.defaultItem.MenuTable;
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].MenuCode == a) {
            result = true;
            break;
          }
        }
        if (result == false) {
          var itemsmenuset = {
            MenuCode: "",
            MenuName: "",
          };
          for (let i = 0; i < this.itemsMenu.length; i++) {
            if (this.itemsMenu[i].id == a) {
              // console.log(this.itemsMenu[i]);
              itemsmenuset.MenuCode = this.itemsMenu[i].id;
              itemsmenuset.MenuName = this.itemsMenu[i].name;
              // console.log(this.editedItem);
              break;
            }
          }
          this.defaultItem.MenuTable.push(itemsmenuset);
          // console.log(this.defaultItem.MenuTable);
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Tambah Menu Access";
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Menu Sudah Ada Bro..!!!";
        }
        // this.menudropdown.id = '';
      }
    },
    async deletelistmenu(item) {
      console.log(item);
      this.editedIndex = await this.defaultItem.MenuTable.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.MenuTable.splice(this.editedIndex, 1);
    },
  },
};
</script>



