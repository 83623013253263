<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.jumlah_uang="{ item }">
        {{ getRupiah(item.jumlah_uang) }}
      </template>

      <template v-slot:item.date="{ item }">
        {{ getTanggal(item.date) }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ getTanggal(item.date) }}
      </template>

      <template v-slot:item.payment_status="{ item }">
        <v-chip
          outlined
          :color="getColorStatusKonfirmasiAdmin(item.payment_status)"
          dark
        >
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusKonfirmasiAdmin(item.payment_status)"
          >
            {{ getIconStatusAdmin(item.payment_status) }}
          </v-icon>
          {{ item.payment_status }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <!-- Modal Add/ Edit -->
          <v-dialog v-model="dialog" max-width="700px">
            <v-card>
              <v-card-title class="mb-1 headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header>
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Main Data
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable :complete="e1 > 2" step="2">
                      List Barang
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable :complete="e1 > 3" step="3">
                      Bukti Pembelian
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step @click="konfirmasiAdd" editable step="4">
                      Konfirmasi
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Supllier</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.supplier"
                              :items="itemssupplier"
                              item-value="code_supplier"
                              item-text="nama_supplier"
                              label="Pilih Supllier"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Pilih Tanggal Pembelian</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="pb-0" cols="12" sm="6" md="6">
                            <v-menu
                              v-model="menu2"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="datepicker"
                                  slot="activator"
                                  label="Tanggal"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Field is required']"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="datepicker"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 2"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="2">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Pilih Barang</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              :items="itemsbarang"
                              item-value="code_product"
                              item-text="nama_product"
                              label="Pilih Barang"
                              v-on:change="changeBarangSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>List Barang Pembelian</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListProduct"
                              :items="defaultItem.list_order"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitemproduct(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Diskon dan Ongkir</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="6" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.diskon"
                              label="Diskon"
                              required
                              type="number"
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col class="px-1 py-0" cols="6" sm="6" md="6">
                            <v-text-field
                              v-model="defaultItem.ongkir"
                              label="Ongkir"
                              required
                              type="number"
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="nextListBarang"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                    
                    <v-stepper-content class="pa-3" step="3">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Keterangan Pembelian</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="12" sm="12" md="12" class="pa-1">
                            <v-text-field
                              v-model="defaultItem.deskripsi"
                              label="Keterangan"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mb-3">
                          <h4>Upload Bukti Pembelian</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="12" sm="6" md="6" class="pa-1">
                            <v-file-input
                              accept="image/png, image/jpeg, image/bmp"
                              placeholder="Pilih Foto"
                              prepend-icon="mdi-camera"
                              show-size
                              label="Pilih Foto"
                              outlined
                              v-model="gambarinput"
                              v-on:change="pilihgambar"
                            ></v-file-input>
                          </v-col>

                          <v-container>
                            <v-row justify="center">
                              <div v-if="selectedImage" max-width="85vw">
                                <img
                                  :src="selectedImage"
                                  alt=""
                                  width="100%"
                                  @click.stop="selectedImage = null"
                                />
                                <hr />
                              </div>
                              <v-col
                                v-if="selectedImage == null"
                                cols="12"
                                md="12"
                              >
                                <v-card
                                  class="d-flex align-center"
                                  elevation="2"
                                  height="450"
                                  width="450"
                                >
                                  <v-img
                                    v-bind:src="gambarshow"
                                    class="my-2 mx-1 mb-4"
                                    max-height="450"
                                    contain
                                    v-on:click="zoom(gambarshow)"
                                  ></v-img>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-row>
                      </v-container>

                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 4"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="4">
                      <v-container class="mb-2">
                        <v-row class="mb-3">
                          <h4>Konfirmasi Akun Neraca</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.akun_debit"
                              :items="itemsAkun"
                              label="Pilih Akun Debit"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.akun_kredit"
                              :items="itemsAkun"
                              label="Pilih Akun Kredit"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <!-- <v-row class="mb-3">
                          <h4>Konfirmasi Akun Laba Rugi</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row class="mb-3">
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.akun_laba_rugi"
                              :items="itemsAkun"
                              label="Pilih Akun"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                        </v-row> -->
                      </v-container>
                      <v-row class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="savepembelian('create')"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Modal Add Product -->
          <v-dialog v-model="dialogAddProduct" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Product</h5>
                        <h3 class="ml-2">
                          {{ nama_product_temp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Satuan</h5>
                        <h3 class="ml-2">
                          {{ satuan_temp }}
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="harga_temp"
                        label="Harga Satuan Beli"
                        required
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="pb-1" cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="jumlah_temp"
                        label="Jumlah"
                        outlined
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeModalAdd">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="addProduct"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEditShow" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >What you want to edit?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  elevation="1"
                  outlined
                  @click="editpurchase"
                >
                  <v-icon left> mdi-pencil-circle-outline </v-icon> Edit
                  Purchase</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="editstatus"
                >
                  <v-icon left> mdi-pencil-circle-outline </v-icon>Edit
                  Status</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEditPurchase" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="mb-0 headermodalstyle"
                >Update Status Purchase?</v-card-title
              >
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.payment_status"
                        :items="itemsStatusPurchase"
                        item-value="value"
                        item-text="text"
                        label="Pilih Status"
                        outlined
                        v-on:change="changeorderstatus"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <v-col
                      v-if="status_show_bayar == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <h4 class="ml-2" style="color: red">
                        Note: Merubah status menjadi
                        <span style="text-decoration: underline">"Paid"</span>
                        dan menambahkan pengeluaran sesuai nominal serta
                        melunasi hutang
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="saveeditstatus"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Save</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="savepembelian('delete')"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle mb-0">
                <span>Detail Pembelian</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container
                  v-if="loaddetail == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>
                <v-container v-if="loaddetail == false">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Supplier
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.nama_supplier }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Tanggal Pembelian
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getTanggal(defaultItem.date) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Keterangan
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.deskripsi }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            style="width: 250px !important; font-size: 13px"
                          >
                            Nama Product
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Harga
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Jumlah
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            satuan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="val in defaultItem.list_order"
                          :key="val.code_product"
                        >
                          <td
                            class="text-left"
                            style="
                              padding-left: 15px !important;
                              font-size: 13px;
                            "
                          >
                            {{ val.namabarang }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getRupiah(val.harga) }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.jumlah }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.satuan }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!-- <v-data-table
                    :headers="headerListProductDetail"
                    :items="defaultItem.list_order"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.subtotal="{ item }">
                      {{ getRupiah(item.subtotal) }}
                    </template>
                  </v-data-table> -->
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            SubTotal
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.jumlah_uang) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Total Diskon
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.diskon_order) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Total Ongkir
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.jumlah_ongkir) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Total Harga
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.total_harga) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>

              <v-container v-if="loaddetail == false">
                <v-row justify="center">
                  <div v-if="selectedImage" max-width="85vw">
                    <img
                      :src="selectedImage"
                      alt=""
                      width="100%"
                      @click.stop="selectedImage = null"
                    />
                    <hr />
                  </div>
                  <v-col v-if="selectedImage == null" cols="12" md="12">
                    <v-card
                      class="d-flex align-center"
                      elevation="2"
                      height="450"
                      width="450"
                    >
                      <v-img
                        v-bind:src="gambarshow"
                        class="my-2 mx-1 mb-4"
                        max-height="450"
                        contain
                        v-on:click="zoom(gambarshow)"
                      ></v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              <v-divider class="mx-4"></v-divider>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "PurchaseOrder",
  data: () => ({
    formTitle: "Add Item",
    value: "add",
    valid: true,
    loadtable: false,
    loaddetail: false,
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    dialogAddProduct: false,
    dialogEditShow: false,
    dialogEditPurchase: false,
    search: "",
    authtoken: "",
    BaseUrl: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrlDBImage: "",
    headers: [
      { text: "Purchase No", value: "purchase_no" },
      { text: "Supplier", value: "nama_supplier" },
      { text: "Nominal", value: "jumlah_uang" },
      { text: "Status", value: "payment_status" },
      { text: "Dibuat", value: "nama_admin" },
      { text: "Tanggal Beli", value: "date", width: "15%" },
      { text: "Actions", value: "actions", sortable: false, width: "12%" },
    ],

    headerListProduct: [
      { text: "Nama Product", value: "namabarang", width: "35%" },
      { text: "Satuan", value: "satuan", width: "15%" },
      { text: "Harga", value: "harga", width: "20%" },
      { text: "Jumlah", value: "jumlah", width: "15%" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headerListProductDetail: [
      { text: "Nama Product", value: "namabarang", width: "35%" },
      { text: "Satuan", value: "satuan", width: "15%" },
      { text: "Harga", value: "harga", width: "20%" },
      { text: "Jumlah", value: "jumlah", width: "15%" },
    ],
    dataobject: [],
    itemsCategory: [],

    defaultItem: {
      id: "",
      purchase_no: "",
      deskripsi: "",
      jumlah_uang: 0,
      total_harga:0,
      diskon_order: "",
      status_purchase: "",
      code_supplier: "",
      supplier: "",
      code_admin: "",
      nama_admin: "",
      date: "",
      created_at: "",
      path_photo: "",
      list_order: [],
      supplier: "",
      payment_status: "",
      akun_debit: "",
      akun_kredit: "",
      akun_laba_rugi: "",
      jumlah_debit:0,
      jumlah_kredit:0,
      diskon :0,
      ongkir :0,
    },

    discTemp:0,
    ongkirTemp:0,

    itemssupplier: [],
    itemsbarang: [],
    itemsStatusPurchase: [
      // { text: "Unpaid", value: "unpaid" },
      // { text: "Partial", value: "partial" },
      { text: "Paid", value: "paid" },
    ],

    
    itemsAkun: [],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    status_show_bayar: false,

    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",
    gambarshow: "/images/noimage.png",
    fileUpload: "",
    fileUploadReady: false,
    gambarinput: "",
    selectedImage: null,
    resultUpload: false,
    e1: 1,
    stepperdone: false,

    code_product_temp: "",
    nama_product_temp: "",
    path_image_temp: "",
    satuan_temp: "",
    jumlah_temp: 0,
    harga_temp: 0,

    bayar_temp: 0,

    admin_id: "",
    rolesales: false,
    rolefinance: false,
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
      this.checkrole(this.User.role_code);
    }
    this.initialize();
    this.getAkun();
    this.getsupplier();
    this.getproduct();
  },

  methods: {
    checkrole(value) {
      if (value == 1) {
        this.rolesales = true;
        this.rolefinance = false;
      } else if (value == 2) {
        this.rolesales = false;
        this.rolefinance = true;
      } else if (value == 3) {
        this.rolesales = false;
        this.rolefinance = false;
      } else {
        this.rolesales = true;
        this.rolefinance = true;
      }
    },
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetPurchase", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async getsupplier() {
      // this.dataobject = [];
      // this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetSupplier", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          // this.loadtable = false;
          this.itemssupplier = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.itemssupplier = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          // this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.loadtable = false;
          this.itemssupplier = [];
        }
      }
    },
    async getproduct() {
      // this.dataobject = [];
      // this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          // this.loadtable = false;
          this.itemsbarang = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.itemsbarang = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          // this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.loadtable = false;
          this.itemsbarang = [];
        }
      }
    },
    async getAkun() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAkun = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAkun = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAkun = [];
        }
      }
    },
    async getPurchaseDetail() {
      try {
        this.loaddetail = true;
        const response = await axios.get(
          this.BaseUrlGet +
            "GetPurchaseDetail?purchase_no=" +
            this.defaultItem.purchase_no,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.defaultItem.ongkir = response.data.data.result.jumlah_ongkir;
          this.defaultItem.diskon = response.data.data.result.diskon_order;
          this.defaultItem.akun_debit = { nama_akun: "Persediaan Buku Solo", no_akun: "1-130" }
          this.defaultItem.akun_kredit = { nama_akun: "Hutang Dagang", no_akun: "2-101" } 
          this.defaultItem.akun_laba_rugi = { nama_akun: "Biaya Cetak", no_akun: "5-102" } 

          this.datepicker = response.data.data.result.date;
          this.gambarshow = this.BaseUrl + response.data.data.result.path_photo;

          this.defaultItem.supplier = {
            code_supplier: response.data.data.result.code_supplier,
            nama_supplier: response.data.data.result.supplier,
          };
          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async verifDelete(datapost) {
      // const datapost = {
      //   purchase_no: this.defaultItem.purchase_no,
      //   code_admin: this.admin_id,
      // };
      // console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeletePurchase",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData(datapost) {
      // const datapost = {
      //   code_supplier: this.defaultItem.supplier.code_supplier,
      //   deskripsi: this.defaultItem.deskripsi,
      //   diskon_order: 0,
      //   total_order: this.defaultItem.jumlah_uang,
      //   status_purchase: "regular",
      //   date: this.datepicker,
      //   code_admin: this.admin_id,
      //   path_photo: this.defaultItem.path_photo,
      //   list_order: JSON.stringify(this.defaultItem.list_order),
      // };
      console.log("apiadd");
      // console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddUpdatePurchase",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal tambah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal tambah data";
        }
      }
    },
    async editData(datapost) {
      // const datapost = {
      //   purchase_no: this.defaultItem.purchase_no,
      //   code_supplier: this.defaultItem.supplier.code_supplier,
      //   deskripsi: this.defaultItem.deskripsi,
      //   diskon_order: 0,
      //   total_order: this.defaultItem.jumlah_uang,
      //   status_purchase: "regular",
      //   date: this.datepicker,
      //   code_admin: this.admin_id,
      //   path_photo: this.defaultItem.path_photo,
      //   list_order: JSON.stringify(this.defaultItem.list_order),
      // };
      console.log("apiupdate");
      // console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddUpdatePurchase",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateStatusPurchase() {
      const datapost = {
        purchase_no: this.defaultItem.purchase_no,
        payment_status: this.defaultItem.payment_status,
        total_bayar: this.bayar_temp,
        code_admin_update: this.admin_id,
      };
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditPurchaseStatus",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogEditPurchase = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async UploadData() {
      console.log(this.BaseUrlUpload);
      var old_file = "";
      if (this.defaultItem.path_image) {
        old_file = this.defaultItem.path_image;
      }
      if (this.fileUploadReady == true) {
        var dateConvert = Date.now();

        const formData = new FormData();
        formData.append("files", this.fileUpload);
        var namafile = "PRDKSR_" + dateConvert;

        // console.log(namafile);
        try {
          const response = await axios.post(
            this.BaseUrlUpload + "?nama=" + namafile + "&old_file=" + old_file,
            formData,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response);
          if (response.data.success == true) {
            this.resultUpload = true;
            this.defaultItem.path_photo = response.data.TempName;
          } else {
            this.resultUpload = false;
            // this.dialog = false;
          }
        } catch (error) {
          console.error(error.response);
          if (error.response.status == 401) {
            this.resultUpload = false;
            localStorage.removeItem("token");
            this.$router.push("/");
            // this.dialog = false;
          } else {
            this.resultUpload = false;
          }
        }
      } else {
        this.defaultItem.path_photo = "-";
        this.resultUpload = true;
      }
    },

    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      // console.log(this.defaultItem.path_image);
      this.getPurchaseDetail();
      this.dialogDetail = true;
    },
    reset() {
      this.defaultItem.id = "";
      this.defaultItem.purchase_no = "";
      this.defaultItem.deskripsi = "";
      this.defaultItem.jumlah_uang = "";
      this.defaultItem.code_supplier = "";
      this.defaultItem.supplier = "";
      this.defaultItem.list_order = [];
      this.defaultItem.date = "";
      this.defaultItem.created_by = "";
      this.defaultItem.created_at = "";
      this.defaultItem.path_photo = "";
      this.defaultItem.image_show = this.BaseUrl + "images/noimage.png";
      this.defaultItem.show_store_product = "";
      this.fileUpload = "";
      this.fileUploadReady = false;
      this.gambarinput = "";
      this.selectedImage = null;
      this.resultUpload = false;
      this.e1 = 1;
    },
    showAddModal() {
      if (this.rolesales == true) {
        this.value = "add";
        this.formTitle = "Add Item";
        this.reset();
        this.gambarshow = this.BaseUrl + "images/noimage.png";
        this.dialog = true;
        this.defaultItem.akun_debit = { nama_akun: "Persediaan Buku Solo", no_akun: "1-130" }
        this.defaultItem.akun_kredit = { nama_akun: "Hutang Dagang", no_akun: "2-101" } 
        this.defaultItem.akun_laba_rugi = { nama_akun: "Biaya Cetak", no_akun: "5-102" } 
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "yang bisa menambah data hanya admin sales";
      }
    },
    editItem(item) {
      this.reset();
      console.log(item);
      this.formTitle = "Edit Item";
      this.datepicker = item.date;
      this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      this.gambarshow = this.BaseUrl + item.path_photo;
      this.defaultItem.show_store_product = parseInt(item.show_store_product);
      this.getPurchaseDetail();
      this.value = "edit";
      this.dialogEditShow = true;
    },
    editstatus() {
      if (this.rolefinance == true) {
        if (this.defaultItem.payment_status == "unpaid") {
          this.defaultItem.payment_status = "-";
          this.dialogEditShow = false;
          this.dialogEditPurchase = true;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.timeoutsnackbar = 4000;
          this.textsnackbar =
            "Status Partial hanya bisa di edit di manajemen hutang, dan Paid tidak bisa di edit";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "yang bisa edit status paid/unpaid hanya admin finance";
      }
    },
    editpurchase() {
      // console.log(item);
      // this.formTitle = "Edit Item";
      // this.defaultItem = Object.assign({}, item);
      // this.getDetail("edit");
      // this.value = "edit";
      if (this.rolesales == true) {
        this.dialogEditShow = false;
        this.e1 = 1;
        this.dialog = true;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "yang bisa edit data hanya admin sales";
      }
    },
    deleteItem(item) {
      if (this.rolesales == true) {
        this.defaultItem = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "yang bisa hapus data hanya admin sales";
      }
    },
    close() {
      this.dialog = false;
      // this.dialogAddProduct = false;
      this.dialogDelete = false;
      this.dialogEditShow = false;
      this.dialogEditPurchase = false;
    },
    closeModalAdd(){
      this.dialogAddProduct = false;
    },

    changeBarangSelected(val) {
      console.log(val);
      this.code_product_temp = val.code_product;
      this.nama_product_temp = val.nama_product;
      this.path_image_temp = val.path_image;
      this.satuan_temp = val.satuan;
      this.dialogAddProduct = true;
    },

    changeorderstatus(val) {
      console.log(val);
      if (val == "partial") {
        this.status_show_bayar = true;
      } else {
        this.status_show_bayar = true;
      }
    },

    saveeditstatus() {
      if (this.rolefinance == true) {
        this.updateStatusPurchase();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "yang bisa edit status paid/unpaid hanya admin finance";
      }
    },

    addProduct() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlah_temp > 0) {
        var listmenu = this.defaultItem.list_order;
        console.log(listmenu);
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          // if (listmenu[n].kodebarang == this.code_product_temp) {
          //   result = true;
          //   console.log(result);
          //   break;
          // }
        }

        console.log(result);
        if (result == false) {
          var productset = {
            kodebarang: "",
            namabarang: "",
            diskon: 0,
            jumlah: "",
            typediskon: "normal",
            harga: "",
            deskripsi: "-",
            satuan: "",
          };

          productset.kodebarang = this.code_product_temp;
          productset.namabarang = this.nama_product_temp;
          productset.harga = this.harga_temp;
          productset.jumlah = this.jumlah_temp;
          productset.satuan = this.satuan_temp;

          this.defaultItem.jumlah_uang =
            Number(this.defaultItem.jumlah_uang) +
            Number(this.harga_temp) * Number(this.jumlah_temp);

          this.defaultItem.jumlah_debit = this.defaultItem.jumlah_uang;
          this.defaultItem.jumlah_kredit = this.defaultItem.jumlah_uang;

          this.defaultItem.list_order.push(productset);

          // console.log(productset);
          // console.log(this.defaultItem.list_order);

          this.dialogAddProduct = false;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Product Sudah Ada..!!!";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },

    async deletelistitemproduct(item) {
      console.log(item);
      this.editedIndex = await this.defaultItem.list_order.indexOf(item);
      var jumlahuang =  Number(item.harga) * Number(item.jumlah);
      this.defaultItem.jumlah_uang = jumlahuang;
      this.defaultItem.jumlah_debit = jumlahuang;
      this.defaultItem.jumlah_kredit = jumlahuang;
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.list_order.splice(this.editedIndex, 1);
    },
    nextListBarang(){
      this.e1=3;
      console.log(this.defaultItem.diskon);
      console.log(this.discTemp);
      this.discTemp = this.defaultItem.diskon;
      console.log(this.discTemp);
      console.log(this.defaultItem.jumlah_uang);
      // this.defaultItem.jumlah_uang = Number(this.defaultItem.jumlah_uang) +Number(this.defaultItem.ongkir) -Number(this.defaultItem.diskon);
      // this.defaultItem.jumlah_debit =  Number(this.defaultItem.jumlah_uang) +Number(this.defaultItem.ongkir) -Number(this.defaultItem.diskon);
      // this.defaultItem.jumlah_kredit = Number(this.defaultItem.jumlah_uang) +Number(this.defaultItem.ongkir) -Number(this.defaultItem.diskon);
    },
    konfirmasiAdd(){
      console.log(this.defaultItem.diskon);
      console.log(this.defaultItem.jumlah_uang);
      // this.defaultItem.jumlah_uang = Number(this.defaultItem.jumlah_uang) +Number(this.defaultItem.ongkir) -Number(this.defaultItem.diskon);
      // this.defaultItem.jumlah_debit =  Number(this.defaultItem.jumlah_uang) +Number(this.defaultItem.ongkir) -Number(this.defaultItem.diskon);
      // this.defaultItem.jumlah_kredit = Number(this.defaultItem.jumlah_uang) +Number(this.defaultItem.ongkir) -Number(this.defaultItem.diskon);
    },

    async savepembelian(val) {
      if (val == "delete") {
        console.log("delete");
        const datapost = {
          purchase_no: this.defaultItem.purchase_no,
          code_admin: this.admin_id,
        };
        if (HelperGlobalService.checkMandatory(datapost, "object") == true) {
          this.verifDelete(datapost);
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Delete, Error data yang dipilih";
        }
      } else {
        var debit = this.defaultItem.akun_debit;
        var kredit = this.defaultItem.akun_kredit;
        // var laba_rugi = this.defaultItem.akun_laba_rugi;
        const datapost = {
          purchase_no: "default",
          code_supplier: this.defaultItem.supplier.code_supplier,
          deskripsi: this.defaultItem.deskripsi,
          diskon_order: "default",
          ongkir: "default",
          total_order: this.defaultItem.jumlah_uang,
          status_purchase: "regular",
          date: this.datepicker,
          code_admin: this.admin_id,
          path_photo: "default",
          list_order: this.defaultItem.list_order,
          akun_debit: debit.no_akun,
          akun_kredit: kredit.no_akun,
          // akun_laba_rugi: laba_rugi.no_akun,
        };

        // console.log(datapost);
        var check = HelperGlobalService.checkMandatory(datapost, "object");
        if (check == true) {
          if (this.fileUploadReady == true) {
            await this.UploadData();
            if (this.resultUpload != true) {
              this.defaultItem.path_photo = "images/noimage.png";
              this.snackbar = true;
              this.colorsnackbar = "red";
              this.textsnackbar = "Gagal Upload Gambar, Gambar Product Kosong";
            }
          }

          
          datapost.diskon_order= this.defaultItem.diskon;
          datapost.ongkir= this.defaultItem.ongkir;
          datapost.list_order = JSON.stringify(this.defaultItem.list_order);
          datapost.diskon_order = 0;
          // console.log(datapost);

          if (this.defaultItem.purchase_no) {
            datapost.path_photo = this.defaultItem.path_photo;
            datapost.purchase_no = this.defaultItem.purchase_no;
            this.editData(datapost);
          } else {
            datapost.path_photo = this.defaultItem.path_photo;
            datapost.purchase_no = "";
            this.addData(datapost);
          }
        } else {
          console.log(check);
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar =
            "Gagal Menambah, Data tidak boleh ada yang kosong";
        }
      }
    },

    pilihgambar(event) {
      console.log(event);
      if (event != null) {
        this.gambarshow = URL.createObjectURL(event);
        console.log(this.defaultItem.gambarshow);
        this.fileUpload = event;
        this.fileUploadReady = true;
        // this.filephotoarray.push("lahan1");
      } else {
        this.gambarshow = "/images/noimage.png";
        this.fileUploadReady = false;
      }
    },
    zoom(url) {
      // console.log("zooom");
      console.log("Zoom", url);
      this.selectedImage = url;
    },

    getColorShow(val) {
      if (val == "1") return "green";
      else return "red";
    },
    getTextShow(val) {
      if (val == "0") return "Tidak";
      else return "Ya";
    },
    getIconShow(val) {
      if (val == "0") return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },

    getTanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },

    getColorStatusKonfirmasiAdmin(status) {
      if (status == "unpaid") return "red";
      else if (status == "partial") return "orange";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "unpaid") return "mdi-alert-octagon";
      //   else if (status == "7") return "mdi-package-variant";
      //   else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-check-underline-circle";
    },
  },
};
</script>



