<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.created_at="{ item }">
        {{ gettanggal(item.created_at) }}
      </template>

      <template #item.code_supplier="{ value }">
        <a @click="detailItemShow(value)">
          {{ value }}
        </a>
      </template>

      <!-- <template v-slot:item.code_supplier="{ item }">
        {{ gettanggal(item.code_supplier) }}
      </template> -->

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModal()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>

          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="fontall px-24 pt-0 pb-2">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6" class="pa-1">
                        <v-text-field
                          v-model="defaultItem.nama_supplier"
                          label="Nama suplier"
                          outlined
                          :rules="[(v) => !!v || 'Field is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="pa-1">
                        <v-text-field
                          v-model="defaultItem.telephone"
                          label="No Telp"
                          outlined
                          :rules="[(v) => !!v || 'Field is required']"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12" class="pa-1">
                        <v-textarea
                          v-model="defaultItem.alamat_supplier"
                          label="Alamat"
                          outlined
                          rows="3"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDetail" max-width="600px" class="pa-1">
            <v-card>
              <v-card-title class="mb-0 headermodalstyle">
                <span>Detail Supplier</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Nama Supplier
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_supplier }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Kode Supplier
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.code_supplier }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Telephone
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.telephone }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Alamat
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.alamat_supplier }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "supplier",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    textshowoptional: "Show",
    iconshowoptional: "mdi-arrow-right-drop-circle-outline",
    valid: true,
    loadtable: false,
    optionalForm: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Kode Suplier",
        value: "code_supplier",
      },
      { text: "Nama", value: "nama_supplier", width: "20%" },
      { text: "No Telp", value: "telephone" },
      { text: "Tanggal", value: "created_at" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersOrderCust: [
      {
        text: "No Inv",
        value: "id",
      },
      { text: "Barang", value: "total_barang" },
      { text: "Total Belanja", value: "total_harga" },
      { text: "Tanggal", value: "created_at" },
      { text: "Update", value: "updated_at" },
    ],
    dataobject: [],
    dataadmin: [],
    listordercust: [],
    itemsKabRO: [],

    defaultItem: {
      id: "",
      code_supplier: "",
      nama_supplier: "",
      alamat_supplier: "",
      code_admin: "",
      telephone: "",
    },

    itemsGender: [
      { text: "-", value: "-" },
      { text: "Laki-Laki", value: "laki-laki" },
      { text: "Perempuan", value: "perempuan" },
    ],

    e1: 1,
    stepperdone: false,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    admin_id: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetSupplier", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async GetAdmin() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAdmin", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataadmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataadmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataadmin = [];
        }
      }
    },
    async getDetail(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetSupplierDetail?code_supplier=" +
            this.defaultItem.code_supplier,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result.supplier_detail;
          this.defaultItem.kabROCombobox = {
            id_alamat:
              response.data.data.result.supplier_detail.code_alamat_cust_ro,
            text: response.data.data.result.supplier_detail.alamat_cust_ro,
          };
          console.log(this.defaultItem);
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getKabKotaROAll() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetKotaKabRO", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsKabRO = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsKabRO = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsKabRO = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        code_supplier: this.defaultItem.code_supplier,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteSupplier",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        nama_supplier: this.defaultItem.nama_supplier,
        alamat_supplier: this.defaultItem.alamat_supplier,
        telephone: this.defaultItem.telephone,
        code_admin: this.admin_id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddSupplier",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        code_supplier: this.defaultItem.code_supplier,
        nama_supplier: this.defaultItem.nama_supplier,
        alamat_supplier: this.defaultItem.alamat_supplier,
        telephone: this.defaultItem.telephone,
        code_admin: this.admin_id,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditSupplier",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },

    showAddModal() {
      this.value = "add";
      this.formTitle = "Add Item";
      // this.defaultItem = [];

      this.defaultItem.code_supplier = "";
      this.defaultItem.nama_supplier = "";
      this.defaultItem.alamat_supplier = "";
      this.defaultItem.code_admin = "";
      this.defaultItem.telephone = "";

      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      this.dialog = true;
    },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.getDetail("edit");
      this.value = "edit";
      this.dialog = true;
    },
    deleteItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.getDetail("detail");
      this.dialogDetail = true;
    },
    detailItemShow(val) {
      this.defaultItem.code_supplier = val;
      this.getDetail("detail");
      this.dialogDetail = true;
    },

    deleteItemConfirm() {
      this.closeDelete();
      this.verifDelete();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      this.$refs.form.validate();

      if (this.$refs.form.validate() == true) {
        if (this.defaultItem.code_supplier) {
          console.log(this.defaultItem);
          this.updateData();
        } else {
          console.log("add");
          console.log(this.defaultItem);
          this.addData();
        }
        // this.close();

        // console.log(this.defaultItem.kabROCombobox.text);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },

    gettanggal(val) {
      var bulanIndo = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mei",
        "Juni",
        "Juli",
        "Agust",
        "Sept",
        "Okt",
        "Nov",
        "Des",
      ];

      var date = val.split(" ")[0];

      var tanggal = date.split("-")[2];
      var bulan = date.split("-")[1];
      var tahun = date.split("-")[0];

      return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
    },
  },
};
</script>



