<template>
  <div>

    <v-card v-if='this.undermaintenance == true' elevation="2" class="mx-auto my-1 px-1" max-width="500">        
                <v-img
                    contain
                    max-height="350"
                    max-width="100%"
                    src="/images/under-maintenance.jpg"
                >                    
                </v-img>
    </v-card>

    <v-data-table v-if='this.undermaintenance == false'
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.total_piutang="{ item }">
        {{ getRupiah(item.total_piutang) }}
      </template>

      <template v-slot:item.total_payment="{ item }">
        {{ getRupiah(item.total_payment) }}
      </template>

      <template v-slot:item.sisapiutang="{ item }">
        {{ getRupiah(item.sisapiutang) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ getTanggal(item.created_at) }}
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="download()" color="#b3b928">
            <v-icon left> mdi-download-circle-outline </v-icon> Download
          </v-btn>

          <!-- Modal Detail -->
          <v-dialog v-model="dialogDetail" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle mb-0">
                <span>Detail Piutang</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container
                  v-if="loaddetail == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>

                <v-container v-if="loaddetail == false">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Customer
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ defaultItem.nama_customer }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Total Piutang
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.total_piutang) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Total Bayar
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.total_payment) }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            Sisa Piutang
                          </th>
                          <td class="text-left" style="font-size: 14px">
                            {{ getRupiah(defaultItem.sisapiutang) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center" style="font-size: 13px">
                            Code Order Invoice
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Total Piutang
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Total Pembayaran
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Sisa Piutang
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Tanggal
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="product in defaultItem.detail"
                          :key="product.code_order"
                        >
                          <td
                            class="text-left"
                            style="width: 250px !important; font-size: 13px"
                          >
                            {{ product.code_order }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getRupiah(product.total_piutang) }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getRupiah(product.total_payment) }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getRupiah(product.sisapiutang) }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getTanggal(product.created_at) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "LaporanPiutang",
  data: () => ({

    undermaintenance : true,
    
    formTitle: "Add Item",
    value: "add",
    valid: true,
    loadtable: false,
    loaddetail: false,
    dialog: false,
    dialogDelete: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrl: "",
    BaseUrlGet: "",
    BaseUrlUpload: "",
    BaseUrlDBImage: "",
    headers: [
      { text: "Code Customer", value: "code_customer" },
      { text: "Nama Customer", value: "nama_customer" },
      { text: "Total Piutang", value: "total_piutang" },
      { text: "Total Pembayaran", value: "total_payment" },
      { text: "Sisa Piutang", value: "sisapiutang" },
      { text: "Actions", value: "actions", sortable: false, width: "12%" },
    ],
    headerListDetail: [
      { text: "Code Order Invoice", value: "code_order" },
      { text: "Total Piutang", value: "total_piutang" },
      { text: "Total Pembayaran", value: "total_payment" },
      { text: "Sisa Piutang", value: "sisapiutang" },
      { text: "Tanggal", value: "created_at" },
    ],
    dataobject: [],
    itemsCategory: [],

    defaultItem: {
      id: "",
      code_customer: "",
      nama_customer: "",
      total_piutang: "",
      total_payment: "",
      created_at: "",
      detail: "",
    },

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    admin_id: "",
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrl = localStorage.getItem("BaseUrl");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
    this.BaseUrlDBImage = localStorage.getItem("BaseUrlDBImage");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.initialize();
  },

  methods: {
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetLaporanPiutang",
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async getDetail() {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetLaporanPiutangDetail?code_customer=" +
            this.defaultItem.code_customer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;

          this.loaddetail = false;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },

    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      // this.defaultItem.image_show = this.BaseUrl + item.path_photo;
      // console.log(this.defaultItem.path_image);
      this.getDetail();
      this.dialogDetail = true;
    },
    download() {
      this.dialogDetailSale = false;
      var str = this.BaseUrlGet;
      window.open(str.substring(0, str.length - 4) + "ExportLaporanPiutang");
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },

    getTanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },
  },
};
</script>



