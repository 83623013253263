<template>
  <div>

    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="2" class="mx-auto my-1 px-1" max-width="90%">
        <v-card-text>
          <v-row>
            <v-col  cols="12" sm="4" md="4">
              <div>
                <h6>Foto Profile</h6>
                <hr style="width:70%">
              </div>
              <div style="text-align: center">
                <img
                    :src="images_show"
                    class="mr-auto my-4"
                    width="200"
                    style="margin-left: 1px !important"
                  />
              </div>
              <div style="text-align: center">
                  <v-btn
                    color="primary"
                    small
                    @click="gantiFoto()"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Ganti Foto
                  </v-btn>
              </div>
              
            </v-col>
            <v-divider class="my-3"
              vertical
            ></v-divider> 
            <v-col  cols="12" sm="8" md="8">           
              <div>
                <h6>Detail User</h6>
                <hr style="width:70%">
              </div>
              <div class="mt-4">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model='namauser'
                      outlined
                      dense
                      class="fontall"
                      label='Nama Admin'
                      :disabled='isDisabledUpdateUser'
                      :filled='isDisabledUpdateUser'
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model='no_telp'
                      outlined
                      dense
                      class="fontall"
                      label='No Telp'
                      :disabled='isDisabledUpdateUser'
                      :filled='isDisabledUpdateUser'
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model='role_user'
                      outlined
                      dense
                      class="fontall"
                      label='Role Admin'
                      disabled
                      filled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model='email'
                      outlined
                      dense
                      class="fontall"
                      label='Email'
                      disabled
                      filled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model='alamat'
                      outlined
                      dense
                      class="fontall"
                      label='Alamat'
                      :disabled='isDisabledUpdateUser'
                      :filled='isDisabledUpdateUser'
                    ></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
              <div style="text-align: right">
                <v-spacer></v-spacer>
                  <v-btn
                  v-if='isDisabledUpdateUser == true'
                    color="primary"
                    small
                    @click="updateUser()"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Update User
                  </v-btn>
                  <v-btn
                  v-if='isDisabledUpdateUser == false'
                    class="mr-2"
                    color="red"
                    small
                    dark
                    @click="cancelUpdateUser()"
                  >
                    Batal
                  </v-btn>
                  <v-btn
                  v-if='isDisabledUpdateUser == false'
                    color="success"
                    small
                    @click="simpanUpdate()"
                  >
                    Simpan
                  </v-btn>
              </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-form>

          <v-dialog persistent v-model="dialogUploadFoto" max-width="500px">
            <v-card>
              <v-card-text class="fontall pb-0 px-2 pt-3">
                <div class="mb-2 mt-1 mx-1" style="background-color:#d3dffd;display: flex">
                  <h2 class="pl-2 py-2">Upload Foto</h2>
                <v-spacer></v-spacer>
                  <v-btn icon small class="ma-1 mr-2" @click="closeUploadFoto">
                    <v-icon center> mdi-window-close </v-icon>
                  </v-btn>
                </div> 
              </v-card-text>
              <v-divider class="mt-2 mb-3 mx-3"></v-divider>
              <v-card-text class="fontall pb-1">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-container fluid class='pb-0 pt-2'>
                    <v-row>
                          <v-col cols="12" sm="8" md="8" class="pa-1">
                            <v-file-input
                              class="pt-1 mt-0"
                              accept="image/png, image/jpeg, image/jpg"
                              placeholder="Pilih Foto/Gambar"
                              prepend-icon="mdi-camera"
                              outlined
                              dense
                              v-model="gambarinput"
                              v-on:change="pilihgambar"
                            ></v-file-input>
                          </v-col>   
                    </v-row>
                        <v-row class="mt-0">
                          <v-col cols="12" sm="12" md="12" style="text-align: -webkit-center;" class="pa-1">
                            <v-img
                              style="margin-left: 40px;"
                              contain
                              max-height="351"
                              max-width="351"
                              v-bind:src='images_show'
                            ></v-img>
                          </v-col>  
                        </v-row>
                  </v-container> 
                </v-form>
              </v-card-text> 
              <v-divider class="my-1 mx-3"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark :loading="loading" color="#25695c" @click="saveupload">
                  <v-icon left> mdi-upload </v-icon>
                  Upload
                </v-btn>
                <v-btn dark color="#8b8787" @click="closeUploadFoto">
                  <v-icon left> mdi-arrow-left-thin </v-icon> Kembali
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile",
  data: () => ({
    showPassword: false,
    authtoken: "",
    BaseUrlGet: "",
    dataobjectprov: [],
    defaultItem: { id: "", province_code: "", name: "" },
    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,
    valid: true,

    isDisabledUpdateUser: true,
    dialogUploadFoto: false,

    User: [],

    usercode:"",
    namauser: "",
    username: "",
    email: "",
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
    
    no_telp: "",
    alamat: "",
    role_user: "",
    path_foto: "images/avatar.png",
    imagesReturnBase64:'',

    fileUpload : '',
    fileUploadReady : false,
    gambarinput:'',
    images_show:'',
  }),

  beforeMount() {
    this.authtoken = localStorage.getItem("token");
    this.User = JSON.parse(localStorage.getItem("User"));
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    if (this.User) {
      this.usercode = this.User.code;
      this.email = this.User.email;
      this.namauser = this.User.name;
      
      this.no_telp = this.User.no_telp;
      this.alamat = this.User.alamat;
      this.role_user = this.User.role;
      this.path_foto = this.User.path_foto;
      if(this.User.path_foto == '-'){
        this.images_show ="images/avatar.png";
      }else{
        this.getFoto(this.User.path_foto);
        // this.path_foto = this.imagesReturnBase64;
      }
    }
  },

  methods: {
    async submitedituser() {
      const datapost = {
        code: this.usercode,
        name: this.namauser,
        email: this.email,
        no_telp: this.no_telp,
        alamat: this.alamat,
        path_foto: this.path_foto,
      };

      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditUser",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.status.code);
        this.snackbar = true;
        if (response.data.data.status.code == 200) {          
          // localStorage.removeItem("User");
          // localStorage.setItem(
          //   "User",
          //   JSON.stringify(response.data.data.result.User)
          // );
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses Update";
          this.isDisabledUpdateUser = true;
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          setTimeout(this.$router.push("/"), 5000);
          // location.reload();
        } else {
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal Update";
        }
      } catch (error) {
        console.error(error.response);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = error.response.data.data.status.description;
      }
    },
    async cekLogout() {
      const datapost = {};
      // console.log(this.BaseUrlGet);
      // console.log(this.authtoken);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "Logout",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.status.code);
        if (response.data.data.status.code == 200) {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          this.$router.push("/");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("User");
          localStorage.removeItem("BaseUrlUpload");
          this.$router.push("/");
        }
      } catch (error) {
        // console.error(error.response);
        localStorage.removeItem("token");
        localStorage.removeItem("User");
        localStorage.removeItem("BaseUrlUpload");
        this.$router.push("/");
      }
    },
    async gantipassword() {
      await this.$refs.form.validate();
      console.log(this.$refs.form.validate());
      if (this.$refs.form.validate() == true) {
        this.submitgantipassword();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Data tidak boleh kosong";
      }
      // console.log(this.email);
      // console.log(this.password);
      // await this.$refs.form.reset();
    },
    async getFoto(val){
      // var imagesvalue = 'public/uploads/1656286341_login_tokomas.PNG';
      var imagesvalue = val;
      try {
        const response = await axios.get(this.BaseUrlGet + "getimages?path_file="+imagesvalue, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        if (response.data.length != 0) {
          this.images_show = "data:image/jpg;base64," + response.data.data.result;
        } else {
          console.log("Kosong");
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
        }
      }
    },
    async uploadApi() {
      const formData = new FormData();
      formData.append("file", this.fileUpload);
      // console.log(formData);
      // this.dialogDetail = false;
      try {        
        const response = await axios.post(
          this.BaseUrlGet + "upload",
          formData,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data);
        if (response.data.success == true) {
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses upload gambar";
          this.path_foto = response.data.data.result;
          // this.imagesResult = response.data.data.result;
          // this.defaultItem.foto_barang = response.data.data.result;
          // console.log(this.defaultItem.foto_barang);
          // this.dialogDelete = false;
          // this.dialog = false;
          // this.initialize();
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    }, 

    gantiFoto(){
      this.dialogUploadFoto = true;
    },
    closeUploadFoto(){
      this.dialogUploadFoto = false;
    },
    pilihgambar(event) {
      console.log(event);
      if (event != null) {
        this.images_show = URL.createObjectURL(event);
        console.log(this.defaultItem.gambarshow);
        this.fileUpload = event;
        this.fileUploadReady = true;
        // this.filephotoarray.push("lahan1");
      } else {
        this.images_show = "images/avatar.png";
        this.fileUploadReady = false;
      }
    },
    async saveupload(){
      if(this.fileUploadReady == true){
        await this.uploadApi();
        console.log(true);
        this.submitedituser();
      }else{
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "Gambar kosong, tidak bisa upload gambar";
        this.loading = false;
      }
    },
    updateUser(){
      this.isDisabledUpdateUser = false;
    },
    cancelUpdateUser(){
      this.isDisabledUpdateUser = true;
    },
    async simpanUpdate(){
      if (this.namauser.length > 0 && this.no_telp.length > 0 && this.alamat.length > 0) {
        await this.submitedituser();
        console.log(this.isDisabledUpdateUser)
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Data tidak boleh kosong";
      }
    },
  },
};
</script>