<template>
  <v-content
    style="
      background-image: url('/images/BG_Login_New1.png');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: 100% 100%;
      height: 100vh;
    "
  >
    <v-form ref="form" v-model="valid" lazy-validation>
      <div
        style="
          text-align: -webkit-center;
          margin-bottom: 40px;
          margin-top: 60px;
        "
      >
        <v-img
          max-width="300"
          src="/images/aqwamLogin.png"
          style="margin-top: 5px"
        ></v-img>
      </div>

      <v-card
        max-width="325"
        class="mx-auto px-5 pb-2"
        style="margin-top: 10px; border-radius: 10px"
        elevation="7"
      >
        <v-row align="center" justify="center" class="mb-2 pt-3">
          <!-- <v-img
            max-width="300"
            src="/images/logo_login_distara.jpeg"
            style="margin-top: 5px"
          ></v-img> -->
        </v-row>
        <!-- <v-row align="center" justify="center" class="mb-5">
          <h3 style="text-align: center">Admin Management System</h3>
        </v-row> -->
        <!-- <v-card-title> </v-card-title> -->
        <v-card-text class="pt-2 pb-0 px-3">
          <v-text-field
            label="Email"
            name="email"
            v-model="email"
            placeholder="Masukkan Email"
            prepend-inner-icon="mdi-email"
            filled
            required
            rounded
            dense
            v-on:keyup="submitLogin"
            :rules="[(v) => !!v || 'Field is required']"
            style="margin-top: 20px; border-radius: 5px"
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="password"
            placeholder="Masukkan Password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            prepend-inner-icon="mdi-lock"
            filled
            required
            rounded
            dense
            v-on:keyup="submitLogin"
            :rules="[(v) => !!v || 'Field is required']"
            style="border-radius: 5px"
          ></v-text-field>
        </v-card-text>

        <v-card-actions class="pt-0 pb-2 px-3 mb-5">
          <v-btn
            block
            large
            width="200"
            @click="login()"
            color="#c53535"
            class="mx-auto"
            :disabled="disablevalue"
            ><v-progress-circular
              v-if="load == true"
              :size="25"
              :width="5"
              indeterminate
              color="green"
            >
            </v-progress-circular>
            <h2 v-if="load == false" style="color: white">Login</h2>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-snackbar v-model="snackbar" :color="colorsnackbar" :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </v-content>
</template>

<script>
import axios from "axios";

export default {
  name: "Trees",
  data: () => ({
    showPassword: false,
    email: "",
    password: "",
    captchavalue: "",
    captchatextfield: "",
    authtoken: "",
    load: false,
    disablevalue: false,
    BaseUrlGet: "https://apiv1erp.aqwam.biz/api/",
    BaseUrlUpload: "https://akuntansi.aqwam.biz/upload.php",
    BaseUrl: "https://akuntansi.aqwam.biz/",
    snackbar: false,
    text: "Test",
    timeout: 2000,
    colorsnackbar: null,
    valid: true,
  }),
  created() {
    this.firstAccessPage();
    this.generateString(8);
  },
  methods: {
    firstAccessPage() {
      var authtoken = localStorage.getItem("token");
      if (authtoken) {
        localStorage.removeItem("token");
      }
      var User = JSON.parse(localStorage.getItem("User"));
      if (User) {
        localStorage.removeItem("User");
      }
      var BaseUrlGet = localStorage.getItem("BaseUrlGet");
      if (BaseUrlGet) {
        localStorage.removeItem("BaseUrlGet");
      }
      var BaseUrl = localStorage.getItem("BaseUrl");
      if (BaseUrl) {
        localStorage.removeItem("BaseUrl");
      }
      var BaseUrlUpload = localStorage.getItem("BaseUrlUpload");
      if (BaseUrlUpload) {
        localStorage.removeItem("BaseUrlUpload");
      }
      this.load = false;
      this.disablevalue = false;
    },
    async cekLogin() {
      this.load = true;
      this.disablevalue = true;
      const datapost = {
        email: this.email,
        password: this.password,
      };
      // console.log(datapost);
      // console.log(this.BaseUrlGet);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "LoginAdmin",
          datapost
        );
        console.log(response.data.data.status.code);
        this.snackbar = true;
        if (response.data.data.status.code == 200) {
          this.load = false;
          this.disablevalue = false;
          this.colorsnackbar = "green";
          this.text = "Sukses Login";
          // console.log(response.data.data.result.access_token);
          localStorage.setItem("token", response.data.data.result.access_token);
          localStorage.setItem(
            "User",
            JSON.stringify(response.data.data.result.User)
          );
          localStorage.setItem("BaseUrlGet", this.BaseUrlGet);
          localStorage.setItem("BaseUrlUpload", this.BaseUrlUpload);
          localStorage.setItem("BaseUrl", this.BaseUrl);
          location.reload();
          this.$router.push("/Dashboard");
        } else {
          this.load = false;
          this.disablevalue = false;
          this.colorsnackbar = "red";
          this.text = "Gagal Login, username dan password salah";
        }
      } catch (error) {
        this.load = false;
        this.disablevalue = false;
        console.error(error.response);
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.text = "Gagal Login, username dan password salah";
      }
    },

    login() {
      this.$refs.form.validate();
      // console.log(this.email);
      // console.log(this.password);
      if (this.$refs.form.validate() == true) {
        this.cekLogin();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.text = "Data tidak boleh kosong";
      }
    },

    submitLogin: function (e) {
      if (e.keyCode === 13) {
        this.$refs.form.validate();
        if (this.$refs.form.validate() == true) {
          this.cekLogin();
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.text = "Data tidak boleh kosong";
        }
      }
      // this.log += e.key;
    },

    generateString(length) {
      let result = " ";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      this.captchavalue = result.replace(/\s/g, "");
    },
  },
};
</script>

<style scoped>
.btn,
button,
input {
  border-radius: 5px;
}

.btn:hover,
button:hover {
  transition: 0.5s ease;
}

.v-input__slot {
  padding-left: 10px !important;
}
</style>

