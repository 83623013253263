<template>
  <div class="px-3">
    <v-card>
          <div class="pa-2" style="display:flex">
            <v-btn color="error" @click="backButton" elevation="2" small>Kembali</v-btn>
            <v-spacer></v-spacer>
            <h3>Buku Besar Akun {{nama_akun}}</h3>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="downloadButton" elevation="2" small>Download</v-btn>
          </div>
          <v-divider style="background-color: black;" class="mx-5"></v-divider>
          <div v-if="loadtable2 == true" style="text-align:center" class="my-5">
            <v-progress-circular
              :size="50"
              :width="5"
              color="red"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th width='12%' class="text-left">
                    Tanggal
                  </th>
                  <th width='15%' class="text-left">
                    Reff
                  </th>
                  <th width='28%' class="text-left">
                    Keterangan
                  </th>
                  <th width='15%' class="text-center">
                    Debit
                  </th>
                  <th width='15%' class="text-center">
                    Kredit
                  </th>
                  <th width='15%' class="text-center">
                    Saldo
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in dataobject2"
                  :key="item.id"
                >
                  <td>{{ gettanggal(item.tanggal) }}</td>
                  <td>
                    <div>{{ item.type }}</div>
                    <div>{{ getReffKeterangan(item.Reff) }}</div>
                  </td>
                  <td>{{ item.keterangan }}</td>
                  <td class="text-right">{{ getRupiahNominal(item.debit) }}</td>
                  <td class="text-right">{{ getRupiahNominal(item.kredit) }}</td>
                  <td class="text-right">{{ getRupiahNominal(item.saldo_berjalan) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "BukuBesar",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    textshowoptional: "Show",
    iconshowoptional: "mdi-arrow-right-drop-circle-outline",
    valid: true,
    loadtable2: false,
    optionalForm: false,
    dialog: false,
    dialogReset: false,
    dialogDetail: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    dataobject2: [],

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    admin_id: "",

    no_akun:'',
    nama_akun:'',
  }),

  beforeMount() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.admin_id = this.User.code;
    }
    this.no_akun = localStorage.getItem("no_akun");
    this.getBukuBesarDetail();
  },

  methods: {
    async getBukuBesarDetail() {
      this.dataobject = [];
      this.loadtable2 = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetDetailLaporanBukuBesar?no_akun="+this.no_akun, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable2 = false;
          this.dataobject2 = response.data.data.result.data;
          this.nama_akun = response.data.data.result.nama_akun;
        } else {
          console.log("Kosong");
          this.loadtable2 = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable2 = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable2 = false;
          this.dataobject = [];
        }
      }
    },

    detailPilih(value) {
      this.getDetail("detail");
      this.dialogDetail = true;
    },

    backButton(){
      localStorage.removeItem("no_akun");
      this.$router.push("/BukuBesar");
    },
    downloadButton(){

    },

    close() {
      this.dialog = false;
    },

    gettanggal(val) {

      if(val){
        var bulanIndo = [
          "",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Juni",
          "Juli",
          "Agust",
          "Sept",
          "Okt",
          "Nov",
          "Des",
        ];

        var date = val.split(" ")[0];

        var tanggal = date.split("-")[2];
        var bulan = date.split("-")[1];
        var tahun = date.split("-")[0];

        return tanggal + " " + bulanIndo[Math.abs(bulan)] + " " + tahun;
      }else{
        return val;
      }
    },

    getReffKeterangan(val){
      if(val){
        if(val.split("(")[1]){
          return '('+val.split("(")[1];
        }else{
          return val;
        }
      }else{
        return val;
      }
    },
    
    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahNominal(val) {
      return HelperGlobalService.getRupiahNominal(val);
    },
  },
};
</script>



