<template>
  <div>

    <v-data-table
      :headers="headers"
      :items="dataobject"
      :search="search"
      :loading="loadtable"
      loading-text="Loading... Please wait"
      class="rounded elevation-6 mx-3 pa-1 fontall"
    >
      <template v-slot:item.created_at="{ item }">
        {{ gettanggal(item.created_at) }}
      </template>

      <template v-slot:item.status_so="{ item }">
        <v-chip
          outlined
          :color="getColorStatusKonfirmasiAdmin(item.status_so)"
          dark
        >
          <v-icon
            medium
            class="mr-1"
            :color="getColorStatusKonfirmasiAdmin(item.status_so)"
          >
            {{ getIconStatusAdmin(item.status_so) }}
          </v-icon>
          {{ item.status_so }}
        </v-chip>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn dark class="mb-2" @click="showAddModalSO()" color="green">
            <v-icon small>mdi-plus</v-icon> Add Item
          </v-btn>
          <v-dialog v-model="dialogAddSO" max-width="900px">
            <v-card>
              <v-card-title class="headermodalstyle" style="margin-bottom: 2px">
                <span>Add Sales Invoice</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-container class="mb-0">
                  <v-row class="mb-0">
                    <v-col class="pb-0" cols="6" sm="6" md="6">
                      <v-menu
                              v-model="menu2"
                              transition="scale-transition"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="datepicker"
                                  slot="activator"
                                  label="Pilih Tanggal"
                                  outlined
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="[(v) => !!v || 'Field is required']"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="datepicker"
                                @input="menu2 = false"
                              ></v-date-picker>
                            </v-menu>
                    </v-col>
                    <v-col class="pb-0" cols="6" sm="6" md="6">
                      <v-select
                        v-model="defaultItem.lokasi"
                        :items="itemsLokasi"
                        item-value="value"
                        item-text="text"
                        label="Pilih Lokasi"
                        outlined
                        :rules="[(v) => !!v || 'Field is required']"
                        v-on:change="changeLokasi"
                      ></v-select>
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-combobox
                        v-model="ordercombo_so"
                        :items="datasalesorder"
                        item-value="order_no"
                        item-text="TextOrderCustomer"
                        label="Pilih No Order"
                        outlined
                        v-on:change="changeOrderSO"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-if="showButtonAdd == true">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-img
                        contain
                        class="my-2 mx-1 mb-4"
                        max-height="600"
                        v-bind:src="defaultItem.image"
                      ></v-img>
                      <!-- <img
                          :src="defaultItem.image"
                          alt=""
                          width="255"
                        /> -->
                      <!-- <v-card
                          class="d-flex align-center"
                          elevation="2"
                          height="450"
                          width="450"
                        >
                          <v-img
                            v-bind:src="defaultItem.image"
                            class="my-2 mx-1 mb-4"
                            max-height="450"
                            contain
                            v-on:click="zoom(defaultItem.Gambar)"
                          ></v-img>
                        </v-card> -->
                    </v-col>
                    <v-col class="pb-0" cols="12" sm="6" md="6">
                      <v-simple-table>
                        <template v-slot:default>
                          <tbody>
                            <tr>
                              <th
                                class="text-left"
                                style="width: 250px !important; font-size: 14px"
                              >
                                No Order
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ defaultItem.order_no }}
                              </td>
                            </tr>
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                Nama Customer
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ defaultItem.nama_customer }}
                              </td>
                            </tr>
                            <tr>
                              <th
                                rowspan="2"
                                class="text-left"
                                style="font-size: 14px"
                              >
                                Alamat
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ defaultItem.kecamatan }}, {{ defaultItem.kab_kota }}
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ defaultItem.alamat }}
                              </td>
                            </tr>                        
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                Tipe Transaksi
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ defaultItem.typeTransaksi }}
                              </td>
                            </tr>                     
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                SubTotal Beli (Total Barang)
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ getRupiah(defaultItem.total_harga) }} ( {{ defaultItem.total_barang }} Barang )
                              </td>
                            </tr>                     
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                Total Diskon
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ setDiskon(defaultItem.totalDiskon,defaultItem.diskonKodeUnik) }}
                              </td>
                            </tr>                     
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                Biaya Ekspedisi
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ getRupiah(defaultItem.biayaExpedisi) }}
                              </td>
                            </tr>                    
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                Total Beli
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ setTotalAll(defaultItem.total_harga,defaultItem.totalDiskon,defaultItem.diskonKodeUnik,defaultItem.biayaExpedisi) }}
                              </td>
                            </tr>                   
                            <tr>
                              <th class="text-left" style="font-size: 14px">
                                Pembayaran (Bank)
                              </th>
                              <td
                                class="text-left"
                                style="font-size: 14px; font-weight: bold"
                              >
                                {{ defaultItem.bank }}
                              </td>
                            </tr> 
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-row class="mb-3 pb-0" style="margin-top: 10px; margin-left: 5px;">
                          <h4>Konfirmasi Tipe Transaksi</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-row class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="6" md="6">
                            <v-combobox
                              v-model="tipetrx"
                              :items="itemsTipe"
                              label="Pilih Tipe Transaksi"
                              item-value="value"
                              item-text="text"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>                        
                  </v-row>
                  <v-row class="mb-3 pb-0" style="margin-top: 10px; margin-left: 5px;">
                          <h4>Kelebihan atau kurang bayar</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-row  v-if="showsisa == true" class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="2" md="2">    
                            <v-checkbox
                              v-model="ceksisa"
                              @change="ceksisafunction()"
                              label="Sisa/Kurang"
                            ></v-checkbox>
                          </v-col> 
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="jenis_sisa"
                              :items="itemsJenisSisa"
                              label="Pilih Jenis"
                              clearable
                              outlined
                              :disabled='isDisabledSisa'
                              :filled='isDisabledSisa'
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              v-model="nominal_sisa"
                              label="Jumlah"
                              outlined
                              type="number"
                              :disabled='isDisabledSisa'
                              :filled='isDisabledSisa'
                            ></v-text-field>
                          </v-col>                          
                  </v-row>
                  <v-row class="mb-3 pb-0" style="margin-top: 10px; margin-left: 5px;">
                          <h4>Konfirmasi Akun Neraca</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row>
                  <v-row class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="6" md="6">
                            <v-combobox
                              v-model="akun_debit_1"
                              :items="itemsAkun"
                              label="Pilih Akun Debit"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="nominal_akun_debit_1"
                              label="Jumlah"
                              outlined
                              type="number"
                              required
                            ></v-text-field>
                          </v-col>                          
                  </v-row>
                  <v-row  v-if="showakun2 == true" class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="2" md="2">    
                            <v-checkbox
                              v-model="cek1"
                              @change="cekakun2()"
                              label="Akun ke-2"
                            ></v-checkbox>
                          </v-col> 
                          <v-col cols="12" sm="6" md="6">
                            <v-combobox
                              v-model="akun_debit_2"
                              :items="itemsAkun"
                              label="Pilih Akun Debit"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :disabled='isDisabledAkun2'
                              :filled='isDisabledAkun2'
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              v-model="nominal_akun_debit_2"
                              label="Jumlah"
                              outlined
                              type="number"
                              :disabled='isDisabledAkun2'
                              :filled='isDisabledAkun2'
                            ></v-text-field>
                          </v-col>                          
                  </v-row>
                  <v-row  v-if="showakun2 == true"  class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="2" md="2">    
                            <v-checkbox
                              v-model="cek2"
                              @change="cekakun3()"
                              label="Akun ke-3"
                            ></v-checkbox>
                          </v-col> 
                          <v-col cols="12" sm="6" md="6">
                            <v-combobox
                              v-model="akun_debit_3"
                              :items="itemsAkun"
                              label="Pilih Akun Debit"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :disabled='isDisabledAkun3'
                              :filled='isDisabledAkun3'
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              v-model="nominal_akun_debit_3"
                              label="Jumlah"
                              outlined
                              type="number"
                              :disabled='isDisabledAkun3'
                              :filled='isDisabledAkun3'
                            ></v-text-field>
                          </v-col>                          
                  </v-row>
                  <v-row  v-if="showakun3 == true"  class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="12" md="12">    
                            <v-textarea
                              v-model="catatan"
                              label="Catatan"
                              rows="3"
                              outlined
                            ></v-textarea>
                          </v-col>                         
                  </v-row>
                  <!-- <v-row class="mb-3" style="margin-top: 10px; margin-left: 5px;">
                          <h4>Konfirmasi Akun Laba Rugi</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                  </v-row> -->
                  <!-- <v-row class="mb-3" style="margin-left: 5px;">
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.akun_laba_rugi_1"
                              :items="itemsAkun"
                              label="Pilih Akun"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.akun_laba_rugi_2"
                              :items="itemsAkun"
                              label="Pilih Akun"
                              item-value="no_akun"
                              item-text="nama_akun"
                              clearable
                              outlined
                              :rules="[(v) => !!v || 'Field is required',]"
                            ></v-combobox>
                          </v-col>
                  </v-row> -->
                  <!-- <v-data-table
                    :headers="headersProduct"
                    :items="defaultItem.list_order"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.harga="{ item }">
                      {{ getRupiah(item.harga) }}
                    </template>
                    <template v-slot:item.jumlah="{ item }">
                      {{ item.jumlah }} {{ item.satuan }}
                    </template>
                  </v-data-table> -->
                </v-container>
              </v-card-text>
              <v-card-actions v-if="showButtonAdd == true">
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="savesalesorder"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialog" max-width="700px">
            <v-card>
              <v-card-title class="headermodalstyle" style="margin-bottom: 2px">
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-card-text class="pa-0 fontall">
                <v-stepper v-model="e1">
                  <v-stepper-header style="height: 65px">
                    <v-stepper-step editable :complete="e1 > 1" step="1">
                      Data Customer
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step editable :complete="e1 > 2" step="2">
                      List Order
                    </v-stepper-step>
                    <!-- <v-divider></v-divider>
                    <v-stepper-step editable step="3">
                      Pembayaran
                    </v-stepper-step> -->
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content class="pa-3" step="1">
                      <v-container class="mb-0">
                        <v-row class="mb-0">
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.code_salesman"
                              :items="dataadmin"
                              item-value="code"
                              item-text="nama"
                              label="Pilih Salesman"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              v-on:change="changeSalesman"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.code_customer"
                              :items="datacustomer"
                              item-value="code_customer"
                              item-text="nama_customer"
                              label="Pilih Customer"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.kabROCombobox"
                              :items="itemsKabRO"
                              item-value="id_alamat"
                              item-text="text"
                              label="Pilih Kec/Kab"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-combobox>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-textarea
                              v-model="defaultItem.alamat"
                              label="Alamat"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              rows="3"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          elevation="1"
                          outlined
                          color="primary"
                          @click="e1 = 2"
                        >
                          <v-icon left>
                            mdi-chevron-right-circle-outline
                          </v-icon>
                          Next
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content class="pa-3" step="2">
                      <v-container class="mb-0">
                        <v-row class="mb-0">
                          <h4>Pilih Product</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row>
                          <v-col class="px-1 py-0" cols="12" sm="12" md="12">
                            <v-combobox
                              v-model="defaultItem.itemsProductSelected"
                              :items="itemsProduct"
                              item-value="code_product"
                              item-text="nama_product"
                              label="Pilih Product"
                              v-on:change="changeProductSelected"
                              outlined
                              clearable
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row class="mb-1">
                          <h4>List Product</h4>
                          <v-divider class="mx-2 mt-3"></v-divider>
                        </v-row>
                        <v-row class="mb-1">
                          <v-col cols="12" sm="12" md="12">
                            <v-data-table
                              :headers="headerListProduct"
                              :items="defaultItem.list_order"
                              class="elevation-3"
                              append-icon="mdi-magnify"
                              :items-per-page="5"
                            >
                              <template v-slot:item.actions="{ item }">
                                <v-icon
                                  class="mr-2"
                                  @click="deletelistitemproduct(item)"
                                  color="red"
                                >
                                  mdi-delete
                                </v-icon>
                                <!-- <v-icon @click="deleteItem(item)" color="red"> mdi-delete </v-icon> -->
                              </template>
                            </v-data-table>
                          </v-col>
                        </v-row>
                        <v-row class="mb-2">
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="defaultItem.jumlah_discount"
                              label="Masukkan Diskon"
                              outlined
                              type="number"
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="save"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <!-- <v-stepper-content class="pa-3" step="3">
                      <v-container class="mb-0">
                        <v-row class="mb-1">
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-select
                              v-model="defaultItem.term_of_payment"
                              :items="itemsTOP"
                              item-value="value"
                              item-text="text"
                              label="Term Of Payment"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                              v-on:change="changeTOP"
                            ></v-select>
                          </v-col>
                          <v-col
                            v-if="dateTempo == true"
                            class="pb-0"
                            cols="12"
                            sm="12"
                            md="12"
                          >
                            <v-select
                              v-model="defaultItem.lama_tempo"
                              :items="itemsLamaTempo"
                              item-value="value"
                              item-text="text"
                              label="Lama Tempo"
                              outlined
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-select>
                          </v-col>
                          <v-col class="pb-0" cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="defaultItem.jumlah_discount"
                              label="Masukkan Diskon"
                              outlined
                              type="number"
                              :rules="[(v) => !!v || 'Field is required']"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>

                      <v-row class="mx-2 mb-2">
                        <v-spacer></v-spacer>
                        <v-btn
                          class="mr-1"
                          color="red"
                          elevation="1"
                          @click="close"
                          outlined
                        >
                          <v-icon left> mdi-close-circle-outline </v-icon>
                          Cancel
                        </v-btn>
                        <v-btn
                          color="success"
                          elevation="1"
                          outlined
                          @click="save"
                        >
                          <v-icon left>
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          Save
                        </v-btn>
                      </v-row>
                    </v-stepper-content> -->
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn color="success" elevation="1" outlined @click="save">
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon>
                  Save
                </v-btn>
              </v-card-actions> -->
            </v-card>
          </v-dialog>

          <!-- Modal Add Product -->
          <v-dialog v-model="dialogAddProduct" max-width="400px">
            <v-card>
              <v-card-text class="pa-1 fontall">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div>
                        <h5>Nama Product</h5>
                        <h3 class="ml-2">
                          {{ NamaProductTemp }}
                        </h3>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <div>
                        <h5>Harga</h5>
                        <h3 class="ml-2">
                          {{ getRupiah(hargaProductTemp) }}
                        </h3>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="jumlahProductTemp"
                        label="Jumlah"
                        outlined
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pb-1" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="deskripsiProductTemp"
                        label="Keterangan Product"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeProduct">
                  <v-icon left> mdi-close-circle-outline </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="addProduct"
                >
                  <v-icon left> mdi-plus-circle-outline </v-icon>
                  Add
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-text>
                <h4 class="ml-2" style="color: red">
                  Note: Invoice sudah di rekam ke piutang, jika di hapus maka
                  <span style="text-decoration: underline"
                    >"Total Piutang Customer akan berkurang"</span
                  >
                </h4>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="closeDelete">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="deleteItemConfirm"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDetail" max-width="800px" class="pa-1">
            <v-card>
              <v-card-title class="mb-0 headermodalstyle">
                <span>Detail Sales Order</span>
              </v-card-title>

              <v-card-text class="fontall pa-1">
                <v-container
                  v-if="loaddetail == true"
                  fluid
                  fill-height
                  style="background-color: rgba(255, 255, 255, 0.5)"
                >
                  <v-layout justify-center align-center>
                    <v-progress-circular
                      :size="80"
                      :width="10"
                      indeterminate
                      color="primary"
                    >
                    </v-progress-circular>
                  </v-layout>
                </v-container>
                <v-container v-if="loaddetail == false" class="pa-1">
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th
                            class="text-left"
                            style="width: 250px !important; font-size: 14px"
                          >
                            No Order
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.order_no }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Nama Salesman
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_sales }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Nama Customer
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.nama_customer }}
                          </td>
                        </tr>
                        <tr>
                          <th
                            rowspan="2"
                            class="text-left"
                            style="font-size: 14px"
                          >
                            Alamat
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.alamat_ro }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.alamat }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Status
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            <v-chip
                              outlined
                              :color="
                                getColorStatusKonfirmasiAdmin(
                                  defaultItem.status_so
                                )
                              "
                              dark
                            >
                              <v-icon
                                medium
                                class="mr-1"
                                :color="
                                  getColorStatusKonfirmasiAdmin(
                                    defaultItem.status_so
                                  )
                                "
                              >
                                {{ getIconStatusAdmin(defaultItem.status_so) }}
                              </v-icon>
                              {{ defaultItem.status_so }}
                            </v-chip>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Term Of Payment
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ defaultItem.term_of_payment }} |
                            {{ defaultItem.lama_tempo }} Hari
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            class="text-center"
                            style="width: 250px !important; font-size: 13px"
                          >
                            Nama Product
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Harga
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Jumlah
                          </th>
                          <th class="text-center" style="font-size: 13px">
                            Keterangan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="val in defaultItem.list_order"
                          :key="val.code_product"
                        >
                          <td
                            class="text-left"
                            style="
                              padding-left: 15px !important;
                              font-size: 13px;
                            "
                          >
                            {{ val.nama_product }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ getRupiah(val.harga) }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.jumlah }} {{ val.satuan }}
                          </td>
                          <td class="text-center" style="font-size: 13px">
                            {{ val.deskripsi }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <!-- <v-data-table
                    :headers="headersProduct"
                    :items="defaultItem.list_order"
                    :search="search"
                    class="rounded elevation-1 mx-1 pa-1 my-2"
                    hide-default-footer
                    disable-pagination
                  >
                    <template v-slot:item.harga="{ item }">
                      {{ getRupiah(item.harga) }}
                    </template>
                    <template v-slot:item.jumlah="{ item }">
                      {{ item.jumlah }} {{ item.satuan }}
                    </template>
                  </v-data-table> -->

                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            SubTotal
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.jumlah_uang) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Diskon Total
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ setDiskon(defaultItem.jumlah_discount,defaultItem.diskon_kode_unik) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Biaya Ongkir
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ getRupiah(defaultItem.biaya_ongkir) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="text-left" style="font-size: 14px">
                            Total
                          </th>
                          <td
                            class="text-left"
                            style="font-size: 14px; font-weight: bold"
                          >
                            {{ setTotalAll(defaultItem.jumlah_uang,defaultItem.jumlah_discount,defaultItem.diskon_kode_unik,defaultItem.biaya_ongkir) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-divider
                    style="border-color: black !important"
                    class="mx-1 mb-2"
                  ></v-divider>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue" elevation="1" outlined @click="print">
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Invoice
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Modal Preview -->
          <v-dialog v-model="dialogPreview" max-width="900px">
            <v-card>
              <v-card-title class="headermodalstyle">
                <span>Preview Invoie</span>
              </v-card-title>
              <div class="text-center fontprint" style="">
                <h1 id="cap1" class="h1 d-none">INVOICE DISTARA</h1>
                <div
                  id="cap2"
                  class="container mb-5 d-none"
                  style="width: 100%"
                >
                  <input
                    type="button"
                    class="btn btn-success float-right mx-2"
                    onclick="printDiv('capture')"
                    value="Cetak Invoice PDF"
                  />
                  <button
                    id="download"
                    class="btn btn-success float-right mx-2"
                  >
                    Cetak Invoice PNG
                  </button>
                  <br style="clear: both" />
                  <br style="clear: both" />
                  <br style="clear: both" />
                </div>
                <div
                  class="container-fluid pa-2 mt-5 mr-2"
                  style="overflow: auto"
                >
                  <div style="width: 100%" id="canvas" />
                </div>
              </div>
              <div style="overflow: auto" class="mr-2">
                <div
                  id="capture"
                  class="pa-2 ma-2"
                  style="transform: scale(0.85)"
                >
                  <div class="printtable mr-2" style="padding: 1px !important">
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 1px 0px 0px 0px !important;
                        padding: 0px 1px 0px 2px !important;
                      "
                    >
                      <tr>
                        <td
                          class="font-weight-bold text-center pa-1"
                          style="font-size: 25px"
                          colspan="7"
                        >
                          INVOICE
                        </td>
                      </tr>
                    </table>
                    <table
                      style="width: 100% !important; margin-top: -45px"
                      class="fontprint mb-1"
                    >
                      <tr
                        class="fontprint"
                        style="padding-left: 30px; text-align: left"
                      >
                        <td style="padding-left: 10px; text-align: left">
                          <img
                            src="/images/logo_login_distara.jpeg"
                            class="mr-auto"
                            style="margin-left: 10px !important; width: 250px"
                          />
                        </td>
                        <td style="width: 105px">
                          <p
                            class="margininvoicetop1"
                            style="
                              margin-bottom: 6px;
                              font-size: 14px;
                              padding-left: 25px;
                            "
                          >
                            <b>Tanggal </b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="
                              margin-bottom: 6px;
                              font-size: 14px;
                              padding-left: 25px;
                            "
                          >
                            <b>No Order </b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="
                              margin-bottom: 6px;
                              font-size: 14px;
                              padding-left: 25px;
                            "
                          >
                            <b>Salesman </b>
                          </p>
                        </td>
                        <td style="width: 215px">
                          <p
                            class="margininvoicetop1"
                            style="margin-bottom: 6px; font-size: 14px"
                          >
                            <b>{{ gettanggal(defaultItem.created_at) }} </b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="margin-bottom: 6px; font-size: 14px"
                          >
                            <b>{{ defaultItem.order_no }}</b>
                          </p>
                          <p
                            class="margininvoicetop1"
                            style="margin-bottom: 6px; font-size: 14px"
                          >
                            <b>{{ defaultItem.nama_sales }} </b>
                          </p>
                        </td>
                      </tr>
                    </table>
                    <table
                      style="width: 100% !important; margin-top: -25px"
                      class="fontprint mb-1"
                    >
                      <tr class="fontprint">
                        <td>
                          <!-- nama pembeli -->
                          <p
                            style="
                              margin: 20px 10px 1px 10px !important ;
                              font-size: 14px;
                            "
                          >
                            <b>Kepada {{ defaultItem.nama_customer }}</b>
                          </p>
                          <p
                            style="
                              margin: 1px 0px 1px 10px !important;
                              font-size: 14px;
                            "
                          >
                            <b>{{ defaultItem.alamat }}</b
                            >, <b>{{ defaultItem.alamat_ro }}</b>
                          </p>
                          <!-- Terimakasih -->
                          <p style="margin: 1px 0px 1px 10px !important">
                            Terimakasih sudah berbelanja, Berikut rincian dari
                            orderan anda :
                          </p>
                        </td>
                        <!-- keterangan bayar -->
                        <td
                          colspan="2"
                          style="text-align: left; font-size: 14px"
                        >
                          <h3 style="color: red">BELUM DIBAYAR</h3>
                        </td>
                      </tr>
                    </table>
                    <table
                      class="table margininvoicebot2"
                      style="
                        margin: 5px 10px 0px 10px !important;
                        padding: 0px 1px 0px 2px !important;
                        border: 1px solid black;
                        width: 97%;
                      "
                    >
                      <tr style="border: 2px solid black">
                        <th
                          class="font-weight-bold"
                          style="color: black"
                          colspan="3"
                        >
                          Nama Produk
                        </th>
                        <th
                          class="font-weight-bold text-center"
                          style="color: black"
                        >
                          Jumlah
                        </th>
                        <th
                          class="font-weight-bold text-center"
                          style="color: black"
                        >
                          Harga
                        </th>
                        <th
                          class="font-weight-bold text-center"
                          colspan="2"
                          style="color: black"
                        >
                          SubTotal
                        </th>
                      </tr>
                      <!-- <tr>
                        <td>Fikih Empat Mazhab Jilid II</td>
                        <td class="text-center">1</td>
                        <td class="text-center">1kg</td>
                        <td class="text-right">Rp 179.000</td>
                        <td class="text-center">0%</td>
                        <td class="text-right">Rp 179.000</td>
                      </tr> -->
                      <tr
                        style="border: 2px solid black"
                        v-for="vallist in defaultItem.list_order"
                        :key="vallist.code_product"
                      >
                        <td colspan="3">
                          <h4>
                            {{ vallist.nama_product }}
                          </h4>
                        </td>
                        <td class="text-center">
                          {{ vallist.jumlah }} {{ vallist.satuan }}
                        </td>
                        <td class="text-right">
                          {{ getRupiah(vallist.harga) }}
                        </td>
                        <td class="text-right" colspan="2">
                          {{ getRupiahTotal(vallist.harga, vallist.jumlah) }}
                        </td>
                      </tr>
                      <!-- <tr class="border-top">
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="font-weight-bold text-weight-bold">
                          SubTotal
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.jumlah_uang) }}
                        </td>
                      </tr>
                      <tr style="border-top: 1px solid black">
                        <td class="text-center"></td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="text-right"></td>
                        <td class="font-weight-bold text-weight-bold">
                          Diskon {{ defaultItem.jumlah_discount }} %
                        </td>
                        <td class="text-right">
                          {{ getRupiah(defaultItem.disc_rupiah) }}
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold"></td>
                        <td class="font-weight-bold">TOTAL</td>
                        <td class="font-weight-bold text-right">
                          {{ getRupiah(defaultItem.Total_Rupiah) }}
                        </td>
                      </tr> -->
                    </table>
                    <!-- <hr class="border-top m-2" /> -->
                    <!-- <table class="table table-sm-responsive table-borderless">
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1 text-center"
                          style="width: 350px"
                        >
                          <p class="font-weight-bold margininvoicebot1"></p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </td>
                        <td
                          class="font-weight-bold text-center"
                          style="width: 120px"
                        >
                          <p class="font-weight-bold margininvoicebot1"></p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </td>
                        <td class="font-weight-bold">
                          <p class="font-weight-bold margininvoicebot1">
                            SubTotal
                          </p>
                          <p class="font-weight-bold margininvoicebot1">
                            Diskon {{ defaultItem.jumlah_discount }} %
                          </p>
                          <p class="font-weight-bold margininvoicebot1">
                            TOTAL
                          </p>
                        </td>
                        <td
                          class="font-weight-bold text-center"
                          style="width: 120px"
                        >
                          <p class="font-weight-bold margininvoicebot1"></p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </td>
                        <td class="font-weight-bold text-right">
                          <p
                            class="font-weight-bold margininvoicebot1"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.jumlah_uang) }}
                          </p>
                          <p
                            class="font-weight-bold margininvoicebot1"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.disc_rupiah) }}
                          </p>
                          <p
                            class="font-weight-bold margininvoicebot1"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.Total_Rupiah) }}
                          </p>
                        </td>
                      </tr>
                    </table> -->
                    <table
                      class="table table-sm-responsive"
                      style="margin-bottom: 0px"
                    >
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1 text-center"
                          style="width: 325px"
                        >
                          <p
                            style="
                              font-size: 14px;
                              font-weight: 700;
                              text-align: left;
                              margin-top: 10px;
                              margin-left: 10px;
                              margin-bottom: 20px;
                            "
                          >
                            Term of payment :
                            {{
                              gettermofpayment(
                                defaultItem.term_of_payment,
                                defaultItem.lama_tempo
                              )
                            }}
                          </p>

                          <p
                            style="border: 2px solid red; text-align: justify"
                            class="notedprint h5 rounded"
                          >
                            <span style="font-size: 14px; font-weight: 500"
                              >Pembayaran dapat ditransfer ke rekening</span
                            >
                            <br />
                            <span style="font-size: 14px; font-weight: 500"
                              >a.n. </span
                            ><span
                              style="font-size: 14px"
                              class="font-weight-bolder"
                              >PT. Distribusi Pelita Nusantara</span
                            >
                            <br />
                            <span
                              style="font-size: 14px"
                              class="font-weight-bolder"
                              >- BCA : 009-2424-241</span
                            >
                            <br />
                            <span
                              style="font-size: 14px"
                              class="font-weight-bolder"
                              >- BRI : 0038-0100-2194-3407</span
                            >
                            <br />
                          </p>
                        </td>
                        <td
                          class="font-weight-bold text-center"
                          style="width: 140px"
                        ></td>
                        <td class="">
                          <p class="margininvoicebot2">SubTotal</p>
                          <p class="margininvoicebot2">
                            Diskon
                            {{
                              showdiskon(
                                defaultItem.jumlah_discount,
                                defaultItem.diskon_management,
                                defaultItem.diskon_cash
                              )
                            }}
                          </p>
                          <p class="font-weight-bold margininvoicebot2">
                            TOTAL
                          </p>
                        </td>

                        <td class="font-weight-bold text-right">
                          <p
                            class="font-weight-bold margininvoicebot2"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.jumlah_uang) }}
                          </p>
                          <p
                            class="font-weight-bold margininvoicebot2"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.disc_rupiah) }}
                          </p>
                          <p
                            class="font-weight-bold margininvoicebot2"
                            style="margin-right: 12px"
                          >
                            {{ getRupiah(defaultItem.Total_Rupiah) }}
                          </p>
                        </td>
                      </tr>
                    </table>
                    <table
                      style="width: 100% !important; margin-top: -45px"
                      class="fontprint mb-1"
                    >
                      <tr>
                        <td
                          class="font-weight-bold margininvoicebot1 text-center"
                          style="width: 475px"
                        ></td>
                        <td class=""></td>
                        <td
                          class="font-weight-bold text-center"
                          style="width: 120px"
                        >
                          <p class="font-weight-bold margininvoicebot1">
                            Mengetahui
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p class="font-weight-bold margininvoicebot1">
                            Opt Manager
                          </p>
                          <br />
                        </td>
                        <td class="font-weight-bold text-right"></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <!-- </v-container> -->
              <v-card-actions>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-sm-none"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printPdf"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak PDF
                </v-btn>
                <v-btn
                  class="d-none d-sm-block"
                  color="blue"
                  elevation="1"
                  outlined
                  @click="printImage"
                >
                  <v-icon left> mdi-printer-check </v-icon>
                  Cetak Image
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEditShow" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="headline"
                >What you want to edit?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="blue" elevation="1" outlined @click="editinvoice">
                  <v-icon left> mdi-pencil-circle-outline </v-icon> Edit
                  Invoice</v-btn
                > -->
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="editstatus"
                >
                  <v-icon left> mdi-pencil-circle-outline </v-icon>Edit
                  Status</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEditStatus" max-width="500px">
            <v-card class="fontall">
              <v-card-title class="mb-0 headermodalstyle"
                >Update Status Invoice?</v-card-title
              >
              <v-card-text class="pa-0 fontall">
                <v-container>
                  <v-row>
                    <v-col class="pb-0" cols="12" sm="12" md="12">
                      <v-select
                        v-model="defaultItem.status_so"
                        :items="itemsStatusSO"
                        item-value="value"
                        item-text="text"
                        label="Pilih Status"
                        outlined
                        v-on:change="changeorderstatus"
                        :rules="[(v) => !!v || 'Field is required']"
                      ></v-select>
                    </v-col>
                    <!-- <v-col
                      v-if="status_show_bayar == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        v-model="bayar_temp"
                        label="Isikan Nominal Bayar"
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-col> -->
                    <v-col
                      v-if="status_show_bayar == true"
                      class="pb-0"
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <h4 class="ml-2" style="color: red">
                        Note: Merubah status menjadi
                        <span style="text-decoration: underline">"Paid"</span>
                        dan menambahkan pemasukan sesuai nominal serta melunasi
                        piutang
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" elevation="1" outlined @click="close">
                  <v-icon left> mdi-close-circle-outline </v-icon> Cancel</v-btn
                >
                <v-btn
                  color="success"
                  elevation="1"
                  outlined
                  @click="saveeditstatus"
                >
                  <v-icon left> mdi-checkbox-marked-circle-outline </v-icon
                  >Save</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-1" @click="detailItem(item)" color="primary">
          mdi-information-outline
        </v-icon>
        <!-- <v-icon class="mr-1" @click="editItem(item)" color="warning">
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon class="mr-1" @click="deleteItem(item)" color="red">
          mdi-delete-circle
        </v-icon> -->
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :color="colorsnackbar"
      :timeout="timeoutsnackbar"
      class="fontall"
    >
      {{ textsnackbar }}
    </v-snackbar>
  </div>
</template>

<script src="../public/js/html2canvas.js"></script>

<script>
import axios from "axios";
import html2canvas from "@/js/html2canvas";

import HelperGlobal from "../../services/Helper";
const HelperGlobalService = new HelperGlobal();

export default {
  name: "SalesInvoice",
  data: () => ({

    formTitle: "Add Item",
    value: "add",
    loadtable: false,
    loaddetail: false,
    dialog: false,
    dialogDelete: false,
    dialogReset: false,
    dialogDetail: false,
    detailorderready: false,
    dialogAddProduct: false,
    dialogPreview: false,
    dialogAddSO: false,
    dialogEditShow: false,
    dialogEditStatus: false,
    showButtonAdd: false,
    search: "",
    authtoken: "",
    BaseUrlGet: "",
    headers: [
      {
        text: "Order No",
        value: "order_no",
      },
      { text: "Nama Cust", value: "nama_customer", width: "20%" },
      { text: "Tujuan", value: "alamat_ro" },
      { text: "Status", value: "status_so" },
      { text: "Tanggal", value: "created_at", width: "13%" },
      //   { text: "User ID", value: "username" },
      { text: "Actions", value: "actions", sortable: false, width: "15%" },
    ],
    headersOrderCust: [
      {
        text: "No Inv",
        value: "id",
      },
      { text: "Barang", value: "total_barang" },
      { text: "Total Belanja", value: "total_harga" },
      { text: "Tanggal", value: "created_at" },
      { text: "Update", value: "updated_at" },
    ],
    headerListProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Harga", value: "harga" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Actions", value: "actions", sortable: false, width: "10%" },
    ],
    headersProduct: [
      { text: "Product", value: "nama_product" },
      { text: "Harga", value: "harga" },
      { text: "Jumlah", value: "jumlah" },
      { text: "Keterangan", value: "deskripsi" },
    ],
    dataobject: [],
    dataadmin: [],
    datacustomer: [],
    datasalesorder: [],
    listordercust: [],
    itemsKabRO: [],
    itemsProduct: [],
    ordercombo_so: "",

    defaultItem: {
      ordercombo: "",
      id: "",
      order_no: "",
      alamat_ro: "",
      status_task: "",
      status_so: "",
      jumlah_barang: "",
      jumlah_uang: "",
      nama_sales: "",
      code_customer: "",
      nama_customer: "",
      code_salesman: "",
      alamat: "",
      code_alamat_ro: "",
      alamat_cust_ro: "",
      diskon_management: "",
      diskon_cash: "",
      disc_percent_show: "",
      jumlah_discount: "",
      term_of_payment: "",
      lama_tempo: "",
      due_date: "",
      created_at: "",
      itemsProductSelected: "",
      list_order: [],
      kabROCombobox: "",
      bank:"",
      image:"",

      lokasi:"",
      typeTransaksi:"",
      total_harga:"",
      totalDiskon:"",
      biayaExpedisi:"",
      total_barang:"",
      nama_customer:"",

      kab_kota:"",
      kecamatan:"",
      
      akun_debit: "",
      akun_kredit: "",
      akun_laba_rugi_1: "",
      akun_laba_rugi_2: "",
    },

    itemsLokasi: [
      { text: "Solo", value: "Gd_001" },
      { text: "Jakarta", value: "Gd_002" },
    ],
    itemsStatus: [
      { text: "Reseller", value: "1" },
      { text: "Cust Reguler", value: "0" },
    ],
    itemsTOP: [
      { text: "Tunai", value: "Tunai" },
      { text: "Tempo", value: "Tempo" },
    ],
    itemsLamaTempo: [
      { text: "15 Hari", value: "15" },
      { text: "30 Hari", value: "30" },
      { text: "45 Hari", value: "45" },
      { text: "60 Hari", value: "60" },
    ],

    itemsStatusSO: [
      // { text: "Unpaid", value: "unpaid" },
      // { text: "Partial", value: "partial" },
      { text: "Paid", value: "paid" },
    ],

    itemsJenisSisa:[
      { text: "Kelebihan", value: "kelebihan" },
      { text: "Kurang", value: "kurang" },
    ], 

    jenis_sisa:'',
    nominal_sisa:0,
    akun_debit_1:'',
    akun_debit_2:'',
    akun_debit_3:'',
    nominal_akun_debit_1:0,
    nominal_akun_debit_2:0,
    nominal_akun_debit_3:0,
    cek1 : false,
    cek2 : false,
    ceksisa: false,
    ceklistValueAkun2 : false,
    ceklistValueAkun3 : false,
    ceklistValueSisa : false,
    isDisabledAkun2: true,
    isDisabledAkun3: true,
    isDisabledSisa: true,
    showakun2: false,
    showakun3: false,
    showsisa: false,
    catatan:'',
    tipetrx:'',
    
    itemsAkun: [],
    itemsTipe:[
      {value:'regular',text:'regular'},
      {value:'preOrder',text:'preOrder'},
      {value:'tempo',text:'tempo'},
    ],

    e1: 1,
    stepperdone: false,

    snackbar: false,
    textsnackbar: "Test",
    timeoutsnackbar: 2000,
    colorsnackbar: null,

    diskonReset: "",
    jenisResellerReset: "",

    kodeAdmin: "",

    status_show_bayar: false,
    bayar_temp: 0,

    dateTempo: false,
    codeProductTemp: "",
    NamaProductTemp: "",
    deskripsiProductTemp: "",
    hargaProductTemp: 0,
    jumlahProductTemp: 0,
    admin_role: "",

    
    datepicker: new Date().toISOString().substr(0, 10),
    menu2: "",


    admin_id: "",
    rolesales: false,
    rolefinance: false,

    dataobject2: [],
  }),

  created() {
    this.authtoken = localStorage.getItem("token");
    this.BaseUrlGet = localStorage.getItem("BaseUrlGet");
    this.User = JSON.parse(localStorage.getItem("User"));
    if (this.User) {
      this.kodeAdmin = this.User.code;
      this.admin_id = this.User.code;
      this.checkrole(this.User.role_code);
    }
    this.initialize();
    this.GetAdmin();
    this.GetCustomer();
    this.getKabKotaROAll();
    this.GetProduct();
    this.getAkun();
  },

  methods: {
    checkrole(value) {
      if (value == 1) {
        this.rolesales = true;
        this.rolefinance = false;
      } else if (value == 2) {
        this.rolesales = false;
        this.rolefinance = true;
      } else if (value == 3) {
        this.rolesales = false;
        this.rolefinance = false;
      } else {
        this.rolesales = true;
        this.rolefinance = true;
      }
    },
    async initializedef() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetSalesInv", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.loadtable = false;
          this.dataobject = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject = [];
        }
      }
    },
    async initialize() {
      this.dataobject = [];
      this.loadtable = true;
      try {
        const response = await axios.get(this.BaseUrlGet + "GetSalesInvChunk", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          if(response.data.data.result.type == 'chunk'){
            this.dataobject = response.data.data.result.data[0];
            await setTimeout(() => this.pushArraytoTable(response.data.data.result.data), 1000)
            this.loadtable = false;
          }else{
            this.dataobject = response.data.data.result.data;
            this.loadtable = false;
          }
        } else {
          console.log("Kosong");
          this.loadtable = false;
          this.dataobject2 = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          this.loadtable = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.loadtable = false;
          this.dataobject2 = [];
        }
      }
    },
    async pushArraytoTable(valData){
      let list=[];
      for (let i = 0; i < valData.length; i++) {  
        $.each(valData[i], function(key, value) {
            list.push(value);
        });
      }
      this.dataobject = list;
    },
    async getAkun() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetAkunAll", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        // console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsAkun = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsAkun = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsAkun = [];
        }
      }
    },
    async GetSalesOrderBosman() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetSOBosman?codeGudang=" +
            this.defaultItem.lokasi,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.datasalesorder = response.data.data.result;
        } else {
          console.log("Kosong");
          this.datasalesorder = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.datasalesorder = [];
        }
      }
    },
    async GetAdmin() {
      this.admin_role = 1;
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetAdminbyRole?role_code=" + this.admin_role,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.dataadmin = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.dataadmin = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.dataadmin = [];
        }
      }
    },
    async GetCustomer() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetCustomer?salesman_id=" +
            this.defaultItem.code_salesman,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.datacustomer = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.datacustomer = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.datacustomer = [];
        }
      }
    },
    async GetSalesOrder() {
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetSOApproved?code_customer=" +
            this.defaultItem.code_customer,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.datasalesorder = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.datasalesorder = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.datasalesorder = [];
        }
      }
    },
    async getDetailSO(val) {
      try {
        const response = await axios.get(
          this.BaseUrlGet + "GetSODetail?order_no=" + val,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.defaultItem.kabROCombobox = {
            id_alamat: response.data.data.result.code_alamat_ro,
            text: response.data.data.result.alamat_ro,
          };
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async GetProduct() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetProduct", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsProduct = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsProduct = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsProduct = [];
        }
      }
    },
    async getDetail(val) {
      this.loaddetail = true;
      try {
        const response = await axios.get(
          this.BaseUrlGet +
            "GetSalesInvDetail?order_no=" +
            this.defaultItem.order_no,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.length != 0) {
          this.defaultItem = response.data.data.result;
          this.defaultItem.kabROCombobox = {
            id_alamat: response.data.data.result.code_alamat_ro,
            text: response.data.data.result.alamat_ro,
          };
          //   console.log(this.defaultItem);

          console.log(val);
          if (val == "edit") {
            this.GetCustomer();
            console.log(val);
          }
          this.loaddetail = false;
        } else {
          console.log("Kosong");
          // this.dataobject = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          // this.detailOrder.defaultItem = [];
        }
      }
    },
    async getKabKotaROAll() {
      try {
        const response = await axios.get(this.BaseUrlGet + "GetKotaKabRO", {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result.data);
        if (response.data.length != 0) {
          this.itemsKabRO = response.data.data.result.data;
        } else {
          console.log("Kosong");
          this.itemsKabRO = [];
        }
      } catch (error) {
        console.error(error);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.itemsKabRO = [];
        }
      }
    },
    async verifDelete() {
      const datapost = {
        order_no: this.defaultItem.order_no,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "DeleteSalesInv",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result.data);
        if (response.data.data.result == "success") {
          this.dialogDelete = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses hapus data";
          this.initialize();
        } else {
          this.dialogDelete = false;
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        }
      }
    },
    async addData() {
      const datapost = {
        code_customer: this.defaultItem.code_customer,
        code_salesman: this.defaultItem.code_salesman,
        alamat: this.defaultItem.alamat,
        code_alamat_ro: this.defaultItem.kabROCombobox.id_alamat,
        jumlah_discount: this.defaultItem.jumlah_discount,
        term_of_payment: this.defaultItem.term_of_payment,
        lama_tempo: this.defaultItem.lama_tempo,
        list_order: JSON.stringify(this.defaultItem.list_order),
        code_admin_create: this.kodeAdmin,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(this.BaseUrlGet + "AddSO", datapost, {
          headers: {
            Authorization: `Bearer ` + this.authtoken,
          },
        });
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateData() {
      const datapost = {
        order_no: this.defaultItem.order_no,
        code_customer: this.defaultItem.code_customer,
        code_salesman: this.defaultItem.code_salesman,
        alamat: this.defaultItem.alamat,
        code_alamat_ro: this.defaultItem.kabROCombobox.id_alamat,
        jumlah_discount: this.defaultItem.jumlah_discount,
        // term_of_payment: this.defaultItem.term_of_payment,
        // lama_tempo: this.defaultItem.lama_tempo,
        list_order: JSON.stringify(this.defaultItem.list_order),
        code_admin_update: this.kodeAdmin,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditSalesInv",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialog = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async updateStatusInvoice() {
      const datapost = {
        order_no: this.defaultItem.order_no,
        status_so: this.defaultItem.status_so,
        total_bayar: this.bayar_temp,
        code_admin_update: this.kodeAdmin,
      };
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "EditSalesInvoiceStatus",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        // console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogEditStatus = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses ubah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          this.dialogEditStatus = false;
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      }
    },
    async addDataSO(datapost) {
      console.log(datapost);
      // this.dialogDetail = false;
      try {
        const response = await axios.post(
          this.BaseUrlGet + "AddSalesInvSO",
          datapost,
          {
            headers: {
              Authorization: `Bearer ` + this.authtoken,
            },
          }
        );
        console.log(response.data.data.result);
        if (response.data.data.result == "success") {
          this.dialogAddSO = false;
          this.snackbar = true;
          this.colorsnackbar = "green";
          this.textsnackbar = "Sukses tambah data";
          this.initialize();
        } else {
          //   this.dialog = false;
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data";
        }
      } catch (error) {
        console.error(error.response);
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          this.$router.push("/");
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Gagal ubah data, Data SO sudah terbuat Invoice";
        }
      }
    },

    showAddModalSO() {
        this.value = "add";
        this.formTitle = "Add Item";
        this.e1 = 1;


        this.cek1 = false;
        this.cek2 = false;
        this.ceksisa = false;

        this.isDisabledAkun2 = true;
        this.isDisabledAkun3 = true;
        this.isDisabledSisa = true;

        this.ceklistValueAkun2 = false;
        this.ceklistValueAkun3 = false;
        this.ceklistValueSisa = false;
        
        this.nominal_akun_debit_1 = 0;
        this.nominal_akun_debit_2 = 0;
        this.nominal_akun_debit_3 = 0;
        this.nominal_sisa = 0;

        this.defaultItem.code_customer = "";
        this.defaultItem.code_salesman = "";
        this.defaultItem.ordercombo = "";
        this.datasalesorder = [];

        this.defaultItem.lokasi = '';
        this.ordercombo_so = '';
        
        this.defaultItem.akun_kredit = { nama_akun: "Persediaan Buku Solo", no_akun: "1-130" }
        this.defaultItem.akun_debit = { nama_akun: "Piutang Dagang Solo", no_akun: "1-126" } 
        this.defaultItem.akun_laba_rugi_1 = { nama_akun: "Penjualan Reguler", no_akun: "4-101" } 
        this.defaultItem.akun_laba_rugi_2 = { nama_akun: "Pendapatan Deliveri Service", no_akun: "4-104" } 

        this.showButtonAdd = false;

        this.dialogAddSO = true;
    },

    // showAddModal() {
    //   this.value = "add";
    //   this.formTitle = "Add Item";
    //   this.e1 = 1;
    //   // this.defaultItem = [];this.defaultItem.code_customer = "";
    //   this.defaultItem.code_salesman = "";
    //   this.defaultItem.alamat = "";
    //   this.defaultItem.jumlah_discount = "";
    //   this.defaultItem.term_of_payment = "";
    //   this.defaultItem.list_order = [];
    //   this.defaultItem.kabROCombobox = "";
    //   this.defaultItem.code_alamat_ro = "";
    //   this.defaultItem.alamat_cust_ro = "";

    //   this.dialog = true;
    // },
    editItem(item) {
      console.log(item);
      this.formTitle = "Edit Item";
      this.defaultItem = Object.assign({}, item);
      this.getDetail("edit");
      this.value = "edit";
      this.e1 = 1;
      // this.dialog = true;
      this.dialogEditShow = true;
      // if (this.rolefinance == true) {
      //   console.log(item);
      //   this.formTitle = "Edit Item";
      //   this.defaultItem = Object.assign({}, item);
      //   this.getDetail("edit");
      //   this.value = "edit";
      //   this.e1 = 1;
      //   // this.dialog = true;
      //   this.dialogEditShow = true;
      // } else {
      //   this.snackbar = true;
      //   this.colorsnackbar = "red";
      //   this.textsnackbar =
      //     "yang bisa edit status paid/unpaid hanya admin finance";
      // }
    },
    editinvoice() {
      // console.log(item);
      // this.formTitle = "Edit Item";
      // this.defaultItem = Object.assign({}, item);
      // this.getDetail("edit");
      // this.value = "edit";
      this.dialogEditShow = false;
      this.e1 = 1;
      this.dialog = true;
    },
    editstatus() {
      // console.log(this.defaultItem.status_so);

      if (this.rolefinance == true) {
        if (this.defaultItem.status_so == "unpaid") {
          this.defaultItem.status_so = "-";
          this.dialogEditShow = false;
          this.dialogEditStatus = true;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.timeoutsnackbar = 4000;
          this.textsnackbar =
            "Status Partial hanya bisa di edit di manajemen piutang, dan Paid tidak bisa di edit";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "yang bisa edit status paid/unpaid hanya admin finance";
      }
    },
    saveeditstatus() {
      if (this.rolefinance == true) {
        this.updateStatusInvoice();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar =
          "yang bisa edit status paid/unpaid hanya admin finance";
      }
    },
    deleteItem(item) {
      if (this.rolesales == true) {
        this.defaultItem = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "yang bisa hapus hanya admin sales";
      }
    },
    detailItem(item) {
      this.defaultItem = Object.assign({}, item);
      this.getDetail("detail");
      this.dialogDetail = true;
    },

    deleteItemConfirm() {
      if (this.rolesales == true) {
        this.closeDelete();
        this.verifDelete();
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "yang bisa hapus hanya admin sales";
      }
    },
    close() {
      this.dialog = false;
      this.dialogAddSO = false;
      this.dialogEditShow = false;
      this.dialogEditStatus = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    save() {
      // order_no: "",
      // console.log(this.defaultItem.code_customer);
      // console.log(this.defaultItem.code_salesman);
      // console.log(this.defaultItem.alamat);
      // console.log(this.defaultItem.jumlah_discount);
      // console.log(this.defaultItem.term_of_payment);
      // console.log(this.defaultItem.list_order);
      // console.log(this.defaultItem.kabROCombobox);

      if (
        this.defaultItem.code_customer.length != 0 &&
        this.defaultItem.code_salesman.length != 0 &&
        this.defaultItem.alamat.length != 0 &&
        // this.defaultItem.jumlah_discount.length != 0 &&
        // this.defaultItem.term_of_payment.length != 0 &&
        this.defaultItem.list_order.length != 0 &&
        this.defaultItem.kabROCombobox.id_alamat.length != 0
      ) {
        if (this.defaultItem.order_no) {
          console.log(this.defaultItem);
          this.updateData();
        } else {
          console.log("add");
          console.log(this.defaultItem);
          this.addData();
        }
        // this.close();

        // console.log(this.defaultItem.kabROCombobox.text);
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Gagal Menambah, Kolom tidak boleh ada yang kosong";
      }
    },
    savesalesorder() {
      // console.log(this.ordercombo_so.order_no);
      // console.log(this.defaultItem.code_customer);
      var debit_1 = this.akun_debit_1;
      var debit_2 = this.akun_debit_2;
      var debit_3 = this.akun_debit_3;
      var type_trx = this.tipetrx;
      // var kredit = this.defaultItem.akun_kredit;
      // var laba_rugi_1 = this.defaultItem.akun_laba_rugi_1;
      // var laba_rugi_2 = this.defaultItem.akun_laba_rugi_2;             
        const datapost = {
          order_no: this.defaultItem.order_no,
          date: this.datepicker,
          code_admin_create: this.admin_id,
          akun_debit_1: debit_1.no_akun,
          akun_debit_2: debit_2.no_akun,
          akun_debit_3: debit_3.no_akun,
          nominal_akun_1 : this.nominal_akun_debit_1,
          nominal_akun_2 : this.nominal_akun_debit_2,
          nominal_akun_3 : this.nominal_akun_debit_3,
          cek_akun2: this.ceklistValueAkun2,
          cek_akun3: this.ceklistValueAkun3,
          cek_sisa: this.ceklistValueSisa,
          jenis_sisa : this.jenis_sisa,
          nominal_sisa : this.nominal_sisa,
          catatan: this.catatan,
          type_trx: type_trx.value,
          // akun_kredit: kredit.no_akun,
          // akun_laba_rugi_1: laba_rugi_1.no_akun,
          // akun_laba_rugi_2: laba_rugi_2.no_akun,
        };

        console.log(datapost)
      this.addDataSO(datapost);
    },

    changeLokasi(){
      this.GetSalesOrderBosman();
      if(this.defaultItem.lokasi == 'Gd_001'){
        this.defaultItem.akun_kredit = { nama_akun: "Persediaan Buku Solo", no_akun: "1-130" }
      }else{
        this.defaultItem.akun_kredit = { nama_akun: "Persediaan Buku Jakarta", no_akun: "1-132" }
      }
    },

    changeTOP(val) {
      console.log(val);
      if (val == "Tempo") {
        this.dateTempo = true;
      } else {
        this.dateTempo = false;
        this.defaultItem.lama_tempo = 0;
      }
      // this.discSelectedCustomer = val.diskon;
    },

    changeSalesman(val) {
      this.GetCustomer();
    },

    changeSalesmanSO(val) {
      this.GetCustomer();
    },
    changeCustomerSO(val) {
      this.GetSalesOrder();
    },
    changeOrderSO(val) {
      console.log(val)  
      this.defaultItem.order_no =  val.order_no;
      this.defaultItem.nama_customer =  val.nama_customer;
      this.defaultItem.TextOrderCustomer =  val.TextOrderCustomer;
      this.defaultItem.alamat =  val.alamat;
      this.defaultItem.kab_kota =  val.kab_kota;
      this.defaultItem.kecamatan =  val.kecamatan;
      this.defaultItem.typeTransaksi =  val.typeTransaksi;
      this.defaultItem.total_harga =  val.total_harga;
      this.defaultItem.totalDiskon =  val.totalDiskon;
      this.defaultItem.diskonKodeUnik =  val.diskonKodeUnik;
      this.defaultItem.biayaExpedisi =  val.biayaExpedisi;
      this.defaultItem.total_barang =  val.total_barang;
      this.defaultItem.bank =  val.bank;
      this.defaultItem.image =  'https://'+val.image;

      this.nominal_akun_debit_1 = val.total_harga - val.totalDiskon -val.diskonKodeUnik +val.biayaExpedisi;

      if(val.typeTransaksi == 'regular'){
        this.showsisa = true;
        this.showakun2 = true;
        this.showakun3 = true;
        this.defaultItem.akun_debit = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.akun_debit_1 = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.akun_debit_2 = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.akun_debit_3 = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.defaultItem.akun_laba_rugi_1 = { nama_akun: "Penjualan Reguler", no_akun: "4-101" } 

        
        this.tipetrx = { value:'regular',text:'regular' }
      }else if(val.typeTransaksi == 'preOrder'){
        this.showsisa = true;
        this.showakun2 = true;
        this.showakun3 = true;
        this.defaultItem.akun_debit = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.akun_debit_1 = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.akun_debit_2 = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.akun_debit_3 = { nama_akun: "Subwofer", no_akun: "1-108" } 
        this.defaultItem.akun_laba_rugi_1 = { nama_akun: "Penjualan Pre Order", no_akun: "4-102" } 
        
        this.tipetrx = { value:'preOrder',text:'preOrder' }
      }else{
        this.showsisa = false;
        this.showakun2 = false;
        this.showakun3 = false;
        if(this.defaultItem.lokasi == 'Gd_001'){
          this.defaultItem.akun_debit = { nama_akun: "Piutang Dagang Solo", no_akun: "1-126" }
          this.akun_debit_1 = { nama_akun: "Piutang Dagang Solo", no_akun: "1-126" }
          this.akun_debit_2 = { nama_akun: "Piutang Dagang Solo", no_akun: "1-126" }
          this.akun_debit_3 = { nama_akun: "Piutang Dagang Solo", no_akun: "1-126" }
        }else{
          this.defaultItem.akun_debit = { nama_akun: "Piutang Dagang Jakarta", no_akun: "1-127" }
          this.akun_debit_1 = { nama_akun: "Piutang Dagang Jakarta", no_akun: "1-127" }
          this.akun_debit_2 = { nama_akun: "Piutang Dagang Jakarta", no_akun: "1-127" }
          this.akun_debit_3 = { nama_akun: "Piutang Dagang Jakarta", no_akun: "1-127" }
        } 
        this.defaultItem.akun_laba_rugi_1 = { nama_akun: "Penjualan Tempo", no_akun: "4-103" } 
        
        this.tipetrx = { value:'tempo',text:'tempo' }
      }
      
      // this.defaultItem = Object.assign({}, val);
      console.log(val.order_no);
      // this.getDetailSO(val.order_no);
      this.showButtonAdd = true;
    },
    setDiskon(a,b){
      var c = 0;
      c = a+b;
      return HelperGlobalService.getRupiah(c);
    },
    setTotalAll(a,b,c,d){
      var f = 0;
      f = a-b-c+d;
      return HelperGlobalService.getRupiah(f);
    },

    changeorderstatus(val) {
      console.log(val);
      if (val == "partial") {
        this.status_show_bayar = true;
      } else {
        this.status_show_bayar = true;
      }
    },

    changeProductSelected(val) {
      console.log(val);
      this.codeProductTemp = val.code_product;
      this.NamaProductTemp = val.nama_product;
      this.hargaProductTemp = val.harga_jual;
      //   this.jumlahProductTemp = val.total;
      this.dialogAddProduct = true;
    },

    closeProduct() {
      this.dialogAddProduct = false;
    },
    addProduct() {
      // console.log(this.codeBukuTemp);
      // console.log(this.jumlahBukuTemp);
      // console.log(this.diskonBukuTemp);
      if (this.jumlahProductTemp > 0) {
        var listmenu = this.defaultItem.list_order;
        console.log(listmenu);
        var result = false;
        for (let n = 0; n < listmenu.length; n++) {
          if (listmenu[n].code_product == this.codeProductTemp) {
            result = true;
            break;
          }
        }

        if (result == false) {
          var productset = {
            code_product: "",
            diskon: "",
            harga: "",
            jumlah: "",
            nama_product: "",
            deskripsi: "",
          };

          productset.code_product = this.codeProductTemp;
          productset.harga = this.hargaProductTemp;
          productset.jumlah = this.jumlahProductTemp;
          productset.nama_product = this.NamaProductTemp;
          if (this.deskripsiProductTemp) {
            productset.deskripsi = this.deskripsiProductTemp;
          } else {
            productset.deskripsi = "-";
          }

          this.defaultItem.list_order.push(productset);

          this.dialogAddProduct = false;
        } else {
          this.snackbar = true;
          this.colorsnackbar = "red";
          this.textsnackbar = "Product Sudah Ada..!!!";
        }
      } else {
        this.snackbar = true;
        this.colorsnackbar = "red";
        this.textsnackbar = "Jumlah Buku Tidak boleh 0..!!!";
      }
    },
    async deletelistitemproduct(item) {
      this.editedIndex = await this.defaultItem.list_order.indexOf(item);
      this.editedItem = await Object.assign({}, item);
      // console.log(this.editedIndex);
      // console.log(this.editedItem);
      this.defaultItem.list_order.splice(this.editedIndex, 1);
    },

    
    ceksisafunction(){
      if(this.isDisabledSisa == false){
        this.isDisabledSisa = true
        this.ceklistValueSisa = false
      }else{        
        this.isDisabledSisa = false
        this.ceklistValueSisa = true
      }
    },

    cekakun2(){
      if(this.isDisabledAkun2 == false){
        this.isDisabledAkun2 = true
        this.ceklistValueAkun2 = false
      }else{        
        this.isDisabledAkun2 = false
        this.ceklistValueAkun2 = true
      }
    },
    cekakun3(){
      if(this.isDisabledAkun3 == false){
        this.isDisabledAkun3 = true
        this.ceklistValueAkun3 = false
      }else{        
        this.isDisabledAkun3 = false
        this.ceklistValueAkun3 = true
      }
    },

    createCanvas() {
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        document.getElementById("cap2").className = "d-none";
        document.getElementById("cap1").className = "d-none";
        document.getElementById("canvas").appendChild(canvas);
        // document.getElementById("capture").className = "d-none";
        document.getElementById("cap1").className = "h1";
        document.getElementById("cap2").className = "container mb-5";
      });
    },

    print() {
      // this.createCanvas();
      this.dialogDetail = false;
      this.dialogPreview = true;
    },

    printImage() {
      var no_ord = this.defaultItem.order_no;
      var nama_cust = this.defaultItem.nama_customer;
      const arrayname = nama_cust.split(" ");
      var nama_cust_split = arrayname[0];
      // console.log(no_ord);
      // console.log(nama_cust_split);
      document.getElementById("capture").className = "pa-1 ma-1 invGmbr";
      document.getElementById("canvas").className = "d-none";

      html2canvas(document.querySelector("#capture"), {
        useCORS: true,
        allowTaint: true,
      }).then(function (canvas) {
        console.log(canvas);
        var uri = canvas.toDataURL();
        var filename = nama_cust_split + "_" + no_ord + ".png";
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          link.href = uri;
          link.download = filename;
          //Firefox requires the link to be in the body
          document.body.appendChild(link);
          //simulate click
          link.click();
          //remove the link when done
          document.body.removeChild(link);
        } else {
          window.open(uri);
        }
        // this.saveAs(canvas.toDataURL(), "file-name.png");
      });
      // document.getElementById("capture").className = "d-none";
      // document.getElementById("canvas").className = "";

      this.dialogPreview = false;
    },

    printPdf() {
      var printContents = document.getElementById("capture").innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    saveAs(uri, filename) {
      var link = document.createElement("a");
      if (typeof link.download === "string") {
        link.href = uri;
        link.download = filename;
        //Firefox requires the link to be in the body
        document.body.appendChild(link);
        //simulate click
        link.click();
        //remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },

    gettermofpayment(top, lama) {
      if (top) {
        if (top.toLowerCase() == "tunai") return "Tunai";
        else return top + " | " + lama + " Hari";
      }
    },

    getRupiah(val) {
      return HelperGlobalService.getRupiah(val);
    },
    getRupiahTotal(val1, val2) {
      var val = val1 * val2;
      return HelperGlobalService.getRupiah(val);
    },

    gettanggal(val) {
      return HelperGlobalService.gettanggal(val);
    },

    getColorStatusKonfirmasiAdmin(status) {
      if (status == "unpaid") return "red";
      else if (status == "partial") return "orange";
      else return "green";
    },
    getIconStatusAdmin(status) {
      if (status == "unpaid") return "mdi-alert-octagon";
      //   else if (status == "7") return "mdi-package-variant";
      //   else if (status == "9") return "mdi-check-underline-circle";
      else return "mdi-check-underline-circle";
    },
    getColorStatusApproval(status) {
      if (status == "waiting_for_approval") return "orange";
      else if (status == "rejected") return "red";
      else return "green";
    },
    getIconStatusApproval(status) {
      if (status == "waiting_for_approval") return "mdi-calendar-clock-outline";
      else if (status == "rejected") return "mdi-close-circle-multiple-outline";
      else return "mdi-check-underline-circle";
    },

    showdiskon(val1, val2, val3) {
      var rslt = "-";
      if (Number(val1) != 0) {
        rslt = val1 + "% ";
      }
      if (Number(val2) != 0) {
        if (rslt == "-") {
          rslt = val2 + "% ";
        } else {
          rslt = rslt + "+" + val2 + "% ";
        }
      }
      if (Number(val3) != 0) {
        if (rslt == "-") {
          rslt = val3 + "% ";
        } else {
          rslt = rslt + "+" + val3 + "% ";
        }
      }
      return rslt;
    },
  },
};
</script>

<style scoped>
.table th {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(14, 13, 13) !important;
  -webkit-print-color-adjust: exact;
}
</style>



